import { BondsProject } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../../../hooks/use-project-context";
import {
  SideFinStatsLabel,
  SideFinStatsValue,
} from "./sidebar-project-financial-stats";

export function SidebarProjectFinancialStatsBonds() {
  const { t } = useTranslation();
  const project = useProjectContext() as BondsProject;
  const { isQualifiedOrWma } = useUserBusiness();
  if (!project) return null;

  return (
    <>
      {project?.status !== "finished" &&
        project.bondsMechanicSettings.annualInterestPercent > 0 && (
          <article>
            <SideFinStatsLabel
              tooltipDesc={
                "C’est le pourcentage du montant emprunté qui est ajouté au capital initial en compensation de l'utilisation des fonds prêtés."
              }
            >{`Taux d'intérêt`}</SideFinStatsLabel>
            {isQualifiedOrWma ? (
              <SideFinStatsValue>
                {project.bondsMechanicSettings.annualInterestPercent}%
              </SideFinStatsValue>
            ) : (
              <SideFinStatsValue>
                <Icon
                  name="Cadenas"
                  size="XS"
                  primaryColor={themeColors["dark-2"]}
                />
              </SideFinStatsValue>
            )}
          </article>
        )}

      {project.bondsMechanicSettings.durationInMonth > 0 && (
        <article>
          <SideFinStatsLabel tooltipDesc="Durée de l’investissement.">
            Durée
          </SideFinStatsLabel>
          <SideFinStatsValue>
            {project.bondsMechanicSettings.durationInMonth} mois
          </SideFinStatsValue>
        </article>
      )}

      <article>
        <SideFinStatsLabel tooltipDesc="La façon dont le remboursement va s’effectuer.">{`Typologie d'obligations`}</SideFinStatsLabel>
        <SideFinStatsValue>
          {t(`repayment_type.${project.bondsMechanicSettings.repaymentType}`)}
        </SideFinStatsValue>
      </article>

      <article>
        <SideFinStatsLabel tooltipDesc="Fréquence de remboursement pour ce projet.">
          Remboursement
        </SideFinStatsLabel>
        <SideFinStatsValue>
          {t(
            `repayment_frequency.${project.bondsMechanicSettings.repaymentFrequency}`,
          )}
        </SideFinStatsValue>
      </article>
    </>
  );
}
