import React from "react";

import { Alert } from "@tudigo-monorepo/web-tudigo-components";

export const WmaIntro = () => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <h1 className="font-brand text-primary text-[27px]">
          Conseiller en gestion de patrimoine ?
        </h1>
        <hr />
      </div>
      <Alert
        icon="InformationIcon"
        variant="info"
        size="M"
        title="Vous souhaitez vous inscrire en tant que CGP sur Tudigo ?"
        description={
          <>Créez votre compte puis ajouter vos clients en quelques clics.</>
        }
      />
    </>
  );
};
