import {
  Investment,
  Organization,
  PaymentMethodType,
  Project,
  TaxExemptionMechanicsEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { LEMONWAY, ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";

export const getInvestmentPaymentMethodType = (investment: Investment) => {
  switch (investment.paymentMethodType) {
    case PaymentMethodType.WIRE:
    case PaymentMethodType.CARD:
    case PaymentMethodType.PEA:
    case PaymentMethodType.PEA_PME:
    case PaymentMethodType.CHECK:
    case PaymentMethodType.MANUAL:
      return investment.paymentMethodType;
    default:
      return PaymentMethodType.NONE;
  }
};

export const getInvestmentPaymentMethodTypeTranslateKey = (
  investment: Investment,
) => {
  if (
    investment.globalStatus === "validated" &&
    !investment.paymentMethodType
  ) {
    return "my-investments.my_investment_page.metrics.investment_card.payment_method_information.validated_payment.fallback";
  }

  return `investment.payment_methode_type.label.${getInvestmentPaymentMethodType(
    investment,
  )}`;
};

export const canInvestViaPeaAccount = (
  project?: Project,
  organization?: Organization | null,
) => {
  const projectIsEligibleForPea = Boolean(
    project?.taxExemptionMechanics?.includes(TaxExemptionMechanicsEnum.PEA),
  );
  const organizationIsEligibleForPea =
    organization?.equitySavingPlan?.invalidatedAt === null &&
    organization.internalKycStatus ===
      ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED;

  return projectIsEligibleForPea && organizationIsEligibleForPea;
};

export const canInvestViaPeaPmeAccount = (
  project?: Project,
  organization?: Organization | null,
) => {
  const projectIsEligibleForPeaPme = Boolean(
    project?.taxExemptionMechanics?.includes(TaxExemptionMechanicsEnum.PEA_PME),
  );
  const organizationIsEligibleForPeaPme =
    organization?.smallBusinessEquitySavingPlan?.invalidatedAt === null &&
    organization.internalKycStatus ===
      ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED;

  return projectIsEligibleForPeaPme && organizationIsEligibleForPeaPme;
};

export const getInvestmentAvailablePaymentMethods = (
  investment: Investment,
) => {
  const methods = [PaymentMethodType.WIRE];

  if (
    investment.totalAmountInCents <= LEMONWAY.MAX_ALLOWED_AMOUNT_IN_CENTS.CARD
  ) {
    methods.push(PaymentMethodType.CARD);
  }

  if (
    investment.investorOrganization.equitySavingPlan &&
    investment.project.taxExemptionMechanics?.includes(
      TaxExemptionMechanicsEnum.PEA,
    )
  ) {
    methods.push(PaymentMethodType.PEA);
  }

  if (
    investment.investorOrganization.smallBusinessEquitySavingPlan &&
    investment.project.taxExemptionMechanics?.includes(
      TaxExemptionMechanicsEnum.PEA_PME,
    )
  ) {
    methods.push(PaymentMethodType.PEA_PME);
  }

  return methods;
};

export const checkCanFinalizeInvestment = (investment: Investment) => {
  return (
    ["waiting_for_payment", "waiting_for_signature"].includes(
      investment.globalStatus,
    ) && investment.project.status !== "finished"
  );
};
