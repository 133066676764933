import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { useQualificationIntro } from "../hooks/useQualificationIntro";

export function QualificationExitButtons() {
  const { setShowExitDialog } = useQualificationIntro();

  return (
    <>
      <Button
        onClick={() => setShowExitDialog(true)}
        variant="tertiary"
        singleIcon="Cross"
        className="z-50 flex w-fit self-end sm:hidden"
      />
      <Button
        onClick={() => setShowExitDialog(true)}
        label="Quitter"
        variant="tertiary"
        iconRight="Cross"
        className="absolute right-6 z-50 !hidden h-10 w-fit sm:!flex"
      />
    </>
  );
}
