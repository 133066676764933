import type {
  InvestmentDocument,
  InvestmentFeed,
  ProjectDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  ProjectNews,
  ProjectNewsProvider,
} from "@tudigo-monorepo/web-feature-projects";

import { InvestmentDocumentListElement } from "./investment-document-list-element";
import { ProjectDocumentListElement } from "./project-document-list-element";

export function InvestmentFeedListElement(props: {
  investmentFeedItem: InvestmentFeed;
}) {
  const investmentFeedItem = props.investmentFeedItem;

  switch (investmentFeedItem.refType) {
    case "investment_document":
      return (
        <InvestmentDocumentListElement
          investmentDocument={
            investmentFeedItem.investmentDocument as InvestmentDocument
          }
        />
      );
    case "project_document":
      return (
        <ProjectDocumentListElement
          projectDocument={
            investmentFeedItem.projectDocument as ProjectDocument
          }
        />
      );
    case "news":
      return (
        investmentFeedItem.news && (
          <ProjectNewsProvider news={investmentFeedItem.news} editMode={false}>
            <ProjectNews />
          </ProjectNewsProvider>
        )
      );
  }
}
