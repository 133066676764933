import { PropsWithChildren } from "react";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { MetricsCard } from "@tudigo-monorepo/web-tudigo-components";

import { InvestmentQuickActionsMenu } from "../../../shared/components/investment-quick-actions-menu";
import { useInvestmentMetricsCardFooterProps } from "../../../shared/hooks/use-investment-metrics-card-footer-props";
import { checkCanFinalizeInvestment } from "../../../shared/utils/investment-utils";

type MetricsInvestmentCardProps = {
  investment: Investment;
  index: number;
};

export function MetricsInvestmentCard(
  props: PropsWithChildren<MetricsInvestmentCardProps>,
) {
  const { investment, index } = props;

  const footerProps = useInvestmentMetricsCardFooterProps(investment);
  const { t } = useTranslation();

  return (
    <MetricsCard
      value={formatCurrency(investment?.totalAmountInCents ?? 0)}
      className="h-full"
      contextualMenu={<InvestmentQuickActionsMenu investment={investment} />}
      heading={{
        firstChip: {
          label: investment?.investorOrganization?.name ?? "Compte à choisir",
        },
        secondChip: {
          label: `#${index + 1}`,
          type: "info",
        },
      }}
      title={t(
        "my-investments.my_investment_page.metrics.investment_card.title",
      )}
      content={{
        sideChip: {
          label: t(`investment.global_status.${investment.globalStatus}`),
          icon: "DotChip",
          type: ["validated", "transfer_funds_failed"].includes(
            investment.globalStatus,
          )
            ? "success"
            : "warning",
          count: false,
        },
      }}
      footer={footerProps}
      navigateTo={
        checkCanFinalizeInvestment(investment)
          ? `/projects/${investment.project.id}/invest/${investment.id}`
          : undefined
      }
    />
  );
}
