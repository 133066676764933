import { createColumnHelper } from "@tanstack/react-table";

import type { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  formatCurrency,
  getImageUrl,
  ORGANIZATIONS,
} from "@tudigo-monorepo/core-tudigo-utils";
import {
  AccountCard,
  Avatar,
  Chip,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export const useWmaCustomersColumns = () => {
  const columnHelper = createColumnHelper<WMACustomer>();
  const { t } = useTranslation();

  return [
    columnHelper.display({
      header: "Client",
      cell: (info) => {
        return (
          <AccountCard
            avatar={
              info.row.original?.customerUser?.profileImage?.url ? (
                <Avatar
                  url={getImageUrl(
                    info.row.original?.customerUser?.profileImage,
                    32,
                    32,
                    "cropped",
                    "",
                  )}
                />
              ) : (
                <Avatar>
                  {info.row.original?.customerUser?.email?.charAt(0) ?? "🦊"}
                </Avatar>
              )
            }
            upperText={
              info.row.original?.customerUser?.firstName
                ? `${info.row.original?.customerUser?.firstName} ${info.row.original?.customerUser?.lastName}`
                : "Nom client non renseigné"
            }
            lowerText={info.row.original?.customerUser?.email}
            lowerTextAsChip
          />
        );
      },
    }),
    columnHelper.accessor(
      "customerUser.investorProfile.statistics.totalInvestmentsAmountInCents",
      {
        header: "Montant investi",
        cell: (info) => {
          return (
            <Typography variant="body3-regular">
              {formatCurrency(info.getValue() ?? 0)}
            </Typography>
          );
        },
      },
    ),
    columnHelper.accessor(
      "customerUser.investorProfile.statistics.totalInvestmentsCount",
      {
        header: "Investissements",
        cell: (info) => {
          return (
            <Typography variant="body3-regular">
              {info.getValue() ?? 0}
            </Typography>
          );
        },
      },
    ),
    columnHelper.accessor("status", {
      header: "Statut invitation",
      cell: (info) => (
        <Chip
          label={t(
            `wma.customers_list_page.customer.status_invitation.label.${info.getValue()}`,
          )}
          iconLeft="DotChip"
          type={(() => {
            switch (info.getValue()) {
              case "invited":
                return "waiting";
              case "accepted":
                return "success";
              case "rejected":
                return "error";
              case "canceled":
                return "info";
              default:
                return "warning";
            }
          })()}
        />
      ),
    }),
    columnHelper.accessor("status", {
      header: "Statut qualification",
      cell: (info) => {
        if (info.getValue() !== "accepted") {
          return;
        }

        const isQualificationExpired =
          !!info.row.original?.customerUser?.investorProfile
            ?.isQualificationExpired;

        return (
          <Chip
            label={
              isQualificationExpired
                ? t(
                    "wma.customers_list_page.customer.status_qualification.label.to_complete",
                  )
                : t(
                    "wma.customers_list_page.customer.status_qualification.label.completed",
                  )
            }
            iconLeft="DotChip"
            type={isQualificationExpired ? "warning" : "success"}
          />
        );
      },
    }),
    columnHelper.accessor("status", {
      header: "Statut KYC",
      cell: (info) => {
        if (info.getValue() !== "accepted") {
          return;
        }

        const isAnOrganizationKycStatusNotValid =
          info.row.original?.customerUser?.organizationsOwned?.some(
            (organization) =>
              organization.internalKycStatus !==
              ORGANIZATIONS.INTERNAL_KYC_STATUS.APPROVED,
          );

        return (
          <Chip
            label={
              isAnOrganizationKycStatusNotValid ? "À compléter" : "Complet"
            }
            iconLeft="DotChip"
            type={isAnOrganizationKycStatusNotValid ? "warning" : "success"}
          />
        );
      },
    }),
  ];
};
