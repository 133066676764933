import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useGetProjectsQuery,
  useGetUserInvestments,
  useGetUserOrganizationsQuery,
  UserInvestmentsFilters,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  FilterConfiguration,
  TudigoCollection,
  TudigoFilters,
} from "@tudigo-monorepo/web-tudigo-collection";
import {
  Button,
  ProjectCard,
  Table,
  TableCardList,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  InvestStatus,
  useInvestStatuses,
} from "../../../shared/hooks/use-invest-statuses";
import { useInvestmentsTableColumns } from "../../../shared/hooks/use-investments-table-columns";

const requestProjectFields =
  "id,mechanic,content.fields(name),holder_organization.fields(id,name,internal_kyc_status)";
const requestFields =
  "id," +
  "total_amount_in_cents," +
  "investor_organization.fields(id,name)," +
  "payment_method_type," +
  "created_at," +
  "status," +
  "global_status," +
  "project.fields(" +
  requestProjectFields +
  ")";

export function InvestmentsListContent() {
  const columns = useInvestmentsTableColumns();
  const urlInvestStatus = useParams().status as string;
  const navigate = useNavigate();

  const intl = useIntl();

  const { user } = useUserBusiness();
  const userId = user?.id as string;

  const investStatuses: InvestStatus[] = useInvestStatuses();

  const currentInvestStatus = investStatuses.find(
    ({ urlParamValue }) => urlParamValue === urlInvestStatus,
  );
  const { data: organizationsResponse } = useGetUserOrganizationsQuery({
    pathParams: { userId },
    pagination: { limit: 1000 },
  });

  const globalStatus = useMemo(() => {
    return currentInvestStatus && currentInvestStatus.urlParamValue !== "all"
      ? currentInvestStatus.filterValue
      : undefined;
  }, [currentInvestStatus]);

  const [filters, setFilters] = React.useState<UserInvestmentsFilters>({
    createdAt: undefined,
    projectId: undefined,
    investorOrganizationId: "",
    paymentMethodType: "",
    globalStatus: globalStatus,
    status: undefined,
  });

  const organizationList = useMemo(() => {
    return (
      organizationsResponse?.data.map((organization) => ({
        value: organization.id,
        name: organization.name ?? `Organization id: ${organization.id}`,
      })) ?? []
    );
  }, [organizationsResponse]);

  const { data: projects } = useGetProjectsQuery({
    filters: { status: ["started", "prefinished"] },
    pagination: { limit: 4 },
  });

  function FourProjectCards() {
    return (
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {projects?.data?.map((project = {} as Project) => (
          <div key={project.id} className=" w-full  p-2 ">
            <ProjectCard
              project={project}
              className="h-full"
              navigateTo={`${
                import.meta.env.VITE_APP_URL
              }/projects/${project?.id}`}
            />
          </div>
        ))}
      </div>
    );
  }
  const filterConfiguration: FilterConfiguration<UserInvestmentsFilters>[] =
    useMemo(() => {
      return [
        {
          label: intl.formatMessage({ id: "account" }),
          name: "investorOrganizationId",
          type: "organizationSelect",
        },
        {
          label: intl.formatMessage({
            id: "investments.table.columns.payment_method",
          }),
          name: "paymentMethodType",
          type: "select",
          placeholder: intl.formatMessage({
            id: "projects.back_office.investments_list.tab-navigation.item.label.all",
          }),
          options: [
            {
              value: "wire",
              name: intl.formatMessage({
                id: "investment.payment_methode_type.label.wire",
              }),
            },
            {
              value: "card",
              name: intl.formatMessage({
                id: "investment.payment_methode_type.label.card",
              }),
            },
            {
              value: "pea",
              name: intl.formatMessage({
                id: "investment.payment_methode_type.label.pea",
              }),
            },
          ],
        },
      ];
    }, [intl, organizationList]);

  useEffect(() => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        globalStatus: globalStatus,
      };
    });
  }, [globalStatus]);

  return (
    <div>
      <TudigoCollection
        useLocationAsState
        columns={columns}
        useQueryFn={useGetUserInvestments}
        pathParams={{ userId }}
        fields={requestFields}
        filters={filters}
        renderEmpty={() => (
          <>
            <p className="font-montserrat text-dark-2 py-8">
              {`Vous n'avez actuellement aucun investissement`}
            </p>
            <Title h3Brand="brand-2" level="h3" className="text-primary mb-1.5">
              Nouvelles opportunitées
            </Title>
            <FourProjectCards />
            <div>
              <Link to="/">
                <Button
                  variant="tertiary"
                  label="Découvrir toutes les opportunités"
                  className="m-auto"
                />
              </Link>
            </div>
          </>
        )}
        render={(table, props) => {
          return (
            <>
              <TudigoFilters
                className="mb-6 mt-4"
                useLocationAsState
                filters={filters}
                setFilters={setFilters}
                filtersConfiguration={filterConfiguration}
              />
              <Table
                table={table}
                actions={props.actions}
                className="hidden sm:table"
                onRowClick={(investment) => {
                  navigate(
                    `/my-account/my-investments/projects/${investment.project.id}`,
                  );
                }}
              />
              <TableCardList
                table={table}
                className="sm:hidden"
                onCardClick={(investment) => {
                  navigate(
                    `/my-account/my-investments/projects/${investment.project.id}`,
                  );
                }}
              />
            </>
          );
        }}
      />
    </div>
  );
}
