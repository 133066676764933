import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { WebFlowCollection } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../../index";
import { getQueryDetailKey } from "../../query-key-factory";

type GetWebflowCollectionByIdParams = CollectionRequestParams<
  { collectionId: string },
  { query?: { [key: string]: unknown } }
>;

const getWebflowCollectionById = async (
  params: GetWebflowCollectionByIdParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.webflowCollectionsEndpoints.getCollectionItems(
      params.pathParams?.collectionId ?? "",
      params.filters,
    );

  return response?.data;
};

export const useGetWebflowCollectionById = (
  params: GetWebflowCollectionByIdParams,
  options?: UseQueryOptions<TudigoResponse<WebFlowCollection>, TudigoError>,
) => {
  return useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.WEBFLOW_COLLECTIONS,
      id: params.pathParams?.collectionId ?? "",
    }),
    queryFn: () => getWebflowCollectionById(params),
    ...options,
  });
};

// Blog news data

export const useGetWebflowArticles = () => {
  const articleCollectionId = "6557fc62d09e911414c7ec12";

  return useGetWebflowCollectionById({
    pathParams: { collectionId: articleCollectionId },
  });
};

export const useGetWebflowTags = () => {
  const tagCollectionId = "66549595659091b1e674e4cc";

  return useGetWebflowCollectionById({
    pathParams: { collectionId: tagCollectionId },
  });
};

// Navbar cards data

export const useGetWebflowNavbarFundsData = () => {
  const tagCollectionId = "66471a70069f8f771154586c";

  return useGetWebflowCollectionById({
    pathParams: { collectionId: tagCollectionId },
  });
};

export const useGetWebflowNavbarAboutData = () => {
  const tagCollectionId = "6654957d191ecbab2728d0d8";

  return useGetWebflowCollectionById({
    pathParams: { collectionId: tagCollectionId },
  });
};

export const useGetWebflowNavbarResourcesData = () => {
  const tagCollectionId = "6654afe35ff9d4fb83d26dd4";

  return useGetWebflowCollectionById({
    pathParams: { collectionId: tagCollectionId },
  });
};
