import React from "react";
import { useIntl } from "react-intl";

import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

export const ClubLeaderMemberDetailInformationCard = (props: {
  clubMember: ClubMember;
}) => {
  const clubMember = props.clubMember;
  const { t } = useTranslation();
  const intl = useIntl();

  return (
    <div className="border-light-1 m-6 flex flex-1 flex-col gap-6 rounded-lg border">
      <div className=" text-dark-2 border-light-1 border-b p-6 text-sm">
        {t("club_leader.page_detail_member.information_card.title")}
      </div>
      <div className="flex flex-col gap-2 px-6 pb-6">
        <div className="flex justify-between">
          <span className="text-primary text-sm">
            {t("club_leader.page_detail_member.information_card.gender.label")}
          </span>
          <span className="text-primary text-sm font-semibold">
            {clubMember.user?.gender === "female"
              ? t("club_leader.page_detail_member.information_card.gender.f")
              : t("club_leader.page_detail_member.information_card.gender.m")}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-primary text-sm">
            {t(
              "club_leader.page_detail_member.information_card.firstname.label",
            )}
          </span>
          <span className="text-primary text-sm font-semibold">
            {clubMember.user?.firstName}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-primary text-sm">
            {t(
              "club_leader.page_detail_member.information_card.lastname.label",
            )}
          </span>
          <span className="text-primary text-sm font-semibold">
            {clubMember.user?.lastName[0] + "."}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-primary text-sm">
            {t(
              "club_leader.page_detail_member.information_card.created_at.label",
            )}
          </span>
          <span className="text-primary text-sm font-semibold">
            {intl.formatDate(clubMember.user?.createdAt)}
          </span>
        </div>
        {/*<div className="flex justify-between">*/}
        {/*  <span className="text-primary text-sm">Profil investisseur</span>*/}
        {/*  <span className="text-primary text-sm font-semibold">*/}
        {/*    {clubMember.user?.investorProfile?.qualificationData*/}
        {/*      ? "Oui"*/}
        {/*      : "Non"}*/}
        {/*  </span>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
