import { ReactNode, useCallback } from "react";
import { flexRender, Table as TanstackTable } from "@tanstack/react-table";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Typography } from "../typography";

export type Action<I> = {
  onClick?: (row: I) => void;
  render?: (row: I) => ReactNode;
  isAvailable?: (row: I) => boolean;
};

type TableProps<I> = {
  table: TanstackTable<I>;
  onRowClick?: (row: I) => void;
  actions?: Action<I>[];
  className?: string;
  isRowAvailable?: (row: I) => boolean;
};

export function Table<I>(props: TableProps<I>) {
  const { actions, table, className, onRowClick, isRowAvailable } = props;

  const isLastRow = useCallback(
    (rowIndex: number) => {
      return table.getRowModel().rows.length - 1 === rowIndex;
    },
    [table],
  );

  const hasRow = useCallback(() => {
    return table.getRowModel().rows.length > 0;
  }, [table]);

  return (
    <table
      className={cn(
        "w-full border-separate border-spacing-0 rounded-lg border",
        className,
      )}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
          <tr key={`${headerGroup.id}_ ${headerGroupIndex}`}>
            {headerGroup.headers.map((header, headerIndex) => (
              <th
                key={`${header.id}_${headerIndex}`}
                className={`${
                  hasRow() ? "border-b" : ""
                } h-[60px] px-3 py-2 text-start`}
              >
                <Typography variant="body3-regular" className="text-dark-3">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Typography>
              </th>
            ))}
            {actions && actions.length ? (
              <th
                key="actions"
                className={`${
                  hasRow() ? "border-b" : ""
                } h-[60px] px-3 py-2 text-start`}
              ></th>
            ) : null}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => {
          if (isRowAvailable?.(row.original) || isRowAvailable === undefined) {
            return (
              <tr
                key={`${row.id}_${rowIndex}`}
                className={cn({
                  "cursor-pointer": onRowClick !== undefined,
                })}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onRowClick && onRowClick(row.original);
                }}
              >
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <td
                    key={`${cell.id}_${cellIndex}`}
                    className={`${
                      !isLastRow(rowIndex) ? "border-b" : ""
                    } h-[60px] px-3 py-2`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
                {actions && actions.length > 0 ? (
                  <td
                    key="actions"
                    className={`${
                      !isLastRow(rowIndex) ? "border-b" : ""
                    } h-[60px] px-3 py-2`}
                  >
                    <div className="flex flex-row justify-end">
                      {actions.map((action, actionIndex) => {
                        if (
                          action.isAvailable?.(row.original) ||
                          action.isAvailable === undefined
                        ) {
                          return (
                            <div
                              key={`${row.id}_${rowIndex}_${actionIndex}`}
                              className="text-dark-1 cursor-pointer px-3"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                action.onClick?.(row.original);
                              }}
                            >
                              {action.render?.(row.original)}
                            </div>
                          );
                        }

                        return null;
                      })}
                    </div>
                  </td>
                ) : null}
              </tr>
            );
          }

          return null;
        })}
      </tbody>
    </table>
  );
}
