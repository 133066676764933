import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { Chip } from "../../../old/chip";
import { ClubProfilePicture } from "../club-profile-picture/club-profile-picture";

type CircleCardProps = {
  circle: ClubV2;
  onClick: () => void;
};

export const CircleCard = ({ circle, onClick }: CircleCardProps) => {
  const { t } = useTranslation();

  return (
    <button
      className="border-light-1 rounded-lg border p-4"
      key={circle.id}
      onClick={onClick}
    >
      <div className="flex flex-col gap-4">
        <header className="flex justify-center sm:justify-between">
          <div className="flex flex-col items-center gap-4 sm:flex-row">
            <ClubProfilePicture club={circle} size="S" />
            <div className="flex flex-col sm:max-w-[70%] sm:items-start">
              <h2 className="font-obviously text-lg font-semibold">
                {circle.content?.name}
              </h2>
              <p className="text-dark-2 text-xs sm:text-left">
                {circle.content?.baseline}
              </p>
            </div>
          </div>
          <div className="hidden items-center gap-2 sm:flex sm:self-start">
            <span className="text-dark-1 text-xs">
              {t("circles_listing_page.circle_card.projects.label")}
            </span>
            <Chip
              label={circle.statistics?.liveProjectsCount.toString()}
              type="secondary"
            />
          </div>
        </header>
        <div className="flex flex-col">
          <ListWithMore
            items={circle.industries ?? []}
            label={t("circles_listing_page.circle_card.industries.label")}
          />
          <ListWithMore
            items={circle.maturities ?? []}
            label={t("circles_listing_page.circle_card.maturities.label")}
          />
          <ListWithMore
            items={circle.territories ?? []}
            label={t("circles_listing_page.circle_card.territories.label")}
          />
          <ListWithMore
            items={circle.mentorings ?? []}
            label={t("circles_listing_page.circle_card.mentorings.label")}
          />
        </div>
        <CircleCardFooter circle={circle} />
      </div>
    </button>
  );
};

type ListWithMoreProps = {
  items: { name: string }[];
  label: string;
};

const ListWithMore = ({ items, label }: ListWithMoreProps) => {
  if (!items || items.length === 0) return null;

  return (
    <div className="my-1 flex flex-col gap-1 sm:flex-row sm:gap-2">
      <span className="text-dark-1 text-sm">{label}</span>
      <span className="text-dark-1 text-sm font-semibold">
        {items.slice(0, 2).map((item, index) => (
          <span key={index}>
            {item.name}
            {index < 1 && index < items.length - 1 ? ", " : ""}
          </span>
        ))}
        {items.length > 2 && <span className="ml-2">+{items.length - 2}</span>}
      </span>
    </div>
  );
};

const CircleCardFooter = ({ circle }: { circle: ClubV2 }) => {
  const { t } = useTranslation();

  return (
    <footer className="border-light-1 flex items-center justify-center gap-4 border-t border-dashed pt-4">
      <div className="flex items-center -space-x-2">
        {circle.leaders
          ?.slice(0, 3)
          .map((leader) => (
            <img
              key={leader.id}
              className="inline-block size-8 rounded-full object-cover ring-2 ring-white"
              src={leader?.profileImage?.url ?? ""}
              alt="Club Leader Avatar"
            />
          ))}
        {circle.leaders?.length > 3 && (
          <Chip
            label={`+${circle.leaders.length - 3}`}
            type="info"
            className="h-6"
          />
        )}
      </div>
      <p className="text-primary text-sm">
        {t("page_club.club_membership_component.club_leaders.paragraph", {
          followersCount: circle.statistics.followersCount.toString(),
        })}
      </p>
    </footer>
  );
};
