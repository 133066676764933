import { useOutletContext } from "react-router-dom";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import { Project } from "@tudigo-monorepo/core-tudigo-api-models";

type EditProjectPageContext = {
  projectFormData: Project;
  saveProjectForm: () => void;
  setProjectFormData: (project: Project) => void;
  updateError: TudigoError | null;
};

function useEditProjectPageContext() {
  return useOutletContext<EditProjectPageContext>();
}

export { useEditProjectPageContext, EditProjectPageContext };
