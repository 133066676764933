import { toast } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  useUpdateProjectNewsMutation,
  type UpdateNewsParams,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";

import { useEditProjectPageContext } from "../../hooks/use-edit-project-page-context";
import { projectNewsRequestFields } from "./edit-project-news-content";
import type { ProjectNewsFormValues } from "./project-news-form/form-schema";

export const useUpdateProjectNews = (onSuccessCallback: () => void) => {
  const { t } = useTranslation();
  const { projectFormData } = useEditProjectPageContext();

  const { mutate: updateProjectNewsMutation, isPending } =
    useUpdateProjectNewsMutation({
      onSuccess: () => {
        toast((content) => (
          <Alert
            icon="Info"
            close={content.closeToast}
            variant="success"
            size="S"
            title={t(
              "back_office.edit_project.project_news.update_news.success_message",
            )}
          />
        ));
        onSuccessCallback();
      },
    });

  const updateProjectNews = (values: ProjectNewsFormValues) => {
    const newsId = values.id;

    if (!newsId) {
      console.error("Cannot update project news. News ID is missing.");

      return;
    }

    const payload: UpdateNewsParams = {
      fields: projectNewsRequestFields,
      newsId,
      data: {
        id: projectFormData.id,
        type: values.type,
        title: values.title,
      },
    };

    if (values.type === "information") {
      payload.data.description = values.description ?? undefined;
    }

    if (values.type === "document") {
      payload.data.document = values.document?.[0];
    }

    if (values.type === "video") {
      payload.data.video = {
        title: values.video?.title,
        url: values.video?.url,
      };
    }

    return updateProjectNewsMutation(payload);
  };

  return { updateProjectNews, isPending };
};
