import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import {
  WMACustomer,
  WMACustomerStatusEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import {
  useGetWMACustomerByIdQuery,
  useUpdateWMACustomerInvitationMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  PageHead,
} from "@tudigo-monorepo/web-tudigo-components";

import { useGetWMACustomerInvitationChipStatusProps } from "../../shared/hooks/use-wma-customer-invitation-status-utils";
import { getWMAFullNameFromWMACustomer } from "../../shared/utils/wma-customer-utils";

export function EditWMACustomerInvitationPage() {
  const { wmaCustomerId } = useParams();

  const { data: wmaCustomerResponse, isLoading: isFetching } =
    useGetWMACustomerByIdQuery({
      pathParams: {
        wmaCustomerId: wmaCustomerId as string,
      },
    });

  const { mutate: updateWMACustomerInvitation, isPending: isUpdating } =
    useUpdateWMACustomerInvitationMutation({
      onSuccess: () => {
        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Checked"
            variant="success"
            size="S"
            title={t(
              "wma.web_app.edit_wma_customer_invitation_page.alert.success",
            )}
          />
        ));
      },
      onError: (error: TudigoError) => {
        setUpdateError(error);
        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Error"
            variant="error"
            size="S"
            title={t(
              "wma.web_app.edit_wma_customer_invitation_page.alert.error",
            )}
          />
        ));
      },
    });

  const [wmaCustomerFormData, setWmaCustomerFormData] = useState<WMACustomer>(
    {},
  );
  const [updateError, setUpdateError] = useState<TudigoError | null>(null);

  useEffect(() => {
    if (wmaCustomerResponse?.data && !wmaCustomerFormData.id) {
      setWmaCustomerFormData(wmaCustomerResponse.data);
    }
  }, [wmaCustomerResponse?.data, wmaCustomerFormData]);

  const { t } = useTranslation();

  const { SmartToggleField } = getSmartFormComponents<WMACustomer>();

  const chipStatusProps = useGetWMACustomerInvitationChipStatusProps(
    wmaCustomerResponse?.data,
  );

  if (!wmaCustomerFormData.id) {
    return null;
  }

  const wmaFullName = getWMAFullNameFromWMACustomer(wmaCustomerResponse?.data);

  const handleUpdateWMACustomerInvitation = () => {
    if (
      wmaCustomerFormData.status !== undefined &&
      wmaCustomerFormData?.status !== wmaCustomerResponse?.data.status
    ) {
      updateWMACustomerInvitation({
        wmaCustomerId: wmaCustomerId as string,
        wmaCustomerUserId: String(wmaCustomerFormData.customerUser?.id),
        data: {
          status: wmaCustomerFormData.status,
        },
      });
    }
  };

  return (
    <div>
      <PageHead
        withEndLine
        title={t("wma.web_app.edit_wma_customer_invitation_page.title")}
        levels={[{ url: "/my-account/wma/invitations", iconName: "UserChat" }]}
        chipsTop={chipStatusProps ? [chipStatusProps] : undefined}
        chipsBottom={[{ label: wmaFullName }]}
      />

      <div className="border-light-1 m-6 flex flex-col gap-y-6 rounded-md border p-4">
        <p className="font-montserrat text-dark-2 text-[13px]">
          {t("wma.web_app.edit_wma_customer_invitation_page.desc", {
            wmaFullName,
            // wmaUserOrganization: "WMA Organization", // <= TODO MVP 2
          })}
        </p>

        <FormContextProvider
          formData={wmaCustomerFormData}
          setFormData={setWmaCustomerFormData}
          error={updateError}
        >
          <SmartToggleField
            name="status"
            required
            label={t(
              "wma.web_app.edit_wma_customer_invitation_page.toggle.label",
            )}
            leftText={t(
              "wma.web_app.edit_wma_customer_invitation_page.toggle.option_left",
            )}
            rightText={t(
              "wma.web_app.edit_wma_customer_invitation_page.toggle.option_right",
            )}
            mapValueChecked={(value: string) =>
              value === WMACustomerStatusEnum.ACCEPTED
            }
            mapCheckedValue={(checked: boolean) =>
              checked
                ? WMACustomerStatusEnum.ACCEPTED
                : WMACustomerStatusEnum.REJECTED
            }
          />
          <Button
            variant="primary"
            label={t(
              "wma.web_app.edit_wma_customer_invitation_page.submit.label",
            )}
            className="w-[82px]"
            onClick={handleUpdateWMACustomerInvitation}
            disabled={
              isFetching ||
              isUpdating ||
              wmaCustomerFormData.status === wmaCustomerResponse?.data.status
            }
          />
        </FormContextProvider>
      </div>
    </div>
  );
}
