import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { ThinLayout } from "@tudigo-monorepo/web-tudigo-components";

import { ProjectModalitiesCard } from "../../../shared-internal/components/project-modalities-card";
import { ProjectLimitedAccess } from "../../components/project-limited-access";
import { useProjectContext } from "../../hooks/use-project-context";
import { getProjectComponentByCMSBlock } from "../../utils/get-project-component-by-cms-block";
import { ProjectModalitiesLegalInfo } from "./project-modalities-legal-info";

export function ProjectModalities() {
  const project = useProjectContext();
  const { isQualifiedOrWma } = useUserBusiness();

  if (!isQualifiedOrWma) {
    return (
      <ThinLayout>
        <ProjectLimitedAccess />
      </ThinLayout>
    );
  }

  return (
    <section className="flex flex-col gap-y-6">
      {project?.content?.modalities
        .map((block) =>
          getProjectComponentByCMSBlock({
            ...block,
          }),
        )
        .map((Block, index) => <Block key={index} />)}

      <ProjectModalitiesCard />
      <ProjectModalitiesLegalInfo />
    </section>
  );
}
