import { FC } from "react";

import { CMSBlock, Project } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  AnalyzeBlock,
  AudioBlock,
  ButtonBlock,
  CarouselBlock,
  ChronologyBlock,
  GalleryBlock,
  HighlightBlock,
  HtmlBlock,
  IFrameBlock,
  ImageAndTextBlock,
  ImageBlock,
  ListBlock,
  ParagraphBlock,
  QuotesBlock,
  TableBlock,
  VideoBlock,
} from "@tudigo-monorepo/web-tudigo-editor";

import { ProjectCardBlock } from "../../edit-project-page/components/blocks/project-card-block";
import { ProjectCardsBlock } from "../../edit-project-page/components/blocks/project-cards-block";
import { ProjectHeadingBlock } from "../../edit-project-page/components/blocks/project-heading-block";
import { ProjectUserCardsBlock } from "../../edit-project-page/components/blocks/project-user-cards-block";

export type ProjectEditorPluginContext = {
  project: Project;
};

export const getProjectComponentByCMSBlock = (block: CMSBlock): FC => {
  switch (block.type) {
    case "button":
      return () => <ButtonBlock block={block} />;
    case "card-text":
      return () => <ProjectCardBlock block={block} />;
    case "cards-text":
    case "cards-text-with-image":
      return () => <ProjectCardsBlock block={block} />;
    case "cards-profile":
      return () => <ProjectUserCardsBlock block={block} />;
    case "cards-quote":
      return () => <QuotesBlock block={block} />;
    case "carousel":
      return () => <CarouselBlock block={block} />;
    case "chronology":
      return () => <ChronologyBlock block={block} />;
    case "gallery":
      return () => <GalleryBlock block={block} />;
    case "heading":
      return () => <ProjectHeadingBlock block={block} />;
    case "iframe":
      return () => <IFrameBlock block={block} />;
    case "audio":
      return () => <AudioBlock block={block} />;
    case "images":
      return () => <ImageBlock block={block} />;
    case "image-and-text":
      return () => <ImageAndTextBlock block={block} />;
    case "list":
      return () => <ListBlock block={block} />;
    case "paragraph":
      return () => <ParagraphBlock block={block} />;
    case "table":
      return () => <TableBlock block={block} />;
    case "video":
      return () => <VideoBlock block={block} />;
    case "html":
      return () => <HtmlBlock block={block} />;
    case "highlight":
      return () => <HighlightBlock block={block} />;
    case "analyze":
      return () => <AnalyzeBlock block={block} />;
    default:
      console.error(
        `We could not find the component for the block type ${block.type}`,
        block,
      );

      return () => null;
  }
};
