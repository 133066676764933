import { MouseEvent, useState } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  ButtonRebrand,
  Icon,
  VideoModal,
  VideoModalTrigger,
} from "@tudigo-monorepo/web-tudigo-components";

import { useProjectNewsContext } from "../../state/project-news.context";
import { ProjectNewsListElement } from "./project-news-list-element";

export function ProjectNews() {
  const { news } = useProjectNewsContext();

  switch (news?.type) {
    case "information":
      return <ProjectNewsInformations />;
    case "document":
      return <ProjectNewsDocument />;
    case "video":
      return <ProjectNewsVideo />;
    default:
      return null;
  }
}

function ProjectNewsInformations() {
  const { news } = useProjectNewsContext();

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = (e?: MouseEvent) => {
    e?.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <>
      <ProjectNewsListElement>
        {news?.description && (
          <ButtonRebrand
            variant="ghost"
            className="tems-center flex justify-end gap-x-2"
            onClick={toggleExpanded}
          >
            <Icon name={expanded ? "Minus" : "Plus"} />
          </ButtonRebrand>
        )}
      </ProjectNewsListElement>

      {news?.description && (
        <div
          className={cn(
            "border-light-1 rounded-b-lg border-b border-l border-r p-6",
            {
              hidden: !expanded,
            },
          )}
        >
          <p className="font-sans">{news.description}</p>
        </div>
      )}
    </>
  );
}

function ProjectNewsDocument() {
  const { news } = useProjectNewsContext();

  const handleOpenNewsDocument = () => window.open(news?.document?.signedUrl);

  return (
    <ProjectNewsListElement>
      {news?.document?.signedUrl && (
        <ButtonRebrand
          variant="ghost"
          className="flex items-center justify-end gap-x-2"
          onClick={handleOpenNewsDocument}
        >
          <p className="text-dark-1 text-sm">
            {news.document?.mimeTypeExtension ? (
              <span className="uppercase">
                {news.document?.mimeTypeExtension}
              </span>
            ) : (
              "Document"
            )}
          </p>
          <Icon name="Download" />
        </ButtonRebrand>
      )}
    </ProjectNewsListElement>
  );
}

function ProjectNewsVideo() {
  const { news } = useProjectNewsContext();

  return (
    <ProjectNewsListElement>
      {news?.video?.url && (
        <VideoModal
          itemId={news.id}
          url={news.video?.url}
          title={news.video?.title}
        >
          <VideoModalTrigger className="flex items-center justify-end" />
        </VideoModal>
      )}
    </ProjectNewsListElement>
  );
}
