import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { ProjectLimitedAccess } from "../../components/project-limited-access";
import { useProjectContext } from "../../hooks/use-project-context";
import { getProjectComponentByCMSBlock } from "../../utils/get-project-component-by-cms-block";
import { ProjectPresentationLegalInfo } from "./project-presentation-legal-info";
import { ProjectPresentationSummary } from "./project-presentation-summary";

export function ProjectPresentation() {
  const project = useProjectContext();
  const { isQualifiedOrWma } = useUserBusiness();

  if (!project) return null;

  if (!isQualifiedOrWma) {
    return (
      <div className="flex flex-col gap-4">
        {project.content?.shortPresentation
          .map((block) =>
            getProjectComponentByCMSBlock({
              ...block,
            }),
          )
          .map((Block, index) => <Block key={index} />)}
        <ProjectLimitedAccess hideGradient />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <ProjectPresentationSummary />
      {project.content?.description
        .map((block) =>
          getProjectComponentByCMSBlock({
            ...block,
          }),
        )
        .map((Block, index) => <Block key={index} />)}
      <ProjectPresentationLegalInfo />
    </div>
  );
}
