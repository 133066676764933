import { ChangeEvent, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useIntl } from "react-intl";

import { SortDirection } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  dateDiffInDaysFromNow,
  QUERY_KEYS,
} from "@tudigo-monorepo/core-tudigo-utils";
import {
  useCreateCommentMutation,
  useGetCommentsByProjectIdQuery,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  AddComment,
  Comment,
  InputSelect,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { sortDirections } from "../../../../shared-external/models/sort-direction";
import { useProjectContext } from "../../hooks/use-project-context";

export const QuestionsAnswers = () => {
  const intl = useIntl();
  const project = useProjectContext();

  const [newComment, setNewComment] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("DESC");

  const queryClient = useQueryClient();

  const { mutate: createComment } = useCreateCommentMutation({
    onSuccess: () => {
      setNewComment("");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMMENTS] });
    },
  });

  if (!project) return null;
  const handleChangeNewComment = (event: ChangeEvent<HTMLInputElement>) => {
    setNewComment(event.target.value);
  };

  const handleCreateComment = () => {
    createComment({
      project: { id: project.id },
      value: newComment,
    });
  };

  const handleReplyComment = (newReply: string, parentId: string) => {
    createComment({
      project: { id: project.id },
      value: newReply,
      parent: { id: parentId },
    });
  };

  return (
    <div className="col-start-0 col-span-12 flex flex-col gap-8 px-4 md:col-span-6 md:col-start-2 md:px-0">
      <Title className="border-b pb-5" level="h2">
        {intl.formatMessage({
          id: "projects.web_app.questions_answers.title.label",
        })}
      </Title>
      <Typography variant="body2-semibold" className="text-dark-1">
        {intl.formatMessage({
          id: "projects.web_app.questions_answers.informations.label",
        })}
      </Typography>
      <AddComment
        className="border-b pb-5"
        newComment={newComment}
        handleChangeNewComment={handleChangeNewComment}
        handleCreateComment={handleCreateComment}
        placeholder={intl.formatMessage({
          id: "projects.web_app.questions_answers.ask.label",
        })}
      />
      <div className="bg-light-3 flex justify-end rounded-md p-5">
        <InputSelect
          className="w-1/2"
          label={intl.formatMessage({
            id: "projects.web_app.questions_answers.sort_by.label",
          })}
          labelPosition="inside"
          comparisonFunction={(item1, item2) => item1.value === item2}
          items={sortDirections}
          getValueFromItem={(item) => item.value}
          labelFunction={(item) =>
            intl.formatMessage({
              id: item.label,
            })
          }
          onChange={(newValue) => setSortDirection(newValue as "DESC" | "ASC")}
          selectedValue={sortDirection}
        />
      </div>
      <TudigoCollection
        columns={[]}
        useQueryFn={useGetCommentsByProjectIdQuery}
        pathParams={{ projectId: project.id }}
        fields="id,author,value,created_at,replies"
        filters={{ sort_direction: sortDirection ?? "DESC" }}
        pagination={{ pageIndex: 0, pageSize: 5 }}
        render={(table, collection, comments) =>
          comments?.data?.map((comment) => (
            <>
              <Comment
                handleReplyComment={handleReplyComment}
                key={comment.id}
                author={`${comment.author.firstName} ${comment.author.lastName}`}
                comment={comment.value}
                date={intl.formatMessage(
                  {
                    id: "projects.web_app.questions_answers.date.label",
                  },
                  {
                    days: dateDiffInDaysFromNow(new Date(comment.createdAt)),
                  },
                )}
                commentId={comment.id}
                profileImage={comment.author.profileImage}
              />

              {comment?.replies?.map((reply) => (
                <Comment
                  className="before:bg-light-1 relative w-11/12 self-end rounded-md border p-5 before:absolute before:bottom-full before:left-[7%] before:h-[26px] before:w-px"
                  key={reply.id}
                  handleReplyComment={handleReplyComment}
                  author={`${reply.author.firstName} ${reply.author.lastName}`}
                  comment={reply.value}
                  date={intl.formatMessage(
                    {
                      id: "projects.web_app.questions_answers.date.label",
                    },
                    {
                      days: dateDiffInDaysFromNow(new Date(reply.createdAt)),
                    },
                  )}
                  commentId={comment.id}
                  profileImage={reply.author.profileImage}
                />
              ))}
            </>
          ))
        }
      />
    </div>
  );
};
