import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import {
  TudigoError,
  type CertificateTitleType,
} from "@tudigo-monorepo/core-tudigo-api";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";

type GenerateInvestmentAccountCertificateRequestParams = {
  type: CertificateTitleType;
  investorId: string;
  holdingId: string;
};

export const generateInvestmentAccountCertificate = async (
  params: GenerateInvestmentAccountCertificateRequestParams,
) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.investments.generateInvestmentAccountCertificate(
      params.type,
      {
        investorId: params.investorId,
        holdingId: params.holdingId,
      },
    );

  return response.data;
};

export const useGenerateInvestmentAccountCertificateMutation = (
  options?: UseMutationOptions<
    Blob,
    TudigoError,
    GenerateInvestmentAccountCertificateRequestParams
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: GenerateInvestmentAccountCertificateRequestParams) =>
      generateInvestmentAccountCertificate(params),
    onSuccess: (...props) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.INVESTMENTS],
      });
      options?.onSuccess?.(...props);
    },
  });
};
