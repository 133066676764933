import { useEffect, useState } from "react";

import {
  PromotionalBlocks,
  Sidebar,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { AppointmentCard } from "../shared-internal/components/appointment-card";
import { CustomSearchBox } from "./component/custom-search-box";
import { ProjectsFilters } from "./component/projects-filters";
import { ProjectsPageHeader } from "./component/projects-page-header";
import { ProjectsPageMainContent } from "./component/projects-page-main-content";
import { ProjectsPageMenuMobile } from "./component/projects-page-menu-mobile";
import { ResetFilters } from "./component/reset-filters";
import { useProjectsRefinements } from "./hooks/use-projects-refinements";

export function ProjectsPage() {
  const { totalRefinementsCount } = useProjectsRefinements();
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [totalRefinementsCount]);

  return (
    <div className="flex w-full">
      <Sidebar
        expanded
        className="h-screen-custom top-[75px] w-[264px] gap-y-8 overflow-y-scroll px-4 lg:flex"
      >
        <div className="flex items-center justify-between">
          <Title level="h3" h3Brand="brand-2">
            Filtres
          </Title>
          <ResetFilters />
        </div>
        <ProjectsFilters />
      </Sidebar>

      <ProjectsPageMenuMobile visible={isMenuMobileOpen}>
        <ProjectsPageMenuMobile.Head
          onClose={() => setIsMenuMobileOpen(false)}
        />
        <ProjectsPageMenuMobile.Actions>
          <ProjectsFilters />
        </ProjectsPageMenuMobile.Actions>
        <ProjectsPageMenuMobile.Footer
          onClose={() => setIsMenuMobileOpen(false)}
        />
      </ProjectsPageMenuMobile>

      <div className="flex h-full w-full flex-col gap-y-6 overflow-x-hidden">
        <div className="px-4 py-6 lg:px-6">
          <ProjectsPageHeader>
            <ProjectsPageHeader.Top />
            <ProjectsPageHeader.Actions>
              <CustomSearchBox />
              <ProjectsPageHeader.ActionsMobile>
                <ProjectsPageHeader.MenuTrigger
                  onClick={() => setIsMenuMobileOpen(true)}
                />
              </ProjectsPageHeader.ActionsMobile>
            </ProjectsPageHeader.Actions>
          </ProjectsPageHeader>

          <ProjectsPageMainContent>
            <ProjectsPageMainContent.Fallback />
            <ProjectsPageMainContent.Results />
            <ProjectsPageMainContent.EmptyResults />
            <ProjectsPageMainContent.ProjectsGallery />
          </ProjectsPageMainContent>
        </div>

        <PromotionalBlocks className="bg-light-3 px-6 py-8 md:-ml-4 xl:-ml-0">
          <AppointmentCard />
        </PromotionalBlocks>
      </div>
    </div>
  );
}
