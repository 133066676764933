import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  clubV2QueryFields,
  QUERY_KEYS,
} from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey, getQueryListKey } from "../../..";

type UpdateCircleMemberParam = {
  clubMemberId: string;
  body: {
    status: ClubMember["status"];
    generateContract?: boolean;
  };
  query?: {
    fields?: string;
  };
};

export const updateCircleMember = async (params: UpdateCircleMemberParam) => {
  const { clubMemberId, body, query } = params;
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.updateCircleMember(
    clubMemberId,
    body,
    query,
  );

  return response.data;
};

export const useUpdateCircleMember = (
  options?: UseMutationOptions<
    TudigoResponse<ClubMember>,
    TudigoError,
    UpdateCircleMemberParam
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params) => updateCircleMember(params),
    onSuccess: (...props) => {
      const [response, params] = props;

      const clubQueryKey = getQueryDetailKey({
        key: QUERY_KEYS.CLUBS,
        id: response.data.club.id!,
        fields: clubV2QueryFields,
      });
      queryClient.invalidateQueries({
        queryKey: clubQueryKey,
        refetchType: "all",
      });

      const memberQueryKey = getQueryDetailKey({
        key: QUERY_KEYS.CLUB_MEMBER,
        id: `${response.data.club.id}-${response.data.id}`,
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKey,
        refetchType: "all",
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CLUBS],
        refetchType: "all",
      });

      const queryKeyMyMemberships = getQueryListKey({
        key: QUERY_KEYS.MY_CLUB_MEMBERS,
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeyMyMemberships],
        refetchType: "all",
      });

      options?.onSuccess?.(...props);
    },
  });
};
