import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { CreditorRepayment } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  CollectionRequestParams,
  getApiClient,
  getQueryListKey,
} from "../../../";

type CreditorsRepaymentsPeriodsByInvestmentQuery = {
  fields?: string;
  limit?: number;
  offset?: number;
  sort_direction?: "ASC" | "DESC";
};

export const getCreditorsRepaymentsPeriodsByInvestment = async (
  investmentId: string,
  query: CreditorsRepaymentsPeriodsByInvestmentQuery,
) => {
  const apiClient = getApiClient();

  if (investmentId === undefined) {
    return {
      data: [],
      pagination: {
        count: 0,
      },
    } as TudigoResponse<any>;
  }
  const response =
    await apiClient.apiV1.repayments.getCreditorsRepaymentsPeriodsForInvestment(
      investmentId,
      query,
    );

  return response.data;
};

export const useGetCreditorsRepaymentsPeriodsForInvestment = (
  params: CollectionRequestParams<
    { investmentId: string },
    CreditorsRepaymentsPeriodsByInvestmentQuery
  >,
  options?: UseQueryOptions<TudigoResponse<CreditorRepayment[]>, TudigoError>,
) => {
  const { pagination, fields, filters, pathParams } = params;

  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.DEBTOR_REPAYMENT_CREDITORS_REPAYMENTS,
      fields,
      filters,
    }),
    queryFn: () =>
      getCreditorsRepaymentsPeriodsByInvestment(
        pathParams?.investmentId ?? "",
        {
          ...filters,
          fields: fields,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      ),
    ...options,
  });
};
