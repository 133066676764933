import { useCallback, useEffect, type PropsWithChildren } from "react";
import type { VariantProps } from "class-variance-authority";
import { PlayIcon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTimeSpent } from "@tudigo-monorepo/core-tudigo-utils";

import { Button } from "../../old/button";
import {
  ButtonRebrand,
  buttonRebrandVariants,
} from "../../rebrand/ui/button-rebrand";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../dialog/dialog";
import { useVideoModalStore } from "./video-modal.store";
import {
  addVideoToUserWatchList,
  getUserVideoByItemId,
  setUserVideoAsWatched,
} from "./video-modal.utils";

interface VideoModalTriggerProps
  extends PropsWithChildren,
    VariantProps<typeof buttonRebrandVariants> {
  rebrand?: boolean;
  className?: string;
}

function VideoModalTrigger(props: VideoModalTriggerProps) {
  const setIsOpen = useVideoModalStore((state) => state.setIsOpen);

  if (!props.children) {
    return (
      <ButtonRebrand variant="ghost" onClick={() => setIsOpen(true)}>
        <PlayIcon className="fill-dark-2 h-5 w-5" />
      </ButtonRebrand>
    );
  }

  if (props.rebrand) {
    return (
      <ButtonRebrand
        onClick={() => setIsOpen(true)}
        className={cn("w-full text-sm sm:w-fit", props.className)}
        size="icon"
        variant={props.variant}
      >
        <PlayIcon className="mr-4 h-5 w-5 fill-white" />
        {props.children}
      </ButtonRebrand>
    );
  }

  return (
    <Button
      label={props.children}
      onClick={() => setIsOpen(true)}
      className={cn("w-full text-sm sm:w-fit", props.className)}
      iconLeft="Play"
    />
  );
}

interface VideoModalProps extends PropsWithChildren {
  itemId?: string;
  url?: string;
  title?: string;
  className?: string;
  onClose?: (timeSpent: number) => void;
  /**
   Determines if the modal should open automatically (when component has mounted) or manually by clicking on a button
   Use "auto" to open the modal automatically
   Default: "manual"
   */
  openMode?: "manual" | "auto";
  /**
   Determines if the modal can be reopened after being closed. If true, it will be marked as watched and won't be reopenable again
   Use true to open the modal only once
   Default: false
   */
  watchOnce?: boolean;
}

function VideoModal(props: VideoModalProps) {
  const {
    itemId,
    title,
    url,
    children,
    className,
    openMode = "manual",
    watchOnce = false,
  } = props;

  const isOpen = useVideoModalStore((state) => state.isOpen);
  const setIsOpen = useVideoModalStore((state) => state.setIsOpen);

  const { stop } = useTimeSpent({
    onEnded: props.onClose,
  });

  useEffect(
    function handleAutomaticOpening() {
      if (!itemId || !url || openMode === "manual") {
        return;
      }

      const video = getUserVideoByItemId(itemId);

      if (!video) {
        setIsOpen(true);
        addVideoToUserWatchList({ itemId, url });

        return;
      }

      if (!video.watched) {
        setIsOpen(true);
      }
    },
    [openMode, itemId, url, setIsOpen],
  );

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);

    stop();

    if (watchOnce && itemId) {
      setUserVideoAsWatched(itemId);
    }
  }, [itemId, watchOnce, setIsOpen, stop]);

  if (!itemId || !url) {
    return null;
  }

  return (
    <>
      {children}

      <Dialog open={isOpen} onOpenChange={handleCloseModal}>
        <DialogContent
          className={cn(
            "w-[calc(100%-32px)] rounded-md md:max-w-[600px]",
            className,
          )}
        >
          <DialogHeader>
            <DialogTitle className="text-pretty pr-16 text-left">
              {title}
            </DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <div className="mx-auto flex h-[362px] flex-col items-center gap-y-4 lg:p-4">
              <iframe
                className="bg-light-1 aspect-video h-full w-full object-cover object-center"
                src={url}
                title={title}
                allowFullScreen
              />
            </div>
          </DialogDescription>
          <DialogFooter />
        </DialogContent>
      </Dialog>
    </>
  );
}

export { VideoModal, VideoModalTrigger };
