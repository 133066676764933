import type { PropsWithChildren } from "react";
import { ChevronDown } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type FiltersPanelProps = PropsWithChildren<{
  title: string;
  active?: boolean;
  open?: boolean;
  onClick?: () => void;
}>;

export const FiltersPanel = ({
  children,
  title,
  active = false,
  open = false,
  onClick,
}: FiltersPanelProps) => {
  return (
    <div className="flex flex-col gap-y-3">
      <button
        onClick={onClick}
        className={cn(
          "flex items-center justify-between py-2 md:pointer-events-none md:cursor-default",
        )}
      >
        <p
          className={cn(
            "font-montserrat text-dark-2 text-[13px] font-bold transition-colors",
            { "text-accent": active },
          )}
        >
          {title}
        </p>

        <ChevronDown
          className={cn(
            "text-dark-2 h-6 w-6 rotate-0 transition-all md:hidden",
            {
              "rotate-180": open,
            },
          )}
        />
      </button>

      <div className={cn("block md:block", { hidden: !open })}>{children}</div>
    </div>
  );
};
