import React from "react";

import { InvestorDocument } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetInvestorDocumentsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Icon,
  ListElement,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

export function InvestorDocumentList() {
  const { user } = useUserBusiness();
  const userId = user?.id as string;
  const { t } = useTranslation();

  const { data: documentsResponse, isLoading } = useGetInvestorDocumentsQuery({
    pathParams: { userId },
    pagination: { limit: 1000 },
    fields: "id,created_at,updated_at,type,document",
  });

  if (isLoading || documentsResponse?.data?.length === 0) {
    return null;
  }

  const displaySignatureDate = (investorDocument: InvestorDocument): string => {
    if (investorDocument.updatedAt) {
      return t("my-profile-exemptions.tax-document-signed.document-signed-on", {
        date: new Date(investorDocument.updatedAt).toLocaleDateString(),
      });
    }

    return "";
  };

  const getDocumentName = (investorDocument: InvestorDocument): string => {
    if (investorDocument.type === "ifu") {
      const date = new Date(investorDocument.createdAt);

      return `IFU ${date.getFullYear() - 1}`;
    }

    return investorDocument.type;
  };

  if (documentsResponse?.data?.length === 0) {
    return null;
  }

  return (
    <div className="m-6 pt-6">
      <Title h3Brand="brand-2" className="text-primary mb-6" level="h3">
        Documents fiscaux
      </Title>
      {documentsResponse?.data.map((investorDocument, index) => (
        <div key={index} className="flex flex-col">
          <div className="mb-6 flex-row" key={investorDocument.id}>
            <ListElement
              className="w-full"
              toggleActiveOnClick={false}
              startElement={{
                img: { icon: { name: "Document" } },
                upperText: getDocumentName(investorDocument),
                profileCardClassName: "sm:min-w-[200px]",
              }}
              middleElement={{
                chips: [
                  {
                    label: t(
                      "my-profile-exemptions.tax-document-signed.individual-label",
                    ),
                    type: "secondary",
                  },
                ],
              }}
              endElement={{
                render: () => (
                  <div
                    className="flex cursor-pointer items-center gap-x-6 p-3"
                    onClick={() =>
                      window.open(
                        investorDocument.document?.signedUrl || undefined,
                      )
                    }
                  >
                    <Typography variant="body3-regular">
                      {t("my-profile-exemptions.tax-document-signed.download")}
                    </Typography>
                    <Icon
                      name="Download"
                      primaryColor={themeColors["dark-2"]}
                    />
                  </div>
                ),
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
