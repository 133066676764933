import { localStorageHandler } from "@tudigo-monorepo/core-tudigo-utils";

const LOCAL_STORAGE_KEY = "user_videos";
// temporary stuff for Space Cargo project
const SPACE_CARGO_PROJECT_ID = "981325fd-a690-42b0-8614-ef940355840c";
const SPACE_CARGO_YT_VIDEO_URL =
  "https://youtube.com/embed/Knlbt6aUAk4?feature=share";

type UserVideo = {
  itemId: string;
  url: string;
  watched: boolean;
};

const getUserVideos = (): UserVideo[] => {
  return localStorageHandler.get(LOCAL_STORAGE_KEY) ?? [];
};

const getUserVideoByItemId = (itemId: string) => {
  const videos = getUserVideos();

  return videos.find((item) => item.itemId === itemId);
};

const getUserVideoToWatch = () => {
  const videos = getUserVideos();

  return videos.find((item) => !item.watched);
};

const addVideoToUserWatchList = (params: Omit<UserVideo, "watched">) => {
  const videos = getUserVideos();
  const video = getUserVideoByItemId(params.itemId);

  if (video) {
    throw new Error("Video already exists in the list");
  }

  localStorageHandler.set(LOCAL_STORAGE_KEY, [
    ...videos,
    { itemId: params.itemId, url: params.url, watched: false },
  ]);
};

const setUserVideoAsWatched = (itemId: string) => {
  const videos = getUserVideos();
  const video = getUserVideoByItemId(itemId);

  if (!video) {
    throw new Error("Video not found in the list");
  }

  localStorageHandler.set(
    LOCAL_STORAGE_KEY,
    videos.filter((item) => {
      if (item.itemId === itemId) {
        item.watched = true;
      }

      return item;
    }),
  );
};

export {
  getUserVideos,
  getUserVideoByItemId,
  getUserVideoToWatch,
  addVideoToUserWatchList,
  setUserVideoAsWatched,
  SPACE_CARGO_PROJECT_ID,
  SPACE_CARGO_YT_VIDEO_URL,
};
