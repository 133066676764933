import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";

export function SignupPage() {
  const searchParams = new URLSearchParams(window.location.search);
  const authClient = getAuthClient();
  const authUrl = authClient.authorizeUrl();
  searchParams.append("register", "1");

  document.location.href = `${authUrl}&${searchParams.toString()}`;

  return null;
}

export default SignupPage;
