import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  CollectionRequestParams,
  getApiClient,
  InvestmentsFilters,
} from "../../..";
import { getQueryListKey } from "../../query-key-factory";

type GetCircleInvestmentsQuery = {
  fields?: string;
  limit?: number;
  offset?: number;
  sort_direction?: "ASC" | "DESC";
};

const defaultFields =
  "id,legacy_id,global_status,payment_status,subscription_form_status,subscription_form_document,subscription_form_signature_date,terms_consent_date,created_at,updated_at,project,currency,total_amount_in_cents,unit_price_in_cents,quantity,investor_organization,user,is_investor_organization_kyc_valid,payment_method_type,documents,payment_date,commitment_letter_document,project_agreement_and_security_consent,latest_payment_intent,equity_saving_plan,is_cancellable";

export const getCircleInvestments = async ({
  circleId,
  query,
}: {
  circleId: string;
  query: GetCircleInvestmentsQuery;
  filters?: InvestmentsFilters;
}) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getCircleInvestments(
    circleId,
    query,
  );

  return response.data;
};

export const useGetCircleInvestments = (
  params: CollectionRequestParams<{ circleId: string }, InvestmentsFilters>,
  options?: Partial<UseQueryOptions<TudigoResponse<Investment[]>, TudigoError>>,
) => {
  const { pagination, fields = defaultFields, filters, pathParams } = params;

  return useQuery({
    queryKey: getQueryListKey({
      key: `${QUERY_KEYS.CIRCLE_INVESTMENTS}-${pathParams?.circleId}`,
      filters,
    }),
    queryFn: () =>
      getCircleInvestments({
        circleId: pathParams?.circleId ?? "",
        query: {
          ...filters,
          fields,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    ...options,
  });
};
