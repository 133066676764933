import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { INVESTMENTS } from "@tudigo-monorepo/core-tudigo-utils";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { ContextualMenuItem } from "@tudigo-monorepo/web-tudigo-components";

import { useCanCancelInvestment } from "./use-can-cancel-investment";

export enum InvestmentQuickAction {
  ADD_EQUITY_SAVING_PLAN_ACCOUNT = "add_equity_saving_plan_account",
  EDIT_EQUITY_SAVING_PLAN_ACCOUNT = "edit_equity_saving_plan_account",
  CANCEL_INVESTMENT = "cancel_investment",
  CHANGE_PAYMENT_METHOD = "change_payment_method",
  MARK_AS_PAID = "mark_as_paid",
  MARK_AS_UNPAID = "mark_as_unpaid",
  PAY_WITH_WALLET = "pay_with_wallet",
  REGENERATE_SUBSCRIPTION_FORM = "regenerate_subscription_form",
}

export type InvestmentQuickActionItem = Omit<ContextualMenuItem, "action"> & {
  action: InvestmentQuickAction;
};

export const useInvestmentQuickActions = (
  investment: Investment | undefined,
): InvestmentQuickActionItem[] => {
  const { isAdmin } = useUserBusiness();
  const canCancelInvestment = useCanCancelInvestment(investment);
  const { t } = useTranslation();

  const actions: InvestmentQuickActionItem[] = [];

  if (!investment) {
    return [];
  }

  if (isAdmin && investment.paymentStatus !== "payed") {
    actions.push({
      label: t(
        "investments.back_office.investment_detail.contextual_menu.mark_as_paid",
      ),
      iconProps: { name: "DotChip", color: "success" },
      action: InvestmentQuickAction.MARK_AS_PAID,
    });
  }

  if (isAdmin && investment.paymentStatus !== "intent") {
    actions.push({
      label: t(
        "investments.back_office.investment_detail.contextual_menu.mark_as_unpaid",
      ),
      iconProps: { name: "DotChip", color: "warning" },
      action: InvestmentQuickAction.MARK_AS_UNPAID,
    });
  }

  if (isAdmin) {
    actions.push({
      label: t(
        "investments.back_office.investment_detail.contextual_menu.regenerate_subscription_form",
      ),
      iconProps: { name: "PaperIcon", color: "dark-2" },
      action: InvestmentQuickAction.REGENERATE_SUBSCRIPTION_FORM,
    });
  }

  if (
    isAdmin &&
    investment.paymentStatus === "payed" &&
    investment.investorOrganization.type === "individual"
  ) {
    actions.push({
      label: t(
        "investments.back_office.investment_detail.contextual_menu.change_payment_method",
      ),
      iconProps: { name: "Pen", color: "dark-2" },
      action: InvestmentQuickAction.CHANGE_PAYMENT_METHOD,
    });
  }

  // Available for both investors and admins
  if (
    (isAdmin &&
      investment.globalStatus !== INVESTMENTS.GLOBAL_STATUS.CANCELLED) ||
    canCancelInvestment
  ) {
    actions.push({
      label: t(
        "investments.back_office.investment_detail.contextual_menu.cancel_investment",
      ),
      iconProps: { name: "Delete", color: "error" },
      action: InvestmentQuickAction.CANCEL_INVESTMENT,
    });
  }

  return actions;
};
