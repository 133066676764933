export const heighteenYearsDateFromNow = () => {
  const date = new Date();

  return new Date(date.setFullYear(date.getFullYear() - 18));
};

export const isMajorityDate = (date: Date | string | null) => {
  return (
    (date instanceof Date || typeof date === "string") &&
    new Date(date) <= heighteenYearsDateFromNow()
  );
};
