import { Todo } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Chip,
  Icon,
  ListElement,
} from "@tudigo-monorepo/web-tudigo-components";

type ClubMemberSignTodoListElementProps = {
  todo: Todo;
};

export const ClubMemberSignTodoListElement = (
  props: ClubMemberSignTodoListElementProps,
) => {
  const { todo } = props;
  const { t } = useTranslation();

  if (!todo?.subjectClubMember) {
    return null;
  }

  return (
    <ListElement
      navigateTo={`/clubs-v2/subscribe/clubs/${todo.subjectClubMember.club.id}/members/${todo.subjectClubMember.id}`}
      startElement={{
        img: {
          icon: { name: "Document" },
        },
        upperText: t(
          "space_investor.my_circles.my_membership_page.membership_to_sign_todo.left_text",
        ),
        profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
      }}
      middleElement={{
        upperText: t(
          "space_investor.my_circles.my_membership_page.membership_to_sign_todo.middle_text",
        ),
      }}
      endElement={{
        render: () => {
          return (
            <div className="flex items-center gap-x-8">
              <Chip
                label={t(
                  "space_investor.my_circles.my_membership_page.membership_to_sign_todo.right_text",
                )}
                size="S"
                type="warning"
                iconLeft="DotChip"
              />
              <Icon name="ChevronRight" primaryColor={themeColors["dark-2"]} />
            </div>
          );
        },
      }}
    />
  );
};
