import { Outlet, useLocation, useParams } from "react-router-dom";

import { isFailedProject } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useProjectGoalChipProps,
  useProjectStatusChipProps,
} from "@tudigo-monorepo/web-feature-projects";
import {
  useGetProjectById,
  useGetUserInvestments,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  getUserVideoByItemId,
  PageHead,
  Tabs,
  VideoModal,
  VideoModalTrigger,
} from "@tudigo-monorepo/web-tudigo-components";
import { mixpanelTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

import { managerInvestmentQueryFields } from "../investment-manager-page/investment-manager-page";
import { useGetInvestmentPageTabs } from "./hooks/get-investment-page-tabs";
import { MyInvestmentPageContext } from "./hooks/use-my-investment-page-context";

const projectFields =
  "id,mechanic,status,vehicle_organization.fields(id),holding_funds_transfer_date,bonds_mechanic_settings,shares_mechanic_settings";
const investorOrganizationFields =
  "id,type,name,organization_representative.fields(email,user.fields(id))";

export const userInvestmentsQueryFields =
  "id," +
  "global_status," +
  "payment_method_type," +
  "total_amount_in_cents," +
  "payment_method_type," +
  "payment_date," +
  "is_cancellable," +
  "investor_organization.fields(" +
  investorOrganizationFields +
  ")," +
  "user.fields(id)," +
  "project.fields(" +
  projectFields +
  ")";

export function InvestmentPage() {
  const { t } = useTranslation();

  const projectId = useParams().projectId as string;
  const userIdParam = useParams().userId as string;
  const { user: connectedUser, isAdmin } = useUserBusiness();
  const location = useLocation();
  const userId = userIdParam || connectedUser?.id || "me";

  const { data: project } = useGetProjectById({
    projectId,
  });

  const { data: investments } = useGetUserInvestments({
    filters: { projectId },
    fields: isAdmin ? managerInvestmentQueryFields : userInvestmentsQueryFields,
    pathParams: { userId },
  });

  const tabs = useGetInvestmentPageTabs(project);
  const chipStatusProps = useProjectStatusChipProps(project);
  const chipGoalProps = useProjectGoalChipProps(project);

  const videoToWatch = getUserVideoByItemId(projectId);

  if (!project || !userId) {
    return;
  }

  const breadcrumbRedirectionPath = location.pathname.includes("my-investments")
    ? "/my-account/my-investments/list/all"
    : "/investments/list/all";

  return (
    <div id="my-investments-page" className="w-full">
      <PageHead
        title={project?.content?.name ?? ""}
        levels={[
          {
            url: breadcrumbRedirectionPath,
            text: t("investments.my_investments"),
            iconName: "Invest",
          },
        ]}
        chipsTop={
          project?.status === "finished" && chipGoalProps
            ? [chipStatusProps, chipGoalProps]
            : [chipStatusProps]
        }
        withEndLine
      >
        {!!videoToWatch && videoToWatch.itemId === project.id && (
          <VideoModal
            itemId={project.id}
            url={videoToWatch.url}
            title={`L’équipe de ${project.content?.name} a un message pour vous`}
            onClose={(timeSpent) => {
              console.log("ui.investment_thank_you_video.watch", {
                investment_video_thank_you_watch_date: new Date().toISOString(),
                project_id: projectId,
                investment_video_thank_you_watch_title: `L’équipe de ${project.content?.name} a un message pour vous`,
                investment_video_thank_you_watch_duration_seconds: timeSpent,
              });
              mixpanelTrackEvent("ui.investment_thank_you_video.watch", {
                investment_video_thank_you_watch_date: new Date().toISOString(),
                project_id: projectId,
                investment_video_thank_you_watch_title: `L’équipe de ${project.content?.name} a un message pour vous`,
                investment_video_thank_you_watch_duration_seconds: timeSpent,
              });
            }}
          >
            <VideoModalTrigger className="sm:ml-auto">
              Le mot de l’équipe
            </VideoModalTrigger>
          </VideoModal>
        )}
      </PageHead>

      {isFailedProject(project) && (
        <Alert
          variant="warning"
          icon="Invest"
          size="M"
          title={t("investments.my_investments.project_failed.alert.title")}
          description={t(
            "investments.my_investments.project_failed.alert.desc",
          )}
          className="mx-6"
        />
      )}

      <Tabs tabs={tabs} className="px-6" />

      <Outlet
        context={
          {
            project,
            investments: investments?.data ?? [],
            userId: userId,
          } satisfies MyInvestmentPageContext
        }
      />
    </div>
  );
}
