import React, { ReactNode } from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Checkbox } from "../../ui/form/checkbox/checkbox";
import { Chip, ChipSize, ChipType } from "../chip";
import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";
import { IconSizes } from "../icons/icon-sizes.type";
import { Linkable } from "../linkable";
import { ProfileCard } from "../profile-card";
import { Typography } from "../typography";

type ChipProps = {
  label: string;
  size?: ChipSize;
  type?: ChipType;
  iconLeft?: IconName;
  iconRight?: IconName;
};

type StartElementProps = {
  upperText: string;
  active?: boolean;
  img?: {
    avatarText?: string;
    avatarUrl?: string;
    icon?: { name: IconName; color?: string; size?: IconSizes };
  };
  lowerText?: string;
  useProfileCard?: boolean;
  profileCardClassName?: string;
};

type MiddleElementProps = {
  upperText?: string;
  lowerText?: string;
  chips?: ChipProps[];
};

type EndElementProps = {
  active?: boolean;
  icon?: IconName;
  checkbox?: boolean;
  info?:
    | {
        text: string;
        chip?: never;
      }
    | {
        text?: never;
        chip: ChipProps;
      };
  onIconClick?: () => void;
  render?: () => ReactNode;
};

export type ListElementProps = {
  active?: boolean;
  className?: string;
  endElement?: EndElementProps;
  middleElement?: MiddleElementProps;
  navigateTo?: string;
  startElement: StartElementProps;
  onClick?: () => void;
  toggleActiveOnClick?: boolean;
  disabled?: boolean;
};

export function ListElement(props: ListElementProps) {
  const {
    startElement,
    middleElement,
    endElement,
    navigateTo,
    active: activeProp = false,
    onClick,
    toggleActiveOnClick = true,
    disabled = false,
  } = props;

  const [isActive, setIsActive] = React.useState<boolean>(activeProp);

  const handleClick = () => {
    if (disabled) return;
    if (toggleActiveOnClick) {
      setIsActive((prev) => !prev);
    }
    onClick && onClick();
  };

  React.useEffect(() => {
    setIsActive(activeProp);
  }, [activeProp]);

  const listElementClassName = cn(
    "p-4 transition-colors md:px-3 md:py-1.5",
    "border-medium-2 border border-solid",
    "rounded-lg",
    "flex flex-col gap-2",
    "md:flex-row md:items-center md:justify-between",
    {
      "bg-accent-super-light border-accent-medium": isActive && !disabled,
      "cursor-pointer": onClick,
      "hover:border-accent-medium": !disabled,
      "bg-light-3 cursor-default": disabled,
    },
    props.className,
  );

  const renderListStartElement = () => {
    if (
      startElement.useProfileCard !== undefined &&
      !startElement.useProfileCard
    ) {
      return (
        <div className="ml-6 flex flex-col ">
          <Typography
            variant="body3-semibold"
            className={cn({
              "text-accent": isActive,
              "text-dark-1": !isActive,
            })}
          >
            {startElement.upperText}
          </Typography>
          {startElement.lowerText ? (
            <Typography
              variant="body3-regular"
              className={cn({
                "text-accent": isActive,
                "text-dark-2": !isActive,
              })}
            >
              {startElement.lowerText}
            </Typography>
          ) : null}
        </div>
      );
    } else {
      return (
        <ProfileCard
          upperText={startElement.upperText}
          lowerText={startElement.lowerText}
          avatarUrl={startElement.img?.avatarUrl}
          avatarText={startElement.img?.avatarText}
          icon={startElement.img?.icon}
          active={isActive}
          className={startElement?.profileCardClassName}
        />
      );
    }
  };

  return (
    <Linkable
      role="listitem"
      className={listElementClassName}
      navigateTo={navigateTo}
      onClick={handleClick}
    >
      <div className="rounded-lg">{renderListStartElement()}</div>

      {middleElement ? (
        <div className="flex min-h-[60px] flex-1 flex-col justify-center gap-2.5">
          <Typography variant="body3-regular">
            {middleElement.upperText}
          </Typography>
          {middleElement.lowerText ? (
            <Typography variant="body3-regular">
              {middleElement.lowerText}
            </Typography>
          ) : null}
          {middleElement.chips ? (
            <div className="flew-wrap flex items-center gap-2 pb-1.5">
              {middleElement.chips.map((chip, index) => (
                <Chip key={index} {...chip} />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}

      {endElement ? (
        <div className="flex min-h-[60px] items-center justify-end">
          {endElement.render ? (
            endElement.render()
          ) : (
            <>
              {!!endElement.info?.text && (
                <Typography variant="body3-regular" className="text-dark-1">
                  {endElement.info.text}
                </Typography>
              )}

              {!!endElement.info?.chip && <Chip {...endElement.info.chip} />}

              {!!endElement.icon && (
                <div
                  onClick={endElement.onIconClick}
                  className={cn(
                    "ml-3 flex h-[60px] w-[60px] items-center justify-center",
                    {
                      "cursor-pointer": endElement.onIconClick,
                    },
                  )}
                >
                  <Icon
                    name={endElement.icon}
                    primaryColor={isActive ? themeColors.accent : undefined}
                  />
                </div>
              )}

              {!!endElement.checkbox && (
                <div className="flex h-[60px] w-[60px] items-center justify-end pr-4">
                  <Checkbox checked={isActive} />
                </div>
              )}
            </>
          )}
        </div>
      ) : null}
    </Linkable>
  );
}
