import {
  Address,
  CompanyInformation,
  Organization,
  OrganizationPerson,
  OrganizationPersonDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  isEUMemberCountry,
  isMajorityDate,
  ORGANIZATIONS,
  PMS_FILES,
} from "@tudigo-monorepo/core-tudigo-utils";
import { Chip, StepObject } from "@tudigo-monorepo/web-tudigo-components";

import { findDocumentOrDefaultOrganizationDocument } from "../content/kyc-documents/utils/organization-documents-utils";
import { findDocumentOrDefaultOrganizationPersonDocument } from "../content/kyc-documents/utils/organization-person-documents-utils";
import { checkIfOrganizationPersonIdentityDocumentsRequirementAreMet } from "./kyc-documents-utils";

export function useKycNavigationSteps(
  organization?: Organization,
): StepObject[] {
  const { t } = useTranslation();

  return [
    {
      label: t("kyc_funnel.navigation.stepper.label.step_personal_information"),
      navigateTo: `personal-information`,
    },
    ...(organization?.type === ORGANIZATIONS.TYPE.COMPANY
      ? [
          {
            label: t(
              "kyc_funnel.navigation.stepper.label.step_company_information",
            ),
            navigateTo: `legal-information`,
          },
        ]
      : []),
    {
      label: t("kyc_funnel.navigation.stepper.label.bank_account"),
      navigateTo: `bank-account-information`,
    },
    {
      label: t("kyc_funnel.navigation.stepper.label.documents"),
      navigateTo: `documents`,
    },
    {
      label: t("kyc_funnel.navigation.stepper.label.summary"),
      navigateTo: `summary`,
    },
  ];
}

export function checkIfOrganizationRepresentativePersonalRequirementsAreMet(
  organizationRepresentative: OrganizationPerson | null,
): boolean {
  return !!(
    organizationRepresentative?.lastName &&
    organizationRepresentative?.firstName &&
    checkIfOrganizationPersonBirthDateIsValid(organizationRepresentative) &&
    organizationRepresentative?.birthLocation?.city &&
    organizationRepresentative?.birthLocation?.country &&
    organizationRepresentative?.nationality &&
    organizationRepresentative?.phone
  );
}

export function checkIfOrganizationRepresentativeAddressRequirementsAreMet(
  organizationRepresentative: OrganizationPerson | null,
): boolean {
  return !!(
    organizationRepresentative?.address &&
    organizationRepresentative?.address.street1 &&
    organizationRepresentative?.address.city &&
    organizationRepresentative?.address.postalCode &&
    organizationRepresentative?.address.country
  );
}

export function checkIfOrganizationRepresentativeRequirementsAreMet(
  organizationRepresentative: OrganizationPerson | null,
): boolean {
  return (
    checkIfOrganizationRepresentativePersonalRequirementsAreMet(
      organizationRepresentative,
    ) &&
    checkIfOrganizationRepresentativeAddressRequirementsAreMet(
      organizationRepresentative,
    )
  );
}

export function getDefaultOrganizationPersonModel(
  isOrganizationRepresentative = false,
  isUltimateBeneficialOwner = false,
): OrganizationPerson {
  return {
    id: "",
    user: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    position: "",
    address: {
      street1: "",
      street2: "",
      city: "",
      postalCode: "",
      country: "",
    } as Address,
    birthDate: null,
    birthLocation: {
      street1: "",
      street2: "",
      city: "",
      postalCode: "",
      country: "",
    } as Address,
    nationality: "",
    gender: "male",
    isUltimateBeneficialOwner: isUltimateBeneficialOwner,
    isOrganizationRepresentative: isOrganizationRepresentative,
    sharesCount: null,
    documents: [],
  };
}

export function getDefaultCompanyInformationModel(): CompanyInformation {
  return {
    legalStatus: "",
    capital: 0,
    capitalCurrency: "",
    capitalInCents: 0,
    registrationLocation: {
      id: null,
      name: null,
      state: null,
      email: null,
      firstName: null,
      lastName: null,
      isVerified: null,
      phoneNumber: null,
      region: null,
      street1: null,
      street2: null,
      city: "",
      country: "",
      postalCode: null,
      formatted: "",
    },
    headOfficeAddress: {
      id: null,
      name: null,
      state: null,
      email: null,
      firstName: null,
      lastName: null,
      isVerified: null,
      phoneNumber: null,
      region: null,
      street1: null,
      street2: null,
      city: "",
      country: "",
      postalCode: null,
      formatted: "",
    },
    registrationNumbers: {
      siret: "",
      siren: "",
      rcs: "",
    },
  };
}

export function checkIfBeneficialOwnerPersonalInformationsRequirementsAreMet(
  beneficialOwner: OrganizationPerson,
): boolean {
  return !!(
    beneficialOwner.firstName &&
    beneficialOwner.lastName &&
    beneficialOwner.address &&
    beneficialOwner.phone &&
    beneficialOwner.birthDate &&
    beneficialOwner.birthLocation &&
    beneficialOwner.nationality &&
    beneficialOwner.sharesCount !== null &&
    beneficialOwner.sharesCount >= 25
  );
}

export function checkIfUltimateBeneficialOwnersFormDataRequirementsAreMet(
  ultimateBeneficialOwnersFormData: OrganizationPerson[],
): boolean {
  return ultimateBeneficialOwnersFormData.every((ubo) => {
    return checkIfBeneficialOwnerPersonalInformationsRequirementsAreMet(ubo);
  });
}

export function getOrganizationPersonDocumentsData(
  organizationPerson: OrganizationPerson,
  isProofOfAddressRequired = false,
): {
  identityCardFrontSide: OrganizationPersonDocument;
  identityCardBackSide: OrganizationPersonDocument;
  mergedIdentityCard: OrganizationPersonDocument;
  passportFrontSide: OrganizationPersonDocument;
  mergedPassport: OrganizationPersonDocument;
  proofOfAddress?: OrganizationPersonDocument;
} {
  return {
    identityCardFrontSide: findDocumentOrDefaultOrganizationPersonDocument(
      organizationPerson,
      PMS_FILES.TYPE.IDENTITY_CARD_FRONT_SIDE,
    ),
    identityCardBackSide: findDocumentOrDefaultOrganizationPersonDocument(
      organizationPerson,
      PMS_FILES.TYPE.IDENTITY_CARD_BACK_SIDE,
    ),
    mergedIdentityCard: findDocumentOrDefaultOrganizationPersonDocument(
      organizationPerson,
      PMS_FILES.TYPE.IDENTITY_CARD,
    ),
    passportFrontSide: findDocumentOrDefaultOrganizationPersonDocument(
      organizationPerson,
      PMS_FILES.TYPE.EUROPEAN_PASSPORT_FRONT_SIDE,
    ),
    mergedPassport: findDocumentOrDefaultOrganizationPersonDocument(
      organizationPerson,
      PMS_FILES.TYPE.EUROPEAN_PASSPORT,
    ),
    ...(isProofOfAddressRequired
      ? {
          proofOfAddress: findDocumentOrDefaultOrganizationPersonDocument(
            organizationPerson,
            PMS_FILES.TYPE.PROOF_OF_ADDRESS,
          ),
        }
      : {}),
  };
}

export type OrganizationPersonDocumentsData = ReturnType<
  typeof getOrganizationPersonDocumentsData
>;

export function getOrganizationCompanyDocumentsData(
  organization: Organization,
) {
  return {
    companyRegistration: findDocumentOrDefaultOrganizationDocument(
      organization,
      "company_registration",
    ),
    status: findDocumentOrDefaultOrganizationDocument(organization, "status"),
    uboRegister: findDocumentOrDefaultOrganizationDocument(
      organization,
      "ubo_register",
    ),
  };
}

export function checkIfCompanyOrganizationPersonsDocumentsRequirementsAreMet(
  organizationPersons: OrganizationPerson[],
  requiredProofOfAddress = true,
): boolean {
  return organizationPersons.every((person) => {
    return checkIfOrganizationPersonIdentityDocumentsRequirementAreMet(
      person,
      requiredProofOfAddress,
    );
  });
}

export function checkIfCompanyRegistrationLocationRequirementsAreMet(
  companyInformation: CompanyInformation,
) {
  return !!(
    companyInformation?.registrationLocation?.street1 &&
    companyInformation?.registrationLocation?.postalCode &&
    companyInformation?.registrationLocation?.city &&
    companyInformation?.registrationLocation?.country
  );
}

export function checkIfCompanyInformationRequirementsAreMet(
  companyInformation: CompanyInformation,
): boolean {
  return !!(
    companyInformation?.legalStatus &&
    companyInformation?.registrationNumbers.siret &&
    checkIfCompanyRegistrationLocationRequirementsAreMet(companyInformation)
  );
}

export function getChipStatus(isComplete: boolean) {
  if (isComplete) {
    return <Chip label="Complet" type="success" size="S" iconLeft="DotChip" />;
  } else {
    return (
      <Chip label="A compléter" type="warning" size="S" iconLeft="DotChip" />
    );
  }
}

export function isOrganizationPersonProofOfAddressRequired(
  beneficialOwner: OrganizationPerson,
): boolean {
  return (
    !isEUMemberCountry(beneficialOwner.address?.country) ||
    beneficialOwner.address?.country !== beneficialOwner.nationality
  );
}

export function getDefaultOrganizationPersonIdentityDocumentType(
  documents: OrganizationPersonDocumentsData,
): "identity_card" | "european_passport" {
  if (documents.passportFrontSide.document.signedUrl) {
    return "european_passport";
  }

  return "identity_card";
}

export function checkIfOrganizationPersonBirthDateIsValid(
  person: OrganizationPerson | null,
): boolean {
  return !!person?.birthDate && isMajorityDate(person.birthDate);
}
