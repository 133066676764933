import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey, getQueryListKey } from "../../..";

type UnsubscribeClubParam = {
  clubMemberId: string;
  clubId: string;
};

export const unsubscribeClub = async (params: UnsubscribeClubParam) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.unsubscribeClubMembers(params);

  return response.data;
};

export const useUnsubscribeClub = (
  options?: UseMutationOptions<
    TudigoResponse<boolean>,
    TudigoError,
    UnsubscribeClubParam
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => unsubscribeClub(params),
    ...options,
    onSuccess: (...props) => {
      const [response, params] = props;
      queryClient.invalidateQueries({
        queryKey: getQueryListKey({
          key: QUERY_KEYS.MY_CLUB_MEMBERS,
        }),
      });
      const memberQueryKey = getQueryDetailKey({
        key: QUERY_KEYS.CLUB_MEMBER,
        id: `${params.clubId}-${params.clubMemberId}`,
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKey,
        refetchType: "all",
      });
      options?.onSuccess?.(...props);
    },
  });
};
