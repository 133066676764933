import { useParams } from "react-router-dom";

import { useTimeSpent } from "@tudigo-monorepo/core-tudigo-utils";
import { useGetProjectById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  mixpanelTrackEvent,
  mixpanelTrackPageView,
  useTrack,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { projectPageFields } from "../utils/project-page-fields";

export function useProjectPageTracking() {
  const { slug: projectSlug } = useParams();

  const { data: projectFromApi } = useGetProjectById({
    projectId: projectSlug,
    query: {
      fields: projectPageFields,
    },
  });

  useTrack(() => {
    if (!projectFromApi) return false;

    mixpanelTrackEvent("project-page-viewed", {
      projectId: projectFromApi.id,
    });

    mixpanelTrackPageView("project-page", {
      projectId: projectFromApi.id,
    });

    mixpanelTrackEvent("ui.project_page.view", {
      project_id: projectFromApi.id,
      project_name: projectFromApi.content?.name,
      project_slug: projectFromApi.content?.slug,
      project_instrument: projectFromApi.mechanic,
      project_tags: projectFromApi.tags?.map((tag) => tag.code).join(","),
      project_categories: projectFromApi.categories
        ?.map((category) => category.code)
        .join(","),
      project_maturity_stage: projectFromApi.maturity?.code,
      project_club_id: projectFromApi.club?.id,
      project_current_funding_in_percent:
        projectFromApi.fundraisingProgressMinGoal,
    });

    return true;
  }, [projectFromApi]);

  useTimeSpent({
    onEnded: (timeSpent) => {
      mixpanelTrackEvent("ui.project_page_view_duration", {
        project_id: projectFromApi?.id,
        project_detail_view_duration_sec: timeSpent.toFixed(2),
      });
    },
  });
}
