import { useCallback, type ReactNode } from "react";

import {
  Avatar,
  ListElementComposable,
} from "@tudigo-monorepo/web-tudigo-components";

import { useProjectNewsContext } from "../../state/project-news.context";
import { ProjectNewsItemMenu } from "./project-news-item-menu";

export function ProjectNewsListElement(props: {
  children?: ReactNode;
  onClick?: () => void;
}) {
  const { news, editMode } = useProjectNewsContext();

  const createdAt = (
    news?.createdAt ? new Date(news.createdAt) : new Date()
  ).toLocaleDateString();

  const getDescription = useCallback(() => {
    switch (news?.type) {
      case "information":
        return news.description;
      case "document":
        return news.document?.originalFilename;
      case "video":
        return news.video?.title;
      default:
        return "-";
    }
  }, [news]);

  return (
    <ListElementComposable
      onClick={props.onClick}
      className="flex w-full flex-col items-center justify-between md:flex-row"
    >
      <div className="flex w-full min-w-40 items-center gap-3 md:w-auto md:basis-1/4">
        <Avatar icon={{ name: "News" }} />
        <h2 className="text-dark-1 text-sm font-semibold">{createdAt}</h2>
      </div>

      <p className="max-h-[300px] w-full flex-1 truncate text-sm md:w-auto">
        {getDescription()}
      </p>

      <div className="flex w-full items-center justify-end gap-2 md:w-auto md:basis-1/4">
        <p className="text-dark-1 font-sans text-[13px] capitalize">
          {news?.type}
        </p>

        {props.children ?? <div className="w-14" />}

        {editMode && <ProjectNewsItemMenu />}
      </div>
    </ListElementComposable>
  );
}
