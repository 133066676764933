import { useQuery } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Todo } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../..";
import { getQueryListKey } from "../../query-key-factory";

export type GetUserTodosFilters = {
  status?: string | string[];
  type?: string | string[];
};

export const getUserTodos = async (
  params: CollectionRequestParams<{ userId: string }, GetUserTodosFilters>,
) => {
  const { pagination, filters, fields } = params;
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.userTodosEndpoints.getUserTodos({
    ...filters,
    fields,
    offset: pagination?.offset,
    limit: pagination?.limit,
  });

  return response.data;
};

export const useGetUserTodosQuery = (
  params: CollectionRequestParams<{ userId: string }, GetUserTodosFilters>,
) => {
  const queryKey = getQueryListKey({
    key: QUERY_KEYS.USER_TODOS,
  });

  return useQuery<TudigoResponse<Todo[]>, TudigoError>({
    queryKey,
    queryFn: () => getUserTodos(params),
  });
};
