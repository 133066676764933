import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectDocuments } from "../../../shared-internal/hooks/use-project-documents";
import { ProjectOptionalDocument } from "../../components/project-optional-document";
import { ProjectRequiredDocument } from "../../components/project-required-document";
import { useProjectContext } from "../../hooks/use-project-context";

export function SidebarProjectDocuments() {
  const project = useProjectContext();
  const { requiredDocuments, optionalDocuments, projectDocuments } =
    useProjectDocuments(project);

  return (
    <div className="flex flex-col gap-4">
      <header className="flex gap-3">
        <Icon
          name="Document"
          className="h-[28px] w-[28px]"
          primaryColor={themeColors.primary}
          bgColor={themeColors["light-3"]}
        />
        <h2 className="text-primary font-brand">Documents</h2>
      </header>

      {requiredDocuments.length > 0 &&
        requiredDocuments.map((doc) => (
          <ProjectRequiredDocument key={doc.id} project={project} doc={doc} />
        ))}

      {optionalDocuments.length > 0 &&
        optionalDocuments.map((doc) => (
          <ProjectOptionalDocument key={doc.id} project={project} doc={doc} />
        ))}

      {projectDocuments.length === 0 && (
        <p className="text-dark-1 text-sm">Aucun document disponible.</p>
      )}
    </div>
  );
}
