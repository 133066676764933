import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../../index";
import { getQueryDetailKey } from "../../query-key-factory";

type GetWMACustomerByIdParams = CollectionRequestParams<
  {
    wmaCustomerId: string;
  },
  {
    [key: string]: unknown;
  }
>;

export const getWMACustomerById = async (params: GetWMACustomerByIdParams) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.wmaCustomers.getWMACustomerById(
    params.pathParams?.wmaCustomerId ?? "",
    {
      ...params.filters,
      fields: params.fields,
    },
  );

  return response.data;
};

export const useGetWMACustomerByIdQuery = (
  params: GetWMACustomerByIdParams,
  options?: UseQueryOptions<TudigoResponse<WMACustomer>, TudigoError>,
) =>
  useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.WMA_CUSTOMERS,
      id: params.pathParams?.wmaCustomerId ?? "",
    }),
    refetchOnMount: "always",
    queryFn: () => getWMACustomerById(params),
    ...options,
  });
