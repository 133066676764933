import { useState } from "react";
import { toast } from "react-toastify";

import { isSharesProject } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { openBlobFile } from "@tudigo-monorepo/core-tudigo-utils";
import { useGenerateInvestmentAccountCertificateMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert, Button, Tooltip } from "@tudigo-monorepo/web-tudigo-components";

import { useCheckCertificateGenerationAvailability } from "../hooks/use-check-certificate-generation-availability";
import { useMyInvestmentPageContext } from "../hooks/use-my-investment-page-context";
import { GenerateInvestmentAccountCertificateModal } from "./generate-investment-account-certificate-modal";

export const GenerateInvestmentAccountCertificateButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const { investments, project } = useMyInvestmentPageContext();
  const { canGenerate, reasons } = useCheckCertificateGenerationAvailability();
  const { t } = useTranslation();

  const { mutate: generateCertificate, isPending } =
    useGenerateInvestmentAccountCertificateMutation({
      onSuccess: (res) => {
        if (modalOpen) {
          setModalOpen(false);
        }

        openBlobFile(res, {
          type: "application/pdf",
          target: "_blank",
        });

        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Checked"
            variant="success"
            title={t(
              "my-investments.my_investment_page.documents.generate_investment_account_certificate.alert.success",
            )}
          />
        ));
      },
      onError: (err) => {
        console.error("generateCertificate error", err);

        toast((content) => (
          <Alert
            close={content.closeToast}
            icon="Error"
            variant="error"
            title={t(
              "my-investments.my_investment_page.documents.generate_investment_account_certificate.alert.error",
            )}
          />
        ));
      },
    });

  const handleGenerateCertificate = (selectedInvestorId?: string) => {
    const projectHoldingId = project.vehicleOrganization?.id;

    if (!projectHoldingId) {
      return;
    }

    const investorOrganizationId =
      investments.find(
        ({ investorOrganization }) =>
          investorOrganization.id === selectedInvestorId,
      )?.investorOrganization.id ?? investments[0].investorOrganization.id;

    if (isSharesProject(project)) {
      generateCertificate({
        type: "share",
        investorId: investorOrganizationId,
        holdingId: projectHoldingId,
      });

      return;
    }

    generateCertificate({
      type: project.bondsMechanicSettings.isConvertible
        ? "convertible_bond"
        : "bond",
      investorId: investorOrganizationId,
      holdingId: projectHoldingId,
    });
  };

  const handleClick = () => {
    const areCompanyInvestorOrganizations = investments.some(
      ({ investorOrganization }) => investorOrganization.type === "company",
    );

    if (areCompanyInvestorOrganizations) {
      setModalOpen(true);

      return;
    }

    handleGenerateCertificate();
  };

  return (
    <>
      <Tooltip
        description={reasons?.join(" - ")}
        placement="top"
        childrenClassName="flex"
      >
        <Button
          disabled={!canGenerate}
          onClick={handleClick}
          label={t(
            "my-investments.my_investment_page.documents.generate_investment_account_certificate.label",
          )}
          isLoading={!modalOpen && isPending}
          iconRight="Download"
        />
      </Tooltip>

      {modalOpen && (
        <GenerateInvestmentAccountCertificateModal
          isLoading={isPending}
          open={modalOpen}
          setOpen={setModalOpen}
          onSubmit={(selectedInvestorId) => {
            handleGenerateCertificate(selectedInvestorId);
          }}
        />
      )}
    </>
  );
};
