import { BlockCarousel } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { getClubComponentByCMSBlock } from "../../../shared-internal/utils/get-club-component-by-cms-block";

export const ClubPresentationContent = () => {
  const club = useGetClubV2UsingIdPathParams();

  if (!club || !club?.content?.presentation) {
    return;
  }

  return (
    <div className="flex flex-col gap-4">
      <h5 className="font-semibold">{club.content?.tagline}</h5>
      <BlockCarousel medias={club.content.presentationMedias} />
      {club.content.presentation
        .map((block) => getClubComponentByCMSBlock(block, club))
        .map((Block, index) => (
          <Block key={index} />
        ))}
    </div>
  );
};
