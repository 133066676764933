import { useEffect, useState } from "react";

import { ClubV2, TrackRecord } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { ClubCardBlock } from "../../../shared-internal/components/club-card-block";
import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { getClubComponentByCMSBlock } from "../../../shared-internal/utils/get-club-component-by-cms-block";

const DEFAULT_DISPLAY_COUNT_DESKTOP = 9;
const DEFAULT_DISPLAY_COUNT_MOBILE = 6;

export const ClubTrackRecordContent = () => {
  const club = useGetClubV2UsingIdPathParams();

  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);
  const resetShowMore = () => {
    setShowMore(false);
  };

  useEffect(() => {
    window.addEventListener("resize", resetShowMore);

    return () => {
      window.removeEventListener("resize", resetShowMore);
    };
  }, []);

  if (!club || !club?.trackRecords) {
    return;
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <h2 className="font-obviously text-dark-1 text-lg font-[570]">
          {t("page_club.tab_track_record.track_record.title")}
        </h2>
        <TrackRecordGrid trackRecords={club.trackRecords} showMore={showMore} />
        <TrackRecordShowMore
          trackRecords={club.trackRecords}
          showMore={showMore}
          onShowMore={setShowMore}
        />
      </div>
      <div className="flex flex-col gap-6">
        <ExitCmsSection club={club} />
        <LegalDisclaimer />
      </div>
    </div>
  );
};

const TrackRecordGrid = ({
  trackRecords,
  showMore,
}: {
  trackRecords: TrackRecord[];
  showMore: boolean;
}) => {
  if (!trackRecords.length) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {trackRecords.map((trackRecord, index) => (
        <ClubCardBlock
          key={trackRecord.id}
          trackRecord={trackRecord}
          className={cn({
            "hidden sm:grid":
              index >= DEFAULT_DISPLAY_COUNT_MOBILE && !showMore,
            "sm:hidden": index >= DEFAULT_DISPLAY_COUNT_DESKTOP && !showMore,
          })}
        />
      ))}
    </div>
  );
};

const TrackRecordShowMore = ({
  trackRecords,
  showMore,
  onShowMore,
}: {
  trackRecords: TrackRecord[];
  showMore: boolean;
  onShowMore: (showMore: boolean) => void;
}) => {
  const { t } = useTranslation();

  if (showMore) {
    return null;
  }

  return (
    <Button
      label={t("page_club.tab_track_record.track_record.see_all_button.label")}
      variant="tertiary"
      onClick={() => onShowMore(true)}
      className={cn("mx-auto hidden w-fit md:hidden", {
        flex: trackRecords.length > DEFAULT_DISPLAY_COUNT_MOBILE,
        "md:flex": trackRecords.length > DEFAULT_DISPLAY_COUNT_DESKTOP,
      })}
    />
  );
};

const LegalDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <div className="border-light-1 text-dark-1 flex flex-col gap-6 border-t pt-6">
      <div>{t("page_club.tab_track_record.legal_disclaimer.row_one")}</div>
      <div>
        {t("page_club.tab_track_record.legal_disclaimer.row_two")}
        <br />
        {t("page_club.tab_track_record.legal_disclaimer.row_three")}
      </div>
    </div>
  );
};

const ExitCmsSection = ({ club }: { club: ClubV2 }) => {
  return (
    <>
      <h2 className="font-obviously text-dark-1 text-lg font-[570]">Exit</h2>
      <div className="flex flex-col gap-4">
        {(club.content?.exitDescription ?? [])
          .map((block) => getClubComponentByCMSBlock(block, club))
          .map((Block, index) => (
            <Block key={index} />
          ))}
      </div>
    </>
  );
};
