import React, { useCallback, useEffect, useRef } from "react";

import {
  ActionTypes,
  Answers,
  Form,
  FormInput,
  FormPage,
  FormSection,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { DynamicFormActionBinding } from "../dynamic-form";
import { getInitialFormAnswers } from "../utils/form-answers";
import { getInputPlugins } from "../utils/form-inputs";
import { normalizePages } from "../utils/form-page";
import { DynamicFormContext } from "./dynamic-form-context";

type DynamicFormProviderProps = React.PropsWithChildren<{
  form: Form;
  summaryPages?: FormPage[];
  actionsBinding?: DynamicFormActionBinding;
  answers?: Answers;
  startPage?: number | "resume";
  // mixpanelTracStepProperties
  trackingStepFn?: (p: { [key: string]: string }) => void;
  trackingStepConfirmedFn?: (p: { [key: string]: string }) => void;
}>;

export enum ActionStateEnum {
  ENABLED = "enabled",
  DISABLED = "disabled",
  LOADING = "loading",
  SUCCESS = "success",
}

export function DynamicFormProvider(props: DynamicFormProviderProps) {
  const {
    startPage,
    children,
    form,
    summaryPages,
    actionsBinding,
    answers: propsAnswers,
    trackingStepFn,
    trackingStepConfirmedFn,
  } = props;

  const inputPlugins = getInputPlugins();
  const pages = normalizePages(form, summaryPages);
  const sections = form.sections as FormSection[];

  const convertActionsBindingToActionsState = (
    actionsBinding: DynamicFormActionBinding,
  ) => {
    return Object.keys(actionsBinding).reduce((acc, actionName) => {
      return {
        ...acc,
        [actionName]: ActionStateEnum.ENABLED,
      };
    }, {});
  };

  const [actionsState, setActionsState] = React.useState<
    Record<string, string>
  >(convertActionsBindingToActionsState(actionsBinding ?? {}));

  const setActionState = useCallback(
    (actionName: string, state: string) => {
      setActionsState((prevState) => ({
        ...prevState,
        [actionName]: state,
      }));
    },
    [setActionsState],
  );

  const initialAnswers = {
    ...getInitialFormAnswers(sections),
    ...propsAnswers,
  };

  const [answers, setAnswers] = React.useState<Answers>(initialAnswers);

  let initialPage =
    startPage === "resume" && propsAnswers
      ? pages.findIndex((page) => {
          const sectionKey = page.sectionKey;
          if (sectionKey === undefined || sectionKey === null) return false;

          return page.inputs?.some(
            (input) => propsAnswers[sectionKey][input.name!] === null,
          );
        }) + 1
      : 1;

  // all inputs are filled
  if (initialPage === 0) {
    const nbSummaryPages = summaryPages?.length ?? 0;
    if (nbSummaryPages > 0) {
      initialPage = pages.length - nbSummaryPages + 1;
    } else {
      initialPage = 1;
    }
  }

  const [currentPageIndex, setCurrentPageIndex] =
    React.useState<number>(initialPage);

  const currentPage = pages[currentPageIndex - 1];

  const goToPage = React.useCallback(
    (page: number) => {
      const summaryPagesCount = summaryPages ? summaryPages.length : 0;
      if (page < 1 || page > pages.length + summaryPagesCount) return;
      setCurrentPageIndex(page);
    },
    [pages.length, summaryPages],
  );

  const submit = useCallback(
    (answers: Answers) => {
      // Set default values for inputs that are not filled
      currentPage.inputs?.forEach((input) => {
        if (
          currentPage.sectionKey &&
          input.name &&
          answers[currentPage.sectionKey][input.name] === null
        ) {
          answers[currentPage.sectionKey][input.name] = inputPlugins
            .find((plugin) => plugin.type === input.type)
            ?.getDefaultValue(input as any);
        }
        setAnswers(answers);
      });

      const submitActionBinding = actionsBinding?.[ActionTypes.SUBMIT];
      submitActionBinding?.onClick(answers);

      trackingStepConfirmedFn?.({
        step: String(currentPageIndex),
        step_name: String(currentPage?.title),
      });
      goToPage(currentPageIndex + 1);
    },
    [
      actionsBinding,
      currentPage.inputs,
      currentPage.sectionKey,
      currentPage?.title,
      currentPageIndex,
      goToPage,
      inputPlugins,
      trackingStepConfirmedFn,
    ],
  );

  const getCurrentSection = React.useCallback(() => {
    const foundPage = pages.find((page) => page.index === currentPageIndex);

    return sections.find((section) => section.key === foundPage?.sectionKey);
  }, [currentPageIndex, pages, sections]);

  const setAnswer = React.useCallback(
    (
      sectionKey: string,
      input: FormInput,
      value: string | number | Array<number | string> | boolean | null,
    ): Answers => {
      if (sectionKey && input.name) {
        const newAnswers = {
          ...answers,
          [sectionKey]: {
            ...answers[sectionKey],
            [input.name]: value,
          },
        };
        setAnswers(newAnswers);

        return newAnswers;
      }

      return answers;
    },
    [answers],
  );

  const memoCurrentPageIndex = React.useMemo(
    () => currentPageIndex,
    [currentPageIndex],
  );

  // Tracking step but only when the page changes (this is why we use a ref)
  const prevPageIndexRef = useRef<number | null>(null);
  useEffect(() => {
    if (
      memoCurrentPageIndex &&
      memoCurrentPageIndex !== prevPageIndexRef.current
    ) {
      trackingStepFn?.({
        step: String(memoCurrentPageIndex),
        step_name: String(currentPage?.title),
      });
      prevPageIndexRef.current = memoCurrentPageIndex;
    }
  }, [currentPage?.title, memoCurrentPageIndex, trackingStepFn]);

  const resetForm = React.useCallback(() => {
    setAnswers(getInitialFormAnswers(sections));
    goToPage(1);
  }, [sections, goToPage]);

  return (
    <DynamicFormContext.Provider
      value={{
        form,
        pages,
        sections,
        currentPageIndex,
        summaryPages,
        goToPage,
        getCurrentSection,
        answers,
        setAnswer,
        resetForm,
        actionsBinding,
        inputPlugins,
        currentPage,
        submit,
        sectionKey: pages[currentPageIndex - 1]?.sectionKey,
        actionsState,
        setActionState,
      }}
    >
      {children}
    </DynamicFormContext.Provider>
  );
}
