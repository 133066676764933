import { Skeleton } from "../../ui/skeleton";

const ListElementSkeleton = () => {
  return (
    <li className="border-medium-2 flex h-auto w-full animate-pulse flex-col gap-2 rounded-lg border p-4 transition-colors md:min-h-[74px] md:flex-row md:items-center md:justify-between md:gap-8 md:px-3 md:py-1.5">
      <div className="flex h-2/3 min-w-[252px] items-center gap-3 px-3 py-2">
        <Skeleton className="h-8 min-h-[32px] w-8 min-w-[32px] rounded-full" />
        <div className="flex w-4/5 flex-col gap-2">
          <Skeleton className="h-3 min-h-3 w-2/3" />
          <Skeleton className="h-4 min-h-4 flex-1" />
        </div>
      </div>
      <Skeleton className="h-2/3 min-h-10 flex-1" />
      <div className="flex h-2/3 min-h-[60px] min-w-[172px] items-center justify-end">
        <Skeleton className="h-full min-h-10 w-1/2" />
      </div>
    </li>
  );
};

export { ListElementSkeleton };
