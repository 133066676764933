import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Chip,
  ClubProfilePicture,
} from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../hooks/use-project-context";
import { SidebarProjectFinancialStats } from "./parts/financial-stats/sidebar-project-financial-stats";
import { SidebarProjectDocuments } from "./parts/sidebar-project-documents";
import { SidebarProjectSecurityAndRequirements } from "./parts/sidebar-project-security-and-requirements";
import { SidebarProjectSimulator } from "./parts/sidebar-project-simulator";

const ProjectSidebarCard = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <section
    className={cn(
      "border-light-1 flex w-full flex-col gap-3 rounded-lg border p-6",
      className,
    )}
  >
    {children}
  </section>
);

export function ProjectSidebar() {
  const currentRoute = useLocation();
  const project = useProjectContext();
  const { t } = useTranslation();

  const isSimulatorRoute = currentRoute.pathname.includes("simulator");
  const isSimulatorVisible =
    project?.marketingSettings?.simulatorVisibility === "visible";

  return (
    <div className="flex w-full flex-col gap-4">
      <ProjectSidebarCard>
        <SidebarProjectFinancialStats />
      </ProjectSidebarCard>

      {!isSimulatorRoute &&
        isSimulatorVisible &&
        project?.status !== "finished" &&
        project?.status !== "prefinished" && (
          <ProjectSidebarCard className="hidden lg:block">
            <SidebarProjectSimulator />
          </ProjectSidebarCard>
        )}

      {project?.club && (
        <ProjectSidebarCard>
          <header className="flex justify-center sm:justify-between">
            <div className="flex flex-col items-center gap-4 sm:flex-row">
              <ClubProfilePicture club={project.club} size="S" />
              <div className="flex flex-col sm:max-w-[70%] sm:items-start">
                <h2 className="font-obviously text-lg font-semibold">
                  {project.club.content?.name}
                </h2>
                <p className="text-dark-2 text-xs sm:text-left">
                  {project.club.content?.baseline}
                </p>
              </div>
            </div>
            <div className="hidden items-center gap-2 sm:flex sm:self-start">
              <span className="text-dark-1 text-xs">
                {t("circles_listing_page.circle_card.projects.label")}
              </span>
              <Chip
                label={project.club.statistics?.liveProjectsCount.toString()}
                type="secondary"
              />
            </div>
          </header>
        </ProjectSidebarCard>
      )}

      <ProjectSidebarCard>
        <SidebarProjectDocuments />
      </ProjectSidebarCard>

      <ProjectSidebarCard>
        <SidebarProjectSecurityAndRequirements />
      </ProjectSidebarCard>
    </div>
  );
}
