import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useGetCurrentInvestorProfileByUserId,
  useGetFormById,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { DynamicForm } from "@tudigo-monorepo/web-tudigo-dynamic-forms";
import {
  gtmTrackEvent,
  mixpanelTrackEvent,
  UI_INVESTOR_PROFILE_STARTED,
  UI_INVESTOR_PROFILE_STEP_COMPLETED_VIEW,
  UI_INVESTOR_PROFILE_STEP_PAGE_VIEW,
  useTrack,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useQualificationActionsBinding } from "./hooks/use-qualification-actions-binding";
import { useQualificationSummaryPages } from "./hooks/use-qualification-summary-pages";
import { DeclineConfirmationModal } from "./modals/decline-modal";
import { EndQualificationModal } from "./modals/end-qualification-modal";
import { ExitConfirmationModal } from "./modals/exit-confirmation-modal";

type QualificationPageProps = {
  startPage?: number | "resume";
};

export function QualificationPage(props: QualificationPageProps) {
  const { startPage = "resume" } = props;
  const { userIdOrMe, user } = useUserBusiness();

  const { data: investorProfile } = useGetCurrentInvestorProfileByUserId({
    userId: userIdOrMe,
  });

  useTrack(() => {
    if (!user?.state?.hasDoneIntro) {
      gtmTrackEvent("ui.sign_up_completed.validated", {
        email: user?.email,
      });
    }

    return true;
  }, []);

  const navigate = useNavigate();

  const { data: form } = useGetFormById({
    formId: "investor-qualification-fr",
  });

  const [showExitDialog, setShowExitDialog] = useState(false);
  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const [showEndDialog, setShowEndDialog] = useState(false);

  const actionsBinding = useQualificationActionsBinding({
    setShowExitDialog,
    setShowDeclineDialog,
    setShowEndDialog,
    investorProfile,
  });

  const summaryPages = useQualificationSummaryPages(
    setShowEndDialog,
    investorProfile,
  );

  useEffect(() => {
    mixpanelTrackEvent(UI_INVESTOR_PROFILE_STARTED, {
      investor_profil_version: String(
        user?.investorProfile?.qualificationVersion,
      ),
    });
  }, [user?.investorProfile?.qualificationVersion]);

  return (
    <div id="qualification-page" className="w-full">
      {form && investorProfile?.qualificationData && (
        <DynamicForm
          startPage={startPage}
          actionsBinding={actionsBinding}
          answers={investorProfile?.qualificationData}
          form={form}
          summaryPages={summaryPages}
          trackingStepFn={(props) =>
            mixpanelTrackEvent(UI_INVESTOR_PROFILE_STEP_PAGE_VIEW, props)
          }
          trackingStepConfirmedFn={(props) =>
            mixpanelTrackEvent(UI_INVESTOR_PROFILE_STEP_COMPLETED_VIEW, props)
          }
        />
      )}

      {showExitDialog && (
        <ExitConfirmationModal
          showExitDialog={showExitDialog}
          setShowExitDialog={setShowExitDialog}
          onExit={() => {
            navigate("/dispatch", { replace: true });
          }}
        />
      )}

      {showDeclineDialog && (
        <DeclineConfirmationModal
          showDeclineDialog={showDeclineDialog}
          setShowDeclineDialog={setShowDeclineDialog}
        />
      )}

      {showEndDialog && (
        <EndQualificationModal
          showEndDialog={showEndDialog}
          setShowEndDialog={setShowEndDialog}
        />
      )}

      <Outlet />
    </div>
  );
}
