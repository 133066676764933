import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";

import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUnsubscribeClub } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  ButtonRebrand,
  Icon,
  ListElement,
  Modal,
} from "@tudigo-monorepo/web-tudigo-components";

import { ClubLeaderMemberDetailStatisticsCard } from "../../club-leader-member-detail-page/components/club-leader-member-detail-statistics-card";
import { ClubLeaderMemberDetailSubscriptionPeriodsTable } from "../../club-leader-member-detail-page/components/club-leader-member-detail-subscription-periods-table";

type MyMembershipPremiumContentProps = {
  clubMember: ClubMember;
};

export const MyMembershipPremiumContent = (
  props: MyMembershipPremiumContentProps,
) => {
  const { clubMember } = props;
  const { t } = useTranslation();
  const [isOpenUnsubscribeModal, setIsOpenUnsubcribeModal] = useState(false);

  const { mutate: unsubscribeClub } = useUnsubscribeClub({
    onSuccess: () => {
      toast((content) => (
        <Alert
          icon="Info"
          close={content.closeToast}
          variant="success"
          size="S"
          title="Votre souscription a bien été annulée"
        />
      ));
      setIsOpenUnsubcribeModal(false);
    },
  });

  const handleShowSignedContractDocument = useMemo(() => {
    return () => {
      window.open(clubMember.contract.signedDocument?.signedUrl ?? "");
    };
  }, [clubMember.contract.signedDocument]);

  return (
    <>
      <p className="text-dark-1 px-6 pt-6 text-lg font-semibold">
        {t(
          "space_investor.my_circles.my_membership_page.premium_content.information",
        )}
      </p>
      <ClubLeaderMemberDetailStatisticsCard clubMember={clubMember} />

      <p className="text-dark-1 p-6 text-lg font-semibold">
        {t(
          "space_investor.my_circles.my_membership_page.premium_content.history",
        )}
      </p>

      {clubMember.contract.signedDocument && (
        <div className="mx-6 mb-6 flex-row">
          <ListElement
            startElement={{
              img: {
                icon: { name: "Download" },
              },
              upperText: "Bulletin d'adhésion",
            }}
            onClick={() => handleShowSignedContractDocument()}
            toggleActiveOnClick={false}
            endElement={{
              info: {
                text: "PDF",
              },
              icon: "Show",
            }}
          />
        </div>
      )}

      <ClubLeaderMemberDetailSubscriptionPeriodsTable clubMember={clubMember} />
      {clubMember.status === "active" && (
        <div className="border-light-1 m-6 rounded-lg border p-6">
          <p className="text-error text-sm">
            {t(
              "space_investor.my_circles.my_membership_page.premium_content.cancel_title",
            )}
          </p>
          <ButtonRebrand
            onClick={() => setIsOpenUnsubcribeModal(true)}
            className={cn(
              "text-error border-error mt-2 rounded-full text-sm font-light",
            )}
            variant="outline"
          >
            {t(
              "space_investor.my_circles.my_membership_page.premium_content.cancel_label",
            )}
            <Icon name="Delete" primaryColor="red" />
          </ButtonRebrand>
          <Modal
            isOpen={isOpenUnsubscribeModal}
            setIsOpen={setIsOpenUnsubcribeModal}
            title={t("my_account.wep_app.clubs.table.actions.modal.title")}
            confirmAction={() =>
              unsubscribeClub({
                clubMemberId: clubMember.id,
                clubId: clubMember.club.id!,
              })
            }
            confirmLabel={t(
              "my_account.wep_app.clubs.table.actions.modal.confirm_label",
            )}
            content={t("my_account.wep_app.clubs.table.actions.modal.content")}
          />
        </div>
      )}
    </>
  );
};
