import { useCallback } from "react";

import { TaxExemption } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useCreateUserTaxExemptionMutation } from "@tudigo-monorepo/web-tudigo-api-client";

import {
  SlidingPanelData,
  TaxExemptionCSGFormFields,
  TaxExemptionModalName,
} from "../../content/investor-profile-tax-exemptions-content";
import { useInvestorTaxExemptions } from "../hooks/use-investor-tax-exemptions";
import { getNextYearCalendarDateRange } from "../utils/dates";
import {
  checkIfDateIsValidForSigningTaxExemptionIR,
  checkIfLastPendingTaxExemptionIRSingable,
  checkIfLastSignedTaxExemptionIROutdated,
  hasCSGTaxExemption,
  hasIRTaxExemptions,
  isTaxExemptionCSGFormValid,
} from "../utils/tax-exemptions-utils";
import { TaxExemptionBlockCSG } from "./tax-exemption-block-csg";
import { TaxExemptionBlockIR } from "./tax-exemption-block-ir";

const canSignNewIr = (
  lastSignedTaxExemptionIR: TaxExemption | null,
  pendingTaxExemptionIR: TaxExemption | null,
) =>
  checkIfDateIsValidForSigningTaxExemptionIR() &&
  checkIfLastSignedTaxExemptionIROutdated(lastSignedTaxExemptionIR) &&
  !checkIfLastPendingTaxExemptionIRSingable(pendingTaxExemptionIR);

interface NewTaxExemptionListProps {
  CSGFormData: TaxExemptionCSGFormFields;
  slidingPanelData: SlidingPanelData;
  setCurrentModalOpen: (modal: TaxExemptionModalName) => void;
  setSlidingPanelData: (data: SlidingPanelData) => void;
}

export function NewTaxExemptionList(props: NewTaxExemptionListProps) {
  const {
    CSGFormData,
    slidingPanelData,
    setCurrentModalOpen,
    setSlidingPanelData,
  } = props;

  const { user, isIndividualOrganizationKycApproved } = useUserBusiness();
  const { taxExemptions, pendingTaxExemptionIR, lastSignedTaxExemptionIR } =
    useInvestorTaxExemptions();

  const {
    mutate: createUserTaxExemption,
    isPending: isCreateUserTaxExemptionLoading,
  } = useCreateUserTaxExemptionMutation({
    onSuccess: (res) => {
      if (res.data.signatureRequest) {
        setSlidingPanelData({
          isOpen: true,
          loadingTaxExemptionName: null,
          taxExemption: res.data,
        });
      }
    },
  });

  const { startDate: validityStartDate, endDate: validityEndDate } =
    getNextYearCalendarDateRange();

  const handleCreateTaxExemptionIR = useCallback(() => {
    setSlidingPanelData({
      ...slidingPanelData,
      loadingTaxExemptionName: "IR",
    });

    createUserTaxExemption({
      userId: user?.id ?? "me",
      data: {
        taxName: "IR",
        validityStartDate: validityStartDate,
        validityEndDate: validityEndDate,
      },
    });
  }, [
    createUserTaxExemption,
    setSlidingPanelData,
    user?.id,
    validityStartDate,
    validityEndDate,
    slidingPanelData,
  ]);

  const handleCreateTaxExemptionCSG = useCallback(() => {
    if (!isTaxExemptionCSGFormValid(CSGFormData)) {
      return setCurrentModalOpen("CSG-form");
    }

    setSlidingPanelData({
      ...slidingPanelData,
      loadingTaxExemptionName: "CSG",
    });

    createUserTaxExemption({
      userId: user?.id ?? "me",
      data: {
        taxName: "CSG",
        validityStartDate: validityStartDate,
        validityEndDate: "2099-12-31",
        ...CSGFormData,
      },
    });
  }, [
    CSGFormData,
    user?.id,
    validityStartDate,
    validityEndDate,
    slidingPanelData,
    createUserTaxExemption,
    setCurrentModalOpen,
    setSlidingPanelData,
  ]);

  return (
    <>
      {(!hasIRTaxExemptions(taxExemptions) ||
        canSignNewIr(lastSignedTaxExemptionIR, pendingTaxExemptionIR)) && (
        <TaxExemptionBlockIR
          validityStartDate={validityStartDate}
          onLearnMoreClick={() => setCurrentModalOpen("IR")}
          onSignClick={handleCreateTaxExemptionIR}
          isLoading={
            isCreateUserTaxExemptionLoading ||
            slidingPanelData.loadingTaxExemptionName === "IR"
          }
        />
      )}

      {isIndividualOrganizationKycApproved &&
        !hasCSGTaxExemption(taxExemptions) && (
          <TaxExemptionBlockCSG
            canSign={isTaxExemptionCSGFormValid(CSGFormData)}
            onLearnMoreClick={() => setCurrentModalOpen("CSG")}
            onEditClick={() => setCurrentModalOpen("CSG-form")}
            onSignClick={handleCreateTaxExemptionCSG}
            isLoading={
              isCreateUserTaxExemptionLoading ||
              slidingPanelData.loadingTaxExemptionName === "CSG"
            }
          />
        )}
    </>
  );
}
