import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetProjectById } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  getUserVideoToWatch,
  PageHead,
  VideoModal,
} from "@tudigo-monorepo/web-tudigo-components";
import { mixpanelTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

export function InvestorDashboardPage() {
  const intl = useIntl();
  const { user } = useUserBusiness();

  const videoToWatch = getUserVideoToWatch();

  const { data: project } = useGetProjectById(
    {
      projectId: videoToWatch?.itemId,
      query: {
        fields: "id,content",
      },
    },
    { enabled: videoToWatch !== undefined },
  );

  return (
    <div id="dashboard-page" className="w-full">
      <PageHead
        title="Tableau de bord"
        subtitle={intl.formatMessage(
          { id: "dashboard.subtitle" },
          {
            firstName: user?.firstName,
            lastName: user?.lastName,
          },
        )}
      />

      {videoToWatch && project && (
        <VideoModal
          openMode="auto"
          watchOnce
          url={videoToWatch.url}
          itemId={videoToWatch.itemId}
          title={`L’équipe de ${project?.content?.name} a un message pour vous`}
          onClose={(timeSpent) => {
            console.log("ui.investment_thank_you_video.watch", {
              investment_video_thank_you_watch_date: new Date().toISOString(),
              project_id: project.id,
              investment_video_thank_you_watch_title: `L’équipe de ${project.content?.name} a un message pour vous`,
              investment_video_thank_you_watch_duration_seconds: timeSpent,
            });
            mixpanelTrackEvent("ui.investment_thank_you_video.watch", {
              investment_video_thank_you_watch_date: new Date().toISOString(),
              project_id: project.id,
              investment_video_thank_you_watch_title: `L’équipe de ${project.content?.name} a un message pour vous`,
              investment_video_thank_you_watch_duration_seconds: timeSpent,
            });
          }}
        />
      )}

      <Outlet />
    </div>
  );
}

export default InvestorDashboardPage;
