import { toast } from "react-toastify";

import type {
  TudigoError,
  TudigoResponse,
} from "@tudigo-monorepo/core-tudigo-api";
import type { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";

const MAX_GOAL_EXCEED = "max_goal_exceeded";
const MAX_GOAL_REACHED = "max_goal_already_reached";

export const useHandleCreateInvestment = () => {
  const { t } = useTranslation();

  const onSuccess = (res?: TudigoResponse<Investment>) => {
    const projectName = res?.data?.project?.content?.name;

    toast((content) => (
      <Alert
        close={content.closeToast}
        icon="Checked"
        variant="success"
        title={
          projectName
            ? t(
                "investments.back_office.create_investment_page.create_investment.alert.success.project_name",
                { projectName },
              )
            : t(
                "investments.back_office.create_investment_page.create_investment.alert.success",
              )
        }
      />
    ));
  };

  const onError = (error: TudigoError) => {
    const { statusCode, message } = error;

    const isMaxGoalError =
      statusCode === 400 &&
      (message === MAX_GOAL_EXCEED || message === MAX_GOAL_REACHED);

    toast((content) => (
      <Alert
        close={content.closeToast}
        icon="Error"
        variant="error"
        title={t(
          "investments.back_office.create_investment_page.create_investment.alert.error",
        )}
        description={
          isMaxGoalError
            ? t(
                `investments.back_office.create_investment_page.create_investment.alert.error.${message}`,
              )
            : undefined
        }
      />
    ));
  };

  return { onSuccess, onError };
};
