import { useIntl } from "react-intl";

import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import {
  PageEnum,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import { UserTodoList } from "@tudigo-monorepo/web-feature-todos";
import { useGetProjectsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  MetricsCard,
  ProjectCard,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

export function InvestorDashboardContent() {
  const intl = useIntl();
  const { user } = useUserBusiness();

  const { currentPagePaths } = useSpaceBusiness();

  return (
    <>
      <div className="bg-light-3 flex snap-x snap-mandatory gap-6 overflow-x-scroll px-6 py-8 lg:grid lg:grid-cols-3">
        <MetricsCard
          className="snap-center"
          value={`${formatCurrency(
            user?.investorProfile?.statistics?.totalInvestmentsAmountInCents ??
              0,
          )}`}
          heading={{
            subtitle: intl.formatMessage(
              { id: "dashboard.metrics.total_invested_amount.heading" },
              {
                count:
                  user?.investorProfile?.statistics?.totalInvestmentsCount ?? 0,
              },
            ),
          }}
          title={intl.formatMessage({
            id: "dashboard.metrics.total_invested_amount.title",
          })}
        />
        <MetricsCard
          className="snap-center"
          value={`${formatCurrency(
            user?.investorProfile?.statistics
              ?.totalSharesInvestmentsAmountInCents ?? 0,
          )}`}
          heading={{
            subtitle: intl.formatMessage(
              { id: "dashboard.metrics.total_shares_invested_amount.heading" },
              {
                count:
                  user?.investorProfile?.statistics
                    ?.totalSharesInvestmentsCount ?? 0,
              },
            ),
          }}
          title={intl.formatMessage({
            id: "dashboard.metrics.total_shares_invested_amount.title",
          })}
        />
        <MetricsCard
          className="snap-center"
          value={`${formatCurrency(
            user?.investorProfile?.statistics
              ?.totalBondsInvestmentsAmountInCents ?? 0,
          )}`}
          heading={{
            subtitle: intl.formatMessage(
              { id: "dashboard.metrics.total_bonds_invested_amount.heading" },
              {
                count:
                  user?.investorProfile?.statistics
                    ?.totalBondsInvestmentsCount ?? 0,
              },
            ),
          }}
          title={intl.formatMessage({
            id: "dashboard.metrics.total_bonds_invested_amount.title",
          })}
        />
      </div>

      <UserTodoList />

      <div className="m-6">
        <Title h3Brand="brand-2" className="text-primary mb-6" level="h3">
          Nouvelles opportunités
        </Title>
        <TudigoCollection
          useQueryFn={useGetProjectsQuery}
          columns={[]}
          actions={[]}
          hidePagination
          pagination={{
            pageSize: 4,
            pageIndex: 0,
          }}
          filters={{
            status: "started",
          }}
          render={(table) => (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              {table.getRowModel().rows?.map(({ original: project }) => {
                return (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    navigateTo={currentPagePaths[PageEnum.PROJECT].replace(
                      ":projectId",
                      project.content?.slug ?? project.id,
                    )}
                  />
                );
              })}
            </div>
          )}
        />
      </div>
    </>
  );
}
