import {
  Project,
  ProjectDocument,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { useProjectDocumentLabel } from "../../shared-internal/hooks/use-project-document-label";

const initialValue = {
  label: "",
  hidden: true,
};

export const useProjectOptionalDocumentProps = (
  project: Project | null,
  doc: ProjectDocument,
) => {
  const { isQualifiedOrWma } = useUserBusiness();
  const label = useProjectDocumentLabel(doc);

  if (
    !project ||
    doc.visibility === "investor" ||
    ["prefinished", "finished"].includes(project.status)
  ) {
    return initialValue;
  }

  if (!isQualifiedOrWma) {
    return {
      label: label,
      hidden: doc.visibility !== "public",
    };
  }

  return {
    label: label,
    hidden: false,
  };
};
