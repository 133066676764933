import { Todo } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetUserTodosQuery } from "@tudigo-monorepo/web-tudigo-api-client";

import { ClubMemberPayTodoListElement } from "../../../shared_external/components/club-member-pay-todo-list-element";
import { ClubMemberSignTodoListElement } from "../../../shared_external/components/club-member-sign-todo-list-element";

export const ClubMemberTodoList = () => {
  const { user } = useUserBusiness();
  const userId = user?.id as string;

  const { data: todos } = useGetUserTodosQuery({
    pathParams: { userId },
    filters: {
      status: "todo",
      type: ["club_membership_to_sign", "club_membership_to_pay"],
    },
    fields: "id,type,subject_club_member.fields(id,club.fields(id))",
    pagination: { limit: 1000 },
  });

  const renderTodo = (todo: Todo) => {
    switch (todo.type) {
      case "club_membership_to_sign":
        return <ClubMemberSignTodoListElement todo={todo} />;

      case "club_membership_to_pay":
        return <ClubMemberPayTodoListElement todo={todo} />;

      default:
        return null;
    }
  };

  return (
    <div className="m-6 pt-6">
      {todos?.data.map((todo, index) => (
        <div key={todo.id} className="flex flex-col">
          <div className="mb-6 flex-row">{renderTodo(todo)}</div>
        </div>
      ))}
    </div>
  );
};
