import { useEffect, useState } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Typography } from "../typography";

type FilterButton = {
  text: string;
  callback: () => void;
};

type TabButtonsProps = {
  buttons: FilterButton[];
  activeButtonIndex?: number;
  className?: string;
};

/**
 * Displays 1 to 3 clickable buttons
 */
export function TabButtons(props: TabButtonsProps) {
  const { buttons, activeButtonIndex, className } = props;
  const [selectedButton, setSelectedButton] = useState(activeButtonIndex ?? 0);

  const handleClickOnButton = (
    buttonId: number,
    callback: FilterButton["callback"],
  ): void => {
    setSelectedButton(buttonId);
    callback();
  };

  useEffect(() => {
    setSelectedButton(activeButtonIndex ?? 0);
  }, [activeButtonIndex]);

  if (buttons.length === 0 || buttons.length > 3) {
    console.error(
      "TabButtons is made today for 1 to 3 buttons, not for other use cases",
    );

    return;
  }

  return (
    <div
      className={cn(
        "h-[35px] w-full",
        "flex flex-row items-center justify-evenly",
        "border-medium-2 rounded-md border",
        className,
      )}
    >
      {buttons.map(({ text, callback }, buttonId) => (
        <div
          key={buttonId}
          className={cn(
            "h-full",
            "flex grow items-center justify-center",
            { "bg-light-3 cursor-pointer": selectedButton !== buttonId },
            { "rounded-l-md": buttonId === 0 },
            { "border-light-1 border-l": buttonId !== 0 },
            { "rounded-r-md": buttonId === buttons.length - 1 },
          )}
          onClick={() => handleClickOnButton(buttonId, callback)}
        >
          <Typography
            variant="body3-regular"
            className={cn({ "text-dark-2": selectedButton !== buttonId })}
          >
            {text}
          </Typography>
        </div>
      ))}
    </div>
  );
}
