import { useEffect, useMemo, useState } from "react";
import useDebouncedCallback from "@restart/hooks/useDebouncedCallback";
import { useNavigate } from "react-router-dom";

import {
  getProjectMechanicSettings,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  Button,
  CurrencyInput,
  InputSlider,
} from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../../../hooks/use-project-context";

export function SimulatorWidget() {
  const project = useProjectContext() as Project;
  const [amountInCents, setAmountInCents] = useState(100000);
  const navigate = useNavigate();

  const { currentPagePaths } = useSpaceBusiness();

  const projectMechanicSettings = getProjectMechanicSettings(project);

  const handleSliderChange = useDebouncedCallback((e) => {
    setAmountInCents(e?.target?.value * 100);
  }, 150);

  const maxValue = project?.goal?.maxInCents
    ? project?.goal?.maxInCents / 100
    : 2000000;

  const underMinimumErrors = useMemo(() => {
    if (amountInCents < projectMechanicSettings?.minimumTicketAmountInCents) {
      return [
        `Le montant minimum est de ${formatCurrency(
          projectMechanicSettings?.minimumTicketAmountInCents,
        )}`,
      ];
    }

    return [];
  }, [amountInCents, projectMechanicSettings?.minimumTicketAmountInCents]);

  useEffect(() => {
    if (project) {
      setAmountInCents(projectMechanicSettings?.minimumTicketAmountInCents);
    }
  }, [project, projectMechanicSettings?.minimumTicketAmountInCents]);

  const handleCalculate = () => {
    navigate(
      `${currentPagePaths[PageEnum.PROJECT].replace(
        ":projectId",
        project?.content?.slug ?? project.id,
      )}/simulator`,
      {
        state: { amountInCents },
      },
    );
  };

  if (!project) return null;

  return (
    <>
      <article className="flex gap-3">
        <div className="flex w-1/2 flex-col">
          <h2 className="text-primary mb-1 text-sm">{`Montant de l'investissement`}</h2>
          <CurrencyInput value={amountInCents} onChange={setAmountInCents} />
          <InputSlider
            valueChip={false}
            min={projectMechanicSettings?.minimumTicketAmountInCents / 100}
            max={maxValue}
            step={projectMechanicSettings?.unitPriceInCents / 100}
            className="-mt-[10px]"
            value={amountInCents / 100}
            onChange={handleSliderChange}
          />
        </div>
        <div className="mt-4 w-1/2">
          <Button
            label="Calculer"
            variant="tertiary"
            className="mt-2 w-full"
            onClick={handleCalculate}
          />
        </div>
      </article>
      {underMinimumErrors.length > 0 && (
        <div className="text-sm text-red-500">{underMinimumErrors[0]}</div>
      )}
    </>
  );
}
