import { Organization } from "@tudigo-monorepo/core-tudigo-api-models";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

import { useOrganizationKycStatusChipProps } from "../../hooks/use-organization-kyc-status-chip-props";

export type OrganizationKycChipProps = {
  organization?: Organization | null;
};

export function OrganizationKycChip(props: OrganizationKycChipProps) {
  const chipStatusProps = useOrganizationKycStatusChipProps(props.organization);

  return <Chip {...chipStatusProps} />;
}
