export function getIRAvailabilityDateLimit() {
  const currentYear = new Date().getFullYear();

  return new Date(`${currentYear}-11-30`);
}

export function getNextFullYear() {
  const currentYear = new Date().getFullYear();
  const nextYear = new Date(
    new Date().setFullYear(currentYear + 1),
  ).getFullYear();

  return nextYear;
}

export function getNextYearCalendarDateRange() {
  const nextYear = getNextFullYear();

  const startDate = new Date(`${nextYear}-01-01`).toISOString().slice(0, 10);
  const endDate = new Date(`${nextYear}-12-31`).toISOString().slice(0, 10);

  return { startDate, endDate };
}
