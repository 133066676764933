import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetProjectsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { ProjectCard } from "@tudigo-monorepo/web-tudigo-components";

export function InvestorProfileBannerContent() {
  const { data } = useGetProjectsQuery({
    filters: { status: ["started", "prefinished"] },
    pagination: { limit: 3 },
  });

  const { t } = useTranslation();

  return (
    <div className="flex flex-col rounded-lg border">
      <div className="z-0 flex h-[378px] flex-col gap-y-6 overflow-hidden rounded-tl-lg rounded-tr-lg px-6 pt-6">
        <h3 className="font-montserrat text-primary text-lg font-bold">
          {t("qualification.summary_page.investor_profile.banner.header.title")}
        </h3>

        <ul className="grid grid-cols-4 place-items-stretch gap-x-4 blur-[2px] lg:grid-cols-8 xl:grid-cols-12">
          {data?.projects.map((project, index) => (
            <ProjectCard
              key={project.id}
              project={project}
              className={cn(
                "col-span-4 mx-auto min-w-[242px] flex-1 self-stretch lg:flex",
                {
                  hidden: index > 0,
                  "md:hidden": index > 1,
                },
              )}
            />
          ))}
        </ul>
      </div>

      <div className="z-10 flex flex-col gap-y-2 rounded-bl-lg rounded-br-lg border-t bg-white p-8">
        <h3 className="font-montserrat text-primary text-lg font-bold">
          {t("qualification.summary_page.investor_profile.banner.footer.title")}
        </h3>

        <a
          href="https://www.tudigo.co/politique-de-confidentialite"
          target="_blank"
          rel="noreferrer"
          className="text-accent font-montserrat cursor-pointer text-sm font-medium"
        >
          {t(
            "qualification.summary_page.investor_profile.banner.footer.cta.label",
          )}
        </a>
      </div>
    </div>
  );
}
