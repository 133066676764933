import { useEffect, useRef, useState } from "react";

type Output = "seconds" | "milliseconds" | "minutes" | "hours";

const outputToDivider = {
  milliseconds: 1,
  seconds: 1000,
  minutes: 60000,
  hours: 3600000,
} as const;

type UseTimeSpentOptions = {
  output?: Output;
  onEnded?: (timeSpent: number) => void;
};

export const useTimeSpent = (opts?: UseTimeSpentOptions) => {
  const { output = "seconds" } = opts ?? {};
  const divider = outputToDivider[output];

  const startTime = useRef<number | null>(null);

  const [timeSpent, setTimeSpent] = useState(0);

  const start = () => {
    startTime.current = performance.now();
  };

  const stop = () => {
    if (startTime.current) {
      const end = performance.now();
      const time = (end - startTime.current) / divider;
      setTimeSpent(time);
      opts?.onEnded?.(time);
      startTime.current = null;
    }
  };

  useEffect(() => {
    start();

    return () => {
      stop();
    };
  }, [divider]);

  return { start, stop, timeSpent };
};
