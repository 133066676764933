import { useCallback, useState } from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { useProjectsRefinements } from "../hooks/use-projects-refinements";
import {
  AlgoliaRefinementAttributes,
  type RefinementAttributesKey,
  type RefinementAttributesValue,
} from "./../models/algolia-refinement-attributes";
import { CustomRefinementList } from "./custom-refinement-list";
import { FiltersPanel } from "./filters-panel";

export function ProjectsFilters() {
  const { currentRefinements } = useProjectsRefinements();
  const { t } = useTranslation();

  const [panelsOpenState, setPanelsOpenState] = useState<
    Record<RefinementAttributesKey, boolean>
  >({
    INSTRUMENT: false,
    TAX_EXEMPTION: false,
    STATE: false,
    MATURITY: false,
    CATEGORY: false,
  });

  const handlePanelClick = useCallback(
    (key: RefinementAttributesKey) => {
      const isPrevPanelOpen = panelsOpenState[key];

      setPanelsOpenState({
        ...panelsOpenState,
        [key]: !isPrevPanelOpen,
      });
    },
    [panelsOpenState],
  );

  const translateRefinementItemLabel = (
    key: RefinementAttributesKey,
    item: any,
  ) => {
    switch (key) {
      case "INSTRUMENT":
        return t(`projects.mechanic.label.${item.label}`);
      case "TAX_EXEMPTION":
        return t(
          `component.project_card.list.item.tax_exemption.${item.label}`,
        );
      case "STATE":
        return t(`projects.stage.label.${item.label}`);
      case "MATURITY":
        return t(`projects.maturity.label.${item.label}`);
      case "CATEGORY":
      default:
        return item.label;
    }
  };

  const refinementList = Object.entries(AlgoliaRefinementAttributes).map(
    ([value, key]) => ({
      key: value as RefinementAttributesKey,
      value: key as RefinementAttributesValue,
    }),
  );

  return (
    <div className="flex w-full flex-col gap-y-6 pb-40">
      {refinementList.map((refinement) => (
        <FiltersPanel
          key={refinement.key}
          title={t(`projects.web_app.refinements.${refinement.value}.label`)}
          active={currentRefinements[refinement.key].hasRefinements}
          open={panelsOpenState[refinement.key]}
          onClick={() => handlePanelClick(refinement.key)}
        >
          <CustomRefinementList
            attribute={AlgoliaRefinementAttributes[refinement.key]}
            transformItem={(item) => ({
              ...item,
              label: translateRefinementItemLabel(refinement.key, item),
            })}
          />
        </FiltersPanel>
      ))}
    </div>
  );
}
