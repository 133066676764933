import type { ReactNode } from "react";

import {
  SPACE_CARGO_PROJECT_ID,
  SPACE_CARGO_YT_VIDEO_URL,
  VideoModal,
} from "@tudigo-monorepo/web-tudigo-components";
import { mixpanelTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

import { useInvestmentFunnelPageContext } from "../../../../../pages/investment-funnel-page";

const ThankYouLayout = (props: { children: ReactNode }) => {
  const { project } = useInvestmentFunnelPageContext();
  // Temporary business logic, video should be shown only for Space Cargo project
  // In real world scenario, a check for a thankyou video url in the current investment project is required
  const temporaryCheck = project.id !== SPACE_CARGO_PROJECT_ID;

  if (!project || temporaryCheck) {
    return props.children;
  }

  return (
    <>
      <VideoModal
        openMode="auto"
        url={SPACE_CARGO_YT_VIDEO_URL}
        itemId={project.id}
        title={`L’équipe de ${project.content?.name} a un message pour vous`}
        onClose={(timeSpent) => {
          console.log("ui.investment_thank_you_video.watch", {
            investment_video_thank_you_watch_date: new Date().toISOString(),
            project_id: SPACE_CARGO_PROJECT_ID,
            investment_video_thank_you_watch_title: `L’équipe de ${project.content?.name} a un message pour vous`,
            investment_video_thank_you_watch_duration_seconds: timeSpent,
          });
          mixpanelTrackEvent("ui.investment_thank_you_video.watch", {
            investment_video_thank_you_watch_date: new Date().toISOString(),
            project_id: SPACE_CARGO_PROJECT_ID,
            investment_video_thank_you_watch_title: `L’équipe de ${project.content?.name} a un message pour vous`,
            investment_video_thank_you_watch_duration_seconds: timeSpent,
          });
        }}
      />

      {props.children}
    </>
  );
};

export { ThankYouLayout };
