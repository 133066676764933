import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { User, UserState } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient } from "../../../";
import queryKeyFactory from "../../query-key-factory";

type RequestParams = {
  userId: User["id"] | "me";
};

type MutationOptions = UseMutationOptions<
  TudigoResponse<UserState>,
  TudigoError,
  RequestParams
>;

export const markIntroHasDone = async (params: RequestParams) => {
  const apiClient = getApiClient();
  const { userId } = params;
  const response = await apiClient.apiV1.users.markIntroHasDone(userId);

  return response.data;
};

const usersKeys = queryKeyFactory("users");

export const useMarkIntroAsDone = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  const updateQueryData = (userId: User["id"]) => {
    queryClient.setQueryData(usersKeys.detail(userId), (data: User) => {
      if (!data) return undefined;

      return {
        ...data,
        state: {
          ...data.state,
          hasDoneIntro: true,
        },
      };
    });
  };

  return useMutation({
    mutationFn: (params: RequestParams) => markIntroHasDone(params),
    ...options,
    onSuccess: (...props) => {
      const [_, params] = props;
      updateQueryData("me");
      updateQueryData(params.userId);
      options?.onSuccess?.(...props);
    },
  });
};
