import React, { useState } from "react";
import { toast } from "react-toastify";

import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUpdateCircleMember } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  ButtonRebrand,
  Icon,
  Modal,
} from "@tudigo-monorepo/web-tudigo-components";

import { ClubLeaderMemberDetailStatisticsCard } from "../../club-leader-member-detail-page/components/club-leader-member-detail-statistics-card";

type MyMembershipFollowingContentProps = {
  clubMember: ClubMember;
};

export const MyMembershipFollowingContent = (
  props: MyMembershipFollowingContentProps,
) => {
  const { clubMember } = props;
  const [isOpenUnfollowModal, setIsOpenUnsubcribeModal] = useState(false);
  const { t } = useTranslation();
  const { mutate: updateCircleMember } = useUpdateCircleMember({
    onSuccess() {
      toast((content) => (
        <Alert
          icon="Info"
          close={content.closeToast}
          variant="success"
          size="S"
          title="Vous ne suivez plus ce cercle"
        />
      ));
    },
    onError() {
      toast((content) => (
        <Alert
          icon="Info"
          close={content.closeToast}
          variant="error"
          size="S"
          title="Une erreur est survenue. Veuillez réessayer."
        />
      ));
    },
  });

  return (
    <>
      <p className="text-dark-1 px-6 pt-6 text-lg font-semibold">
        {t(
          "space_investor.my_circles.my_membership_page.following_content.information",
        )}
      </p>
      <ClubLeaderMemberDetailStatisticsCard clubMember={clubMember} />
      <div className="border-light-1 m-6 rounded-lg border p-6">
        <p className="text-error text-sm">
          {t(
            "space_investor.my_circles.my_membership_page.following_content.cancel_title",
          )}
        </p>
        <ButtonRebrand
          onClick={() => setIsOpenUnsubcribeModal(true)}
          className={cn(
            "text-error border-error mt-2 rounded-full text-sm font-light",
          )}
          variant="outline"
        >
          {t(
            "space_investor.my_circles.my_membership_page.following_content.cancel_label",
          )}
          <Icon name="Delete" primaryColor="red" />
        </ButtonRebrand>
        <Modal
          isOpen={isOpenUnfollowModal}
          setIsOpen={setIsOpenUnsubcribeModal}
          title="Ne plus suivre ce cercle"
          confirmAction={() => {
            updateCircleMember({
              clubMemberId: clubMember.id!,
              body: {
                status: "unfollowed",
              },
              query: {
                fields:
                  "id,club.fields(id),user.fields(investor_profile,first_name,last_name,created_at),statistics,investments,status,subscription_periods",
              },
            });
          }}
          confirmLabel="Je confirme"
          content="Êtes-vous sûr de vouloir ne plus suivre ce cercle ?"
        />
      </div>
    </>
  );
};
