import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../form";
import { InputConnectedProps } from "../input.type";
import { Label } from "../label";
import { RadioGroup, RadioGroupItem } from "./radio-group";

interface RadioGroupConnectedProps extends InputConnectedProps {
  options?: {
    value: string;
    label: string;
  }[];
  defaultValue?: string;
  disableValueFn?: (value: string) => boolean;
  onValueChange?: (value: string) => void;
}

export function RadioGroupConnected({
  options,
  defaultValue,
  name,
  label,
  className,
  description,
  required,
  disableValueFn,
  onValueChange,
}: RadioGroupConnectedProps) {
  const form = useFormContext();
  console.log("form fields state", form.watch());

  if (!form) {
    throw new Error("RadioGroupConnected must be used within a Form component");
  }

  const handleChange = (
    field: ControllerRenderProps<FieldValues, string>,
    value: string,
  ) => {
    field.onChange(value);
    onValueChange?.(value);
  };

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem>
          <div className="flex items-center gap-2">
            <FormControl>
              <RadioGroup
                defaultValue={field.value ?? defaultValue}
                onValueChange={(value) => handleChange(field, value)}
                className={className}
              >
                {options?.map((item) => (
                  <RadioGroupItemConnected
                    key={item.value}
                    item={item}
                    disabled={disableValueFn?.(item.value)}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {label && <FormLabel required={required}>{label}</FormLabel>}
          </div>

          {fieldState?.error ? (
            <FormMessage />
          ) : (
            description && <FormDescription>{description}</FormDescription>
          )}
        </FormItem>
      )}
    />
  );
}

function RadioGroupItemConnected(props: {
  item: { value: string; label: string };
  disabled?: boolean;
}) {
  const id = `radio-group-item-${props.item.value}`;

  return (
    <div className="flex items-center space-x-2">
      <RadioGroupItem
        id={id}
        value={props.item.value}
        disabled={props.disabled}
        className={cn({ "bg-muted-foreground": props.disabled })}
      />
      <Label
        htmlFor={id}
        className={cn("cursor-pointer", {
          "text-muted-foreground cursor-default": props.disabled,
        })}
      >
        {props.item.label}
      </Label>
    </div>
  );
}
