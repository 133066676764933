import { create } from "zustand";

import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";

type QualificationIntroState = {
  selectedProfile: UserRole;
  setSelectedProfile: (profile: UserRole) => void;

  showExitDialog: boolean;
  setShowExitDialog: (show: boolean) => void;
};

const useQualificationIntro = create<QualificationIntroState>((set) => ({
  selectedProfile: UserRole.INVESTOR,
  setSelectedProfile: (profile: UserRole) => set({ selectedProfile: profile }),

  showExitDialog: false,
  setShowExitDialog: (show: boolean) => set({ showExitDialog: show }),
}));

export { useQualificationIntro };
