import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";

export function LoginPage() {
  const searchParams = new URLSearchParams(window.location.search);
  const authClient = getAuthClient();
  const authUrl = authClient.authorizeUrl();

  document.location.href = `${authUrl}&${searchParams.toString()}`;

  return null;
}

export default LoginPage;
