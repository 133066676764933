import { Link } from "react-router-dom";

import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useGetProjectsQuery,
  useGetUserByIdQuery,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  Alert,
  MetricsCard,
  ProjectCard,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

export function WmaDashboardContent() {
  const { data: user } = useGetUserByIdQuery({
    userId: "me",
  });

  const { currentPagePaths } = useSpaceBusiness();

  return (
    <>
      <div className="bg-light-3 flex snap-x snap-mandatory gap-6 overflow-x-scroll px-6 py-8 md:grid md:grid-cols-3">
        <MetricsCard
          className="snap-center"
          value={`${formatCurrency(
            user?.wmaProfile?.statistics?.totalInvestmentsAmountInCents ?? 0,
          )}`}
          title="Montant total des investissements"
          navigateTo="/wma/investments"
        />
        <MetricsCard
          className="snap-center"
          value={user?.wmaProfile?.statistics?.totalInvestmentsCount ?? 0}
          title="Investissements"
          navigateTo="/wma/investments"
        />
        <MetricsCard
          className="snap-center"
          value={user?.wmaProfile?.statistics?.totalCustomersCount ?? 0}
          title="Investisseurs"
          navigateTo="/wma/customers"
        />
      </div>
      <div className="m-6">
        <Alert
          size="F"
          variant="info"
          icon="Info"
          title="Nouvelle plateforme Tudigo"
          description={
            <span>
              La migration vers la nouvelle plateforme est en cours. Il peut
              manquer quelques documents mais pas d’inquietude vous les
              retrouverez dns les prochains jours. En attendant nous serions
              ravis d’avoir{" "}
              <Link
                to="https://tudigo.typeform.com/to/ixSsrm6q"
                target="_blank"
                className="underline"
              >
                votre avis
              </Link>
            </span>
          }
        />
      </div>

      <div className="m-6">
        <Title h3Brand="brand-2" className="text-primary mb-6" level="h3">
          Nouvelles opportunités
        </Title>
        <TudigoCollection
          useQueryFn={useGetProjectsQuery}
          columns={[]}
          actions={[]}
          hidePagination
          pagination={{
            pageSize: 4,
            pageIndex: 0,
          }}
          filters={{
            status: "started",
          }}
          render={(table) => (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              {table.getRowModel().rows?.map(({ original: project }) => {
                return (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    navigateTo={currentPagePaths[PageEnum.PROJECT].replace(
                      ":projectId",
                      project.content?.slug ?? project.id,
                    )}
                  />
                );
              })}
            </div>
          )}
        />
      </div>
    </>
  );
}
