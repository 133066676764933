import { Navigate, Route, Routes } from "react-router-dom";

import { MyCirclesTable } from "../../pages/investor-my-circles-page/components/my-circles-table";
import { InvestorMyCirclesPage } from "../../pages/investor-my-circles-page/investor-my-circles-page";
import { InvestorMyMembershipPage } from "../../pages/investor-my-membership-page/investor-my-membership-page";

export function ClubsV2InvestorRouteWebapp() {
  return (
    <Routes>
      <Route
        path="/:circleId/membership/:membershipId"
        element={<InvestorMyMembershipPage />}
      />
      <Route path="/*" element={<InvestorMyCirclesPage />}>
        <Route path="all" element={<MyCirclesTable />} />
        <Route
          path="follow"
          element={<MyCirclesTable status={["following"]} />}
        />
        <Route
          path="premium"
          element={<MyCirclesTable status={["active", "draft"]} />}
        />
        <Route path="*" element={<Navigate to="all" />} />
      </Route>
      <Route path="*" element={<Navigate to="/all" />} />
    </Routes>
  );
}
