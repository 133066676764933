import { TaxExemption } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { TaxExemptionModalName } from "../../content/investor-profile-tax-exemptions-content";
import { useInvestorTaxExemptions } from "../hooks/use-investor-tax-exemptions";
import {
  checkIfDateIsValidForSigningTaxExemptionIR,
  checkIfLastPendingTaxExemptionIRSingable,
} from "../utils/tax-exemptions-utils";
import { TaxExemptionBlockCSG } from "./tax-exemption-block-csg";
import { TaxExemptionBlockIR } from "./tax-exemption-block-ir";

interface PendingTaxExemptionListProps {
  canSignCSG: boolean;
  onSignPendingTaxExemption: (item: TaxExemption) => void;
  setCurrentModalOpen: (modal: TaxExemptionModalName) => void;
}

export function PendingTaxExemptionList(props: PendingTaxExemptionListProps) {
  const { canSignCSG, onSignPendingTaxExemption, setCurrentModalOpen } = props;

  const { isIndividualOrganizationKycApproved } = useUserBusiness();
  const { pendingTaxExemptionIR, pendingTaxExemptionCSG } =
    useInvestorTaxExemptions();

  return (
    <>
      {pendingTaxExemptionIR &&
        checkIfDateIsValidForSigningTaxExemptionIR() &&
        checkIfLastPendingTaxExemptionIRSingable(pendingTaxExemptionIR) && (
          <TaxExemptionBlockIR
            validityStartDate={pendingTaxExemptionIR.validityStartDate}
            onLearnMoreClick={() => setCurrentModalOpen("IR")}
            onSignClick={() => onSignPendingTaxExemption(pendingTaxExemptionIR)}
          />
        )}

      {isIndividualOrganizationKycApproved && pendingTaxExemptionCSG && (
        <TaxExemptionBlockCSG
          canSign={canSignCSG}
          onLearnMoreClick={() => setCurrentModalOpen("CSG")}
          onEditClick={() => setCurrentModalOpen("CSG-form")}
          onSignClick={() => onSignPendingTaxExemption(pendingTaxExemptionCSG)}
        />
      )}
    </>
  );
}
