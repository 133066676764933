import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { usePostKycSubmitDataRequestMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  PageHead,
} from "@tudigo-monorepo/web-tudigo-components";
import {
  mixpanelTrackEvent,
  useTrack,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useKYCContext } from "../../kyc-page";
import {
  checkIfBankAccountRequirementsAreMet,
  checkIfOrganizationDocumentsRequirementsAreMet,
  checkIfOrganizationPersonIdentityDocumentsRequirementAreMet,
} from "../../utils/kyc-documents-utils";
import {
  checkIfCompanyOrganizationPersonsDocumentsRequirementsAreMet,
  checkIfOrganizationRepresentativeRequirementsAreMet,
  checkIfUltimateBeneficialOwnersFormDataRequirementsAreMet,
} from "../../utils/kyc-utils";
import { BankAccountSummarySection } from "./partials/bank-account-summary-section";
import { LegalConsentsSection } from "./partials/legal-consents-section";
import { OrganizationDetailSummarySection } from "./partials/organization-detail-summary-section";
import { OrganizationDocumentsSummarySection } from "./partials/organization-documents-summary-section";
import { PersonalInformationSummarySection } from "./partials/personal-information-summary-section";
import { UboDocumentsSummarySection } from "./partials/ubo-documents-summary-section";
import { UboSummarySection } from "./partials/ubo-summary-section";

export function KycSummary() {
  const { organization, organizationQueryKey } = useKYCContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const [consented, setConsented] = useState(false);

  const checkIfKycFormCanBeSubmitted = () => {
    if (organization.type === ORGANIZATIONS.TYPE.COMPANY) {
      return (
        checkIfOrganizationRepresentativeRequirementsAreMet(
          organization.organizationRepresentative,
        ) &&
        checkIfUltimateBeneficialOwnersFormDataRequirementsAreMet(
          organization.ultimateBeneficialOwners,
        ) &&
        checkIfBankAccountRequirementsAreMet(
          organization.organizationBankAccounts[0],
        ) &&
        checkIfCompanyOrganizationPersonsDocumentsRequirementsAreMet(
          organization.ultimateBeneficialOwners,
          false,
        ) &&
        checkIfOrganizationDocumentsRequirementsAreMet(organization)
      );
    } else {
      return (
        checkIfOrganizationPersonIdentityDocumentsRequirementAreMet(
          organization.organizationRepresentative,
          true,
        ) &&
        checkIfBankAccountRequirementsAreMet(
          organization.organizationBankAccounts[0],
        )
      );
    }
  };

  useTrack(() => {
    mixpanelTrackEvent("ui.kyc_step_summary.started", {
      kyc_step_name: "Summary",
    });

    return true;
  }, []);

  const {
    mutateAsync: postKycSubmitDataRequest,
    isPending: formSubmissionIsPending,
  } = usePostKycSubmitDataRequestMutation({
    onSuccess: async () => {
      mixpanelTrackEvent("ui.kyc_step_summary.completed", {
        kyc_step_name: "Summary",
      });

      await queryClient.invalidateQueries({
        queryKey: organizationQueryKey,
      });
      toast((content) => {
        return (
          <Alert
            icon="Checked"
            variant="success"
            size="S"
            close={content.closeToast}
            title={t(
              "kyc_funnel.step_personal_information.alert_on_success.title",
            )}
          />
        );
      });
      navigate(`${kycPagePath}/waiting-approval`, {
        replace: true,
      });
    },
    onError: async (error) => {
      await queryClient.invalidateQueries({
        queryKey: organizationQueryKey,
      });
      toast((content) => {
        return (
          <Alert
            icon="Error"
            size="S"
            close={content.closeToast}
            variant="error"
            title={t("kyc_funnel.final_summary.error_on_submit.alert.title")}
            description={error.message}
          />
        );
      });
    },
  });

  const handleSubmit = () => {
    postKycSubmitDataRequest({
      organizationId: organization.id,
    });
  };

  const displayMainRejectedAlert = () => {
    return (
      organization.internalKycStatus ===
        ORGANIZATIONS.INTERNAL_KYC_STATUS.REJECTED &&
      !checkIfKycFormCanBeSubmitted()
    );
  };

  return (
    <>
      <PageHead
        chipsTop={[
          {
            label:
              organization.type === ORGANIZATIONS.TYPE.COMPANY
                ? t(
                    "kyc_funnel.step_bank_account_information.page.chip.label.company",
                  )
                : t(
                    "kyc_funnel.step_bank_account_information.page.chip.label.individual",
                  ),
          },
          {
            label: organization.name,
            type: "info",
          },
        ]}
        title={t("kyc_funnel.final_summary.page.title")}
        subtitle={t("kyc_funnel.final_summary.page.subtitle")}
        withEndLine
      />

      <div className="kyc-summary-section m-6 flex flex-col gap-12">
        {displayMainRejectedAlert() && (
          <Alert
            variant="error"
            size="F"
            icon="Cross"
            title={t(
              "kyc_funnel.final_summary.organization_kyc_status_rejected.alert.title",
            )}
            description={t(
              "kyc_funnel.final_summary.organization_kyc_status_rejected.alert.description",
            )}
          />
        )}

        <PersonalInformationSummarySection />

        {organization.type === ORGANIZATIONS.TYPE.COMPANY && (
          <>
            <OrganizationDetailSummarySection />
            <UboSummarySection />
            <UboDocumentsSummarySection section="ubo-documents" />
            <OrganizationDocumentsSummarySection />
          </>
        )}

        {organization.type === ORGANIZATIONS.TYPE.INDIVIDUAL && (
          <UboDocumentsSummarySection section="personal-documents" />
        )}

        <BankAccountSummarySection />

        <LegalConsentsSection
          consented={consented}
          setConsented={setConsented}
        />
      </div>

      <div className="border-light-1 fixed bottom-0 left-0 z-20 flex h-[80px] w-full items-center justify-center gap-x-4 border-t bg-white px-6 sm:h-[104px]">
        <Button
          isLoading={formSubmissionIsPending}
          disabled={!checkIfKycFormCanBeSubmitted() || !consented}
          className="w-fit px-12"
          variant="primary"
          iconRight="ArrowRight"
          label={t("kyc_funnel.final_summary.submit_button.label")}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}
