import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  NumberSearchCriteria,
  SearchCriterias,
  UnArray,
} from "@tudigo-monorepo/core-tudigo-utils";
import { extractValueFromPath } from "@tudigo-monorepo/web-feature-form";
import { InvestmentSelector } from "@tudigo-monorepo/web-feature-investments";
import { OrganizationSelector } from "@tudigo-monorepo/web-feature-organizations";
import { ProjectSelector } from "@tudigo-monorepo/web-feature-projects";
import { InputDate, InputSelect } from "@tudigo-monorepo/web-tudigo-components";
import { CircleSelector } from "@tudigo-monorepo/web/feature-clubs-v2";

import { ShareholdersSelector } from "../../../feature-organizations/src/lib/shared/components/shareholders-selector/shareholders-selector";
import { FilterConfiguration } from "./types";
import {
  mergeFiltersWithUrlParams,
  mergeUrlParamsWithFilters,
} from "./utils/filters-utils";

type TudigoFiltersProps<F> = {
  className?: string;
  filters: F;
  setFilters: Dispatch<SetStateAction<F>>;
  filtersConfiguration: FilterConfiguration<F>[];
  useLocationAsState?: boolean;
};

export function TudigoFilters<F extends SearchCriterias>(
  props: TudigoFiltersProps<F>,
) {
  const {
    className,
    filters,
    setFilters,
    filtersConfiguration,
    useLocationAsState,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const [localFilters, setLocalFilters] = useState<F>(
    mergeFiltersWithUrlParams(
      filtersConfiguration,
      searchParams,
      {
        ...filters,
      },
      false,
    ),
  );

  // When Url change
  useEffect(() => {
    if (useLocationAsState) {
      setFilters((filters) => {
        const shallowFilters = { ...filters };
        const newFilters = mergeFiltersWithUrlParams(
          filtersConfiguration,
          searchParams,
          filters,
        );
        if (JSON.stringify(newFilters) !== JSON.stringify(shallowFilters)) {
          return { ...newFilters };
        }

        return newFilters;
      });
    }
  }, [searchParams, setFilters, useLocationAsState, filtersConfiguration]);

  // When Local filters change
  useEffect(() => {
    if (!useLocationAsState) {
      setFilters(localFilters);
    } else if (localFilters) {
      const previousQuery = searchParams.toString();
      mergeUrlParamsWithFilters(
        filtersConfiguration,
        localFilters,
        searchParams,
      );
      if (previousQuery !== searchParams.toString()) {
        navigate({ search: searchParams.toString() });
      }
    }
  }, [
    localFilters,
    navigate,
    setFilters,
    useLocationAsState,
    searchParams,
    filtersConfiguration,
    filters,
  ]);

  const formatDate = (date: Date) => {
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    return year + "-" + month + "-" + day;
  };

  return (
    <div
      className={cn(
        "bg-light-3 flex flex-col justify-end gap-6 rounded-lg p-2.5 sm:flex-row",
        className,
      )}
    >
      {filtersConfiguration.map((filter, index) => {
        if (filter.type === "select" && filter.options.length > 0) {
          return (
            <InputSelect<UnArray<typeof filter.options>, string>
              key={index}
              label={filter.label}
              labelPosition="inside"
              className="sm:w-[288px]"
              getValueFromItem={(item) => item.value}
              items={filter.options
                .map((option) => ({
                  value: option.value,
                  name: option.name,
                }))
                .filter(
                  (value, i, array) =>
                    array.findIndex((v) => v.value === value.value) === i,
                )}
              placeholder={filter.placeholder ?? filter.name}
              selectedValue={
                extractValueFromPath(localFilters, filter.name) ?? ""
              }
              onChange={(value) =>
                setLocalFilters({
                  ...localFilters,
                  [filter.name]: value as string,
                })
              }
              labelFunction={(item) => item.name}
              comparisonFunction={(item, value) => item.value === value}
            />
          );
        }
        if (filter.type === "dateRange") {
          const range = extractValueFromPath(
            localFilters,
            filter.name,
          ) as NumberSearchCriteria;

          return (
            <InputDate
              range
              key={index}
              labelPosition="inside"
              className="sm:w-[288px]"
              value={[
                range?.gte ? new Date(range.gte) : undefined,
                range?.lte ? new Date(range.lte) : undefined,
              ]}
              onChange={(dates: any) => {
                const [newStart, newEndDate] = dates as [Date, Date];
                setLocalFilters({
                  ...localFilters,
                  [filter.name]: {
                    gte: newStart ? formatDate(newStart) : undefined,
                    lte: newEndDate ? formatDate(newEndDate) : undefined,
                  },
                });
              }}
            />
          );
        }

        if (filter.type === "projectSelect") {
          const projectId = extractValueFromPath(
            localFilters,
            filter.name,
          ) as string;

          return (
            <ProjectSelector
              key={index}
              filters={filter.filters}
              handleSelectItem={(item) => {
                setLocalFilters({
                  ...localFilters,
                  [filter.name]: item?.id,
                });
              }}
              currentItemId={projectId}
            />
          );
        }

        if (filter.type === "investmentSelect") {
          const investmentId = extractValueFromPath(
            localFilters,
            filter.name,
          ) as string;

          return (
            <InvestmentSelector
              key={index}
              userId={filter.userId}
              filters={filter.filters}
              handleSelectItem={(item) => {
                setLocalFilters({
                  ...localFilters,
                  [filter.name]: item?.id,
                });
              }}
              currentItemId={investmentId ?? filter.currentItemId}
            />
          );
        }

        if (filter.type === "organizationSelect") {
          const projectId = extractValueFromPath(
            localFilters,
            filter.name,
          ) as string;

          return (
            <OrganizationSelector
              key={index}
              placeholder="Tous"
              filters={filter.filters}
              handleSelectItem={(item) => {
                setLocalFilters({
                  ...localFilters,
                  [filter.name]: item?.id,
                });
              }}
              currentItemId={projectId}
            />
          );
        }

        if (filter.type === "shareholderSelect") {
          const shareholderId = extractValueFromPath(
            localFilters,
            filter.name,
          ) as string;

          return (
            <ShareholdersSelector
              key={index}
              placeholder="Tous"
              filters={filter.filters}
              handleSelectItem={(item) => {
                setLocalFilters({
                  ...localFilters,
                  [filter.name]: item?.id,
                });
              }}
              currentItemId={shareholderId}
            />
          );
        }

        if (filter.type === "circleSelect") {
          const circleId = extractValueFromPath(
            localFilters,
            filter.name,
          ) as string;

          return (
            <CircleSelector
              key={index}
              placeholder="Tous"
              filters={filter.filters}
              handleSelectItem={(item) => {
                setLocalFilters({
                  ...localFilters,
                  [filter.name]: item?.id,
                });
              }}
              currentItemId={circleId}
            />
          );
        }

        return null;
      })}
    </div>
  );
}
