import { ClubStatus, SubscriptionPrice } from "../club/models";
import { CMSBlock } from "../cms";
import { Image } from "../images";
import { Media } from "../media/models";
import { Organization } from "../organizations";
import { ProjectTag } from "../projects";
import { User } from "../users";

export interface ClubMechanicSettings {
  minimumTicketAmountFromInCents: number;
  minimumTicketAmountToInCents: number;
  investmentDurationFromInMonth: number;
  investmentDurationToInMonth: number;
  entryFeesFromNoDecimalRate: number;
  entryFeesToNoDecimalRate: number;
  carriedFromNoDecimalRate: number;
  carriedToNoDecimalRate: number;
}

export interface ClubContent {
  name: string;
  baseline: string;
  tagline: string;
  presentationMedias: Media[];
  presentation: CMSBlock[];
  medias: CMSBlock[];
  mentoringDescription: CMSBlock[];
  exitDescription: CMSBlock[];
  modalities: CMSBlock[];
  thesis: CMSBlock[];
  premiumAdvantages: PremiumAdvantage[];
  logo: Image | null;
  cover: Image | null;
  thankYouImage: Image | null;
}

export interface ClubV2Configuration {
  isPremiumAvailable: boolean;
  subscriptionsOpeningDate: string | null;
  subscriptionsClosingDate: string | null;
  subscriptionPrice: SubscriptionPrice;
  introductorySubscriptionPrice: SubscriptionPrice | null;
  maxIntroductorySubscriptions: number;
  maxSubscriptions: number;
}

export type ClubV2 = {
  id?: string;
  name: string;
  iconBorderColor: string;
  leaders: ClubLeader[];
  organization: Organization | null;
  status: `${ClubStatus}`;
  mechanicSettings?: ClubMechanicSettings;
  configuration: ClubV2Configuration;
  socialLinks: {
    discord?: string;
  };
  statistics: {
    followersCount: number;
    clubLeadersCount: number;
    liveProjectsCount: number;
  };
  trackRecords: TrackRecord[];
  industries: Industry[] | null;
  maturities: Maturity[] | null;
  territories: Territory[] | null;
  mentorings: Mentoring[] | null;
  content: ClubContent | null;
  faqs: Faq[];
  myMembership?: {
    status: MembershipStatus;
  };
};

export type Faq = {
  question: string;
  answer: string;
};

export type PremiumAdvantage = {
  title: string;
  description: string;
};

export type ClubLeader = {
  id?: string;
  profileImage: Image;
  user?: {
    id: User["id"];
  };
  firstName: string;
  lastName: string;
  socialLinks: {
    linkedin?: string;
  };
  title: string;
  specialties?: ClubLeaderSpeciality[];
  shortDescription: string;
  description: string;
  position?: number;
};

export type ClubLeaderSpeciality = {
  code: string;
  name: string;
};

export enum MembershipStatus {
  DRAFT = "draft",
  REQUESTED = "requested",
  REJECTED = "rejected",
  BANNED = "banned",
  FOLLOWING = "following",
  SUBSCRIBED = "subscribed",
  ACTIVE = "active",
  CANCELLED = "cancelled",
}

export type TrackRecord = {
  id?: string;
  logo?: Image | null;
  title?: string;
  projectTags?: ProjectTag[];
  shortDescription?: string;
  description?: string;
  link?: string;
  position?: number;
};

export type Industry = {
  code: string;
  name: string;
};

export type Maturity = {
  code: string;
  name: string;
};

export type Territory = {
  code: string;
  name: string;
};

export type Mentoring = {
  code: string;
  name: string;
};
