import {
  NumberSearchCriteria,
  ObjectDotNotation,
  SearchCriterias,
} from "@tudigo-monorepo/core-tudigo-utils";

import { FilterConfiguration } from "../types";

export function mergeFiltersWithUrlParams<F extends SearchCriterias>(
  filtersConfiguration: FilterConfiguration<F>[],
  searchParams: URLSearchParams,
  filters: F,
  unsetIfNotPresent = true,
) {
  filtersConfiguration.forEach((filter) => {
    if (filter.type === "select") {
      const searchParam = searchParams.get(filter.name);
      filters[filter.name] = searchParam
        ? (searchParam as any)
        : unsetIfNotPresent
          ? undefined
          : filters[filter.name];
    }
    if (filter.type === "projectSelect") {
      const searchParam = searchParams.get(filter.name);
      filters[filter.name] = searchParam
        ? (searchParam as any)
        : unsetIfNotPresent
          ? undefined
          : filters[filter.name];
    }
    if (filter.type === "investmentSelect") {
      const searchParam = searchParams.get(filter.name);
      filters[filter.name] = searchParam
        ? (searchParam as any)
        : unsetIfNotPresent
          ? undefined
          : filters[filter.name];
    }
    if (filter.type === "organizationSelect") {
      const searchParam = searchParams.get(filter.name);
      filters[filter.name] = searchParam
        ? (searchParam as any)
        : unsetIfNotPresent
          ? undefined
          : filters[filter.name];
    }
    if (filter.type === "shareholderSelect") {
      const searchParam = searchParams.get(filter.name);
      filters[filter.name] = searchParam
        ? (searchParam as any)
        : unsetIfNotPresent
          ? undefined
          : filters[filter.name];
    }
    if (filter.type === "circleSelect") {
      const searchParam = searchParams.get(filter.name);
      filters[filter.name] = searchParam
        ? (searchParam as any)
        : unsetIfNotPresent
          ? undefined
          : filters[filter.name];
    }
    if (filter.type === "dateRange") {
      filters[filter.name] = {
        ...(filters[filter.name] as NumberSearchCriteria),
        gte: searchParams.get(filter.name + "[gte]") ?? undefined,
      } as any;
      filters[filter.name] = {
        ...(filters[filter.name] as NumberSearchCriteria),
        gt: searchParams.get(filter.name + "[gt]") ?? undefined,
      } as any;
      filters[filter.name] = {
        ...(filters[filter.name] as NumberSearchCriteria),
        lte: searchParams.get(filter.name + "[lte]") ?? undefined,
      } as any;
      filters[filter.name] = {
        ...(filters[filter.name] as NumberSearchCriteria),
        lt: searchParams.get(filter.name + "[lt]") ?? undefined,
      } as any;
      filters[filter.name] = {
        ...(filters[filter.name] as NumberSearchCriteria),
        e: searchParams.get(filter.name + "[e]") ?? undefined,
      } as any;
      filters[filter.name] = {
        ...(filters[filter.name] as NumberSearchCriteria),
        ne: searchParams.get(filter.name + "[ne]") ?? undefined,
      } as any;
    }
  });

  return filters;
}

export function mergeUrlParamsWithFilters<F extends SearchCriterias>(
  filtersConfiguration: FilterConfiguration<F>[],
  filters: F,
  searchParams: URLSearchParams,
) {
  Object.entries(filters).forEach(([key, filterValue]) => {
    const filterConfiguration = filtersConfiguration.find(
      ({ name }) => name === key,
    );
    if (filterConfiguration === undefined) return;
    if (
      [
        "select",
        "projectSelect",
        "organizationSelect",
        "shareholderSelect",
        "circleSelect",
        "investmentSelect",
      ].includes(filterConfiguration.type)
    ) {
      if (filterValue) {
        searchParams.set(key, filterValue as any);
      } else {
        searchParams.delete(key);
      }
    } else if (filterConfiguration.type === "dateRange") {
      const numberSearchCriteria = filterValue as NumberSearchCriteria;
      if (numberSearchCriteria?.gte) {
        searchParams.set(key + "[gte]", numberSearchCriteria.gte);
      } else {
        searchParams.delete(key + "[gte]");
      }
      if (numberSearchCriteria?.gt) {
        searchParams.set(key + "[gt]", numberSearchCriteria.gt);
      } else {
        searchParams.delete(key + "[gt]");
      }
      if (numberSearchCriteria?.lte) {
        searchParams.set(key + "[lte]", numberSearchCriteria.lte);
      } else {
        searchParams.delete(key + "[lte]");
      }
      if (numberSearchCriteria?.lt) {
        searchParams.set(key + "[lt]", numberSearchCriteria.lt);
      } else {
        searchParams.delete(key + "[lt]");
      }
      if (numberSearchCriteria?.e) {
        searchParams.set(key + "[e]", numberSearchCriteria.e);
      } else {
        searchParams.delete(key + "[e]");
      }
      if (numberSearchCriteria?.ne) {
        searchParams.set(key + "[ne]", numberSearchCriteria.ne);
      } else {
        searchParams.delete(key + "[ne]");
      }
    }
  });

  return searchParams;
}

export function createFilterHelper<F>() {
  return {
    dateRange: (name: ObjectDotNotation<F> & string, label: string) => {
      return {
        type: "dateRange" as const,
        name,
        label,
      };
    },
    select: (
      name: ObjectDotNotation<F> & string,
      label: string,
      options: { value: string; name: string }[],
    ) => {
      return {
        type: "select" as const,
        name,
        label,
        options,
      };
    },
    projectSelect: (name: ObjectDotNotation<F> & string, label: string) => {
      return {
        type: "projectSelect" as const,
        name,
        label,
      };
    },
    organizationSelect: (
      name: ObjectDotNotation<F> & string,
      label: string,
      filters?: any,
    ) => {
      return {
        type: "organizationSelect" as const,
        name,
        label,
        filters,
      };
    },
    shareholderSelect: (
      name: ObjectDotNotation<F> & string,
      label: string,
      filters?: any,
    ) => {
      return {
        type: "shareholderSelect" as const,
        name,
        label,
        filters,
      };
    },
    circleSelect: (
      name: ObjectDotNotation<F> & string,
      label: string,
      filters?: any,
    ) => {
      return {
        type: "circleSelect" as const,
        name,
        label,
        filters,
      };
    },
    investmentSelect: (
      name: ObjectDotNotation<F> & string,
      label: string,
      userId: string,
      filters: any,
    ) => {
      return {
        type: "investmentSelect" as const,
        name,
        label,
        userId,
        filters,
      };
    },
  };
}
