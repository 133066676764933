import { getYear, isBefore, parseISO } from "date-fns";

import { TaxExemption } from "@tudigo-monorepo/core-tudigo-api-models";

import { TaxExemptionCSGFormFields } from "../../content/investor-profile-tax-exemptions-content";
import { getIRAvailabilityDateLimit } from "./dates";

export const getLatestTaxExemptionByEndDate = (
  taxExemptions: TaxExemption[],
) => {
  return taxExemptions.reduce((prev, current) => {
    if (!prev.id) {
      return current;
    }

    return new Date(current.validityEndDate) > new Date(prev.validityEndDate)
      ? current
      : prev;
  }, {} as TaxExemption);
};

export const checkIfLastSignedTaxExemptionIROutdated = (
  lastSignedTaxExemptionIR: TaxExemption | null,
) => {
  if (!lastSignedTaxExemptionIR) return true;

  const currentDate = new Date();
  const validityStartDateObj = parseISO(
    lastSignedTaxExemptionIR?.validityStartDate,
  );

  return getYear(currentDate) - getYear(validityStartDateObj) <= 0;
};

export const checkIfLastPendingTaxExemptionIRSingable = (
  taxExemption: TaxExemption | null,
) => {
  if (!taxExemption) return false;

  const { validityEndDate, status } = taxExemption;
  const validityEndObj = parseISO(validityEndDate);
  const currentDate = new Date();

  return (
    status === "draft" && getYear(currentDate) + 1 === getYear(validityEndObj)
  );
};

export const checkIfDateIsValidForSigningTaxExemptionIR = () => {
  const currentDate = new Date();

  return isBefore(currentDate, getIRAvailabilityDateLimit());
};

export const hasIRTaxExemptions = (taxExemptions: TaxExemption[]) => {
  return taxExemptions.some((item) => item.taxName === "IR");
};

export const hasCSGTaxExemption = (taxExemptions: TaxExemption[]) => {
  return !!taxExemptions.find((item) => item.taxName === "CSG");
};

export const isTaxExemptionCSGFormValid = (form: TaxExemptionCSGFormFields) => {
  const { since, state, socialProtectionFound, situation } = form;

  return Boolean(
    since &&
      state &&
      state.length > 0 &&
      socialProtectionFound &&
      socialProtectionFound.length > 0 &&
      situation,
  );
};
