import { useMemo } from "react";
import { useIntl } from "react-intl";

import { InvestorProfile } from "@tudigo-monorepo/core-tudigo-api-models";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { Title } from "@tudigo-monorepo/web-tudigo-components";

import { InvestmentCapacityCardsContent } from "../../../content/investment-capacity-cards-content";
import { InvestmentCapacityCollapseContent } from "../../../content/investment-capacity-collapse-content";
import { getInvestmentCapacity, getSummaries } from "./summary-helper";

export type InvestmentCapacitySummaryPageProps = {
  investorProfile: InvestorProfile;
};

export function InvestmentCapacitySummaryPage(
  props: InvestmentCapacitySummaryPageProps,
) {
  const { investorProfile } = props;
  const intl = useIntl();

  const sum = useMemo(() => getSummaries(investorProfile), [investorProfile]);

  return (
    <div className="investment-capacity-summary-page flex flex-col gap-y-8">
      <Title className="border-light-1 w-full border-b pb-4">
        {intl.formatMessage({
          id: "qualification.summary_page.investment_capacity.title",
        })}
      </Title>
      <InvestmentCapacityCardsContent
        investmentCapacity={getInvestmentCapacity(investorProfile)}
      />
      <InvestmentCapacityCollapseContent
        summaryItems={[
          {
            label: "Vos salaires nets /an",
            value: formatCurrency(sum.annualSalary * 100),
            type: "item",
          },
          {
            label: "Intérêts /an",
            value: formatCurrency(sum.interests * 100),
            type: "item",
          },
          {
            label: "Revenus fonciers /an",
            value: formatCurrency(sum.landIncome * 100),
            type: "item",
          },
          {
            label: "Dividendes /an",
            value: formatCurrency(sum.dividends * 100),
            type: "item",
          },
          {
            type: "separator",
          },
          {
            label: "Produits d’épargne",
            value: formatCurrency(sum.savingsProduct * 100),
            type: "item",
          },
          {
            label: "Titres cotés",
            value: formatCurrency(sum.listedSecurities * 100),
            type: "item",
          },
          {
            label: "Assurance vie et PER",
            value: formatCurrency(sum.lifeInsurance * 100),
            type: "item",
          },
          {
            label: "Titres non cotés",
            value: formatCurrency(sum.unlistedSecurities * 100),
            type: "item",
          },
          {
            label: "Crypto monnaie",
            value: formatCurrency(sum.cryptoCurrencies * 100),
            type: "item",
          },
          {
            label: "Investissements sur les 12 derniers mois",
            value: formatCurrency(sum.investLastTwelvesMonths * 100),
            type: "item",
          },
          {
            type: "separator",
          },
          {
            label: "Résidence principale",
            value: formatCurrency(sum.primaryResidence * 100),
            type: "item",
          },
          {
            label: "Investissements immobiliers",
            value: formatCurrency(sum.realEstate * 100),
            type: "item",
          },
          {
            label: "Autres",
            value: formatCurrency(sum.others * 100),
            type: "item",
          },
          {
            type: "separator",
          },
          {
            label: "Crédits immobilier",
            value: formatCurrency(sum.realEstateLoans * 100),
            type: "item",
          },
          {
            label: "Mensualités de crédits /an",
            value: formatCurrency(sum.monthlyLoansPayments * 100),
            type: "item",
          },
          {
            label: "Dépenses récurrentes /mois",
            value: formatCurrency(sum.recurrentMonthlyExpenses * 100),
            type: "item",
          },
        ]}
      />
    </div>
  );
}
