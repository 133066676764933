import { LoaderCircle } from "lucide-react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ButtonRebrand,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@tudigo-monorepo/web-tudigo-components";

import { useProjectNewsContext } from "../../../../shared-external/state/project-news.context";
import type { ProjectNewsFormValues } from "./project-news-form/form-schema";
import { ProjectNewsForm } from "./project-news-form/project-news-form";
import { useUpdateProjectNews } from "./use-update-project-news";

export function UpdateProjectNewsModal({
  isDialogOpen,
  setEditDialogOpen,
}: {
  isDialogOpen: boolean;
  setEditDialogOpen: (isOpen: boolean) => void;
}) {
  const { t } = useTranslation();

  const { updateProjectNews, isPending } = useUpdateProjectNews(() =>
    setEditDialogOpen(false),
  );

  const { news } = useProjectNewsContext();

  const formDefaultNews =
    news?.type === "document" ? { ...news, document: [news.document] } : news;

  return (
    <Dialog open={isDialogOpen} onOpenChange={setEditDialogOpen}>
      <DialogContent>
        <DialogTitle>
          {t("back_office.edit_project.project_news.update_news.title")}
        </DialogTitle>

        <ProjectNewsForm
          onSubmit={updateProjectNews}
          defaultValues={formDefaultNews as ProjectNewsFormValues}
        >
          <ButtonRebrand
            type="submit"
            disabled={isPending}
            className="mx-auto w-72 gap-x-2"
          >
            {isPending && <LoaderCircle className="h-5 w-5 animate-spin" />}
            {t(
              "back_office.edit_project.project_news.update_news.submit_button",
            )}
          </ButtonRebrand>
        </ProjectNewsForm>
      </DialogContent>
    </Dialog>
  );
}
