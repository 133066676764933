import { useState } from "react";

import {
  QualificationContent,
  QualificationFooter,
} from "../../components/qualification-intro-view";
import { ProjectHolderIntro } from "./project-holder-intro";
import { ProjectHolderIntroActions } from "./project-holder-intro-actions";
import { ProjectHolderDepositForm } from "./projet-holder-deposit-form";

export function QualificationProjectHolder() {
  const [showProjectHolderDepositForm, setShowProjectHolderDepositForm] =
    useState(false);

  return (
    <>
      <QualificationContent>
        {showProjectHolderDepositForm ? (
          <ProjectHolderDepositForm />
        ) : (
          <ProjectHolderIntro />
        )}
      </QualificationContent>

      <QualificationFooter>
        {!showProjectHolderDepositForm && (
          <ProjectHolderIntroActions
            onCreateProfile={() => {
              setShowProjectHolderDepositForm(true);
            }}
          />
        )}
      </QualificationFooter>
    </>
  );
}
