import { NavLink, useMatch } from "react-router-dom";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Chip } from "../chip";
import { ChipCount } from "../chip-count";
import { Icon } from "../icons/icon";
import { MenuLink } from "../menu-navigation";

type MenuLinkProps = {
  item: MenuLink;
  reducedDisplay: boolean;
  className?: string;
};

export function MenuNavigationLinkItem(props: MenuLinkProps) {
  const { item, reducedDisplay, className } = props;

  const isActive = useMatch({
    path: item.path,
    end: item.end ?? false,
  });

  return (
    <NavLink
      to={item.path}
      onClick={item.action}
      target={item?.target || "_self"}
      className={cn(
        "menu-navigation-item hover:border-accent-medium text-dark-2 m-0 flex min-h-[43px] items-center gap-3 rounded-lg border border-transparent py-2 transition-all",
        {
          "text-primary-rebrand bg-accent-super-light border-accent-medium":
            isActive,
          "w-full px-3": !reducedDisplay,
          "w-[38px] px-[7px]": reducedDisplay,
        },
        className,
      )}
    >
      {item.icon ? (
        <Icon
          name={item.icon}
          primaryColor={isActive ? themeColors.accent : themeColors["dark-2"]}
        />
      ) : null}

      {!reducedDisplay && (
        <>
          <p className="whitespace-nowrap opacity-100 transition-opacity duration-300 ease-in-out">
            {item.label}
          </p>
          {item.chip ? <Chip {...item.chip} /> : null}
          {item.chipCount ? <ChipCount {...item.chipCount} /> : null}
        </>
      )}
    </NavLink>
  );
}
