import { ProjectPresentation } from "../tabs/tab-1-presentation/project-presentation";
import { ProjectSimulator } from "../tabs/tab-2-simulator/project-simulator";
import { ProjectModalities } from "../tabs/tab-3-modalities/project-modalities";
import { ProjectQa } from "../tabs/tab-4-questions-answers/project-qa";
import { useProjectContext } from "./use-project-context";

export function useProjectPageRoutes() {
  const project = useProjectContext();

  if (!project) return [];

  const isProjectFinished = ["finished", "prefinished"].includes(
    project.status,
  );

  const isSimulatorVisible =
    project?.marketingSettings?.simulatorVisibility === "visible";

  return [
    {
      key: "presentation",
      enabled: true,
      path: "",
      label: "Présentation",
      component: ProjectPresentation,
    },
    {
      key: "simulator",
      enabled: !isProjectFinished && isSimulatorVisible,
      path: "simulator",
      label: "Simulateur",
      component: ProjectSimulator,
    },
    {
      key: "modalities",
      enabled: !isProjectFinished,
      path: "modalities",
      label: "Analyse et modalités",
      component: ProjectModalities,
    },
    {
      key: "qa",
      enabled: !isProjectFinished,
      path: "qa",
      label: "Questions et réponses",
      component: ProjectQa,
    },
  ].filter((route) => route.enabled);
}

/*
    {
      enabled: !isProjectFinished,
      path: "news",
      label: "Actualités",
      component: ProjectNews,
    },
 */
