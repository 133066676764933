import type { Todo } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import {
  Chip,
  Icon,
  ListElement,
} from "@tudigo-monorepo/web-tudigo-components";

export function UserValidateWmaInvitationListElement(props: { todo: Todo }) {
  const { todo } = props;

  if (!todo.subjectWmaCustomer || !todo.subjectWmaCustomer.wmaUser) {
    return null;
  }

  return (
    <ListElement
      className="bg-white"
      navigateTo={`/my-account/wma/invitations/${todo.subjectWmaCustomer.id}`}
      startElement={{
        img: { icon: { name: "UserChat" } },
        upperText: "Invitation en attente",
        lowerText: `${todo.subjectWmaCustomer.wmaUser.firstName} ${todo.subjectWmaCustomer.wmaUser.lastName}`,
        profileCardClassName: "min-w-[280px] flex justify-start px-3 py-2",
      }}
      middleElement={{ upperText: "Valider l’invitation de votre CGP" }}
      endElement={{
        render: () => {
          return (
            <div className="flex items-center gap-x-8">
              <Chip
                label="A valider"
                size="S"
                type="waiting"
                iconLeft="DotChip"
              />
              <Icon name="ChevronRight" primaryColor={themeColors["dark-2"]} />
            </div>
          );
        },
      }}
    />
  );
}
