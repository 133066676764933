import {
  QualificationContent,
  QualificationFooter,
} from "../../components/qualification-intro-view";
import { WmaActions } from "./wma-actions";
import { WmaIntro } from "./wma-intro";

export function QualificationWma() {
  return (
    <>
      <QualificationContent>
        <WmaIntro />
      </QualificationContent>

      <QualificationFooter>
        <WmaActions />
      </QualificationFooter>
    </>
  );
}
