import {
  QualificationContent,
  QualificationFooter,
} from "../../components/qualification-intro-view";
import { InvestorIntro } from "./investor-intro";
import { InvestorIntroActions } from "./investor-intro-actions";

export function QualificationInvestor() {
  return (
    <>
      <QualificationContent>
        <InvestorIntro />
      </QualificationContent>

      <QualificationFooter>
        <InvestorIntroActions />
      </QualificationFooter>
    </>
  );
}
