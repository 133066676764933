import { toast } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useDeleteProjectNewsMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectNewsContext } from "../state/project-news.context";

export const useDeleteProjectNews = () => {
  const { news } = useProjectNewsContext();
  const { t } = useTranslation();

  const { mutate: deleteProjectNews } = useDeleteProjectNewsMutation();

  const handleDeleteProjectNews = () => {
    if (!news) return;

    if (!news.id) {
      console.error("News id is missing");

      return;
    }

    const confirmDelete = window.confirm(
      t("back_office.edit_project.project_news.delete_news.confirm_message"),
    );
    if (confirmDelete) {
      deleteProjectNews(
        { newsId: news.id },
        {
          onSuccess: () => {
            toast((content) => (
              <Alert
                icon="Info"
                close={content.closeToast}
                variant="success"
                size="S"
                title={t(
                  "back_office.edit_project.project_news.delete_news.success_message",
                )}
              />
            ));
          },
          onError: (error) => {
            console.error("Error deleting news:", error);
          },
        },
      );
    }
  };

  return handleDeleteProjectNews;
};
