import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import {
  User,
  UserRole,
  WMAProfile,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";
import queryKeyFactory from "../../query-key-factory";

type CreateWMAProfileParams = {
  data: WMAProfile;
};

export const createWMAProfile = async (params: CreateWMAProfileParams) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.wmaProfiles.createWMAProfile(
    params.data,
  );

  return response.data;
};

const usersKeys = queryKeyFactory("users");

export const useCreateWMAProfileMutation = (
  options?: UseMutationOptions<
    TudigoResponse<WMAProfile>,
    TudigoError,
    CreateWMAProfileParams
  >,
) => {
  const queryClient = useQueryClient();

  const updateUserQueryData = (userId: User["id"], wmaProfile: WMAProfile) => {
    queryClient.setQueryData(usersKeys.detail(userId), (data: User) => {
      if (!data) return undefined;

      return {
        ...data,
        wmaProfile: wmaProfile,
        appRoles: [...data.appRoles, UserRole.WMA],
      };
    });
  };

  return useMutation({
    ...options,
    mutationFn: (params: CreateWMAProfileParams) => createWMAProfile(params),
    onSuccess: async (...props) => {
      const [data, params] = props;
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.WMA_PROFILES],
      });

      updateUserQueryData(params.data.id, data.data);

      options?.onSuccess?.(...props);
    },
  });
};
