import { get } from "lodash";

import { InvestorProfile } from "@tudigo-monorepo/core-tudigo-api-models";

export const getNumberValueOrZero = (value: any) => {
  return Number(value) ?? 0;
};
export const getAnnualIncome = (investorProfile: InvestorProfile) => {
  return getNumberValueOrZero(investorProfile?.financialSummary?.annualIncome);
};

export const getDept = (investorProfile: InvestorProfile) =>
  getNumberValueOrZero(
    investorProfile?.qualificationData?.financialSituation?.realEstateLoans,
  );

export const getLiquidAssets = (investorProfile: InvestorProfile) => {
  const financialSituation =
    investorProfile?.qualificationData?.financialSituation || {};

  return (
    getNumberValueOrZero(financialSituation.savingsProduct) +
    getNumberValueOrZero(financialSituation.listedSecurities) +
    getNumberValueOrZero(financialSituation.lifeInsurance) +
    getNumberValueOrZero(financialSituation.unlistedSecurities) +
    getNumberValueOrZero(financialSituation.cryptoCurrencies)
  );
};

export const getWealth = (investorProfile: InvestorProfile) => {
  const financialSituation =
    investorProfile?.qualificationData?.financialSituation || {};

  return (
    getNumberValueOrZero(financialSituation.primaryResidence) +
    getNumberValueOrZero(financialSituation.realEstate) +
    getNumberValueOrZero(financialSituation.others)
  );
};

export const getNetAssets = (investorProfile: InvestorProfile) => {
  return getWealth(investorProfile) - getDept(investorProfile);
};

export const getInvestmentCapacity = (investorProfile: InvestorProfile) => {
  return {
    annualIncome: getAnnualIncome(investorProfile),
    debt: getDept(investorProfile),
    liquidAssets: getLiquidAssets(investorProfile),
    monthlyIncome: parseInt((getAnnualIncome(investorProfile) / 12).toFixed(0)),
    netAssets: getNetAssets(investorProfile),
    wealth: getWealth(investorProfile),
  };
};

const summaryInfosDataPath = {
  annualIncome: "financialSummary.annualIncome",
  realEstateLoans: "qualificationData.financialSituation.realEstateLoans",
  annualSalary: "qualificationData.financialSituation.annualSalary",
  interests: "qualificationData.financialSituation.interests",
  landIncome: "qualificationData.financialSituation.landIncome",
  dividends: "qualificationData.financialSituation.dividends",
  savingsProduct: "qualificationData.financialSituation.savingsProduct",
  listedSecurities: "qualificationData.financialSituation.listedSecurities",
  lifeInsurance: "qualificationData.financialSituation.lifeInsurance",
  unlistedSecurities: "qualificationData.financialSituation.unlistedSecurities",
  cryptoCurrencies: "qualificationData.financialSituation.cryptoCurrencies",
  investLastTwelvesMonths:
    "qualificationData.financialSituation.investLastTwelvesMonths",
  primaryResidence: "qualificationData.financialSituation.primaryResidence",
  realEstate: "qualificationData.financialSituation.realEstate",
  others: "qualificationData.financialSituation.others",
  monthlyLoansPayments:
    "qualificationData.financialSituation.monthlyLoansPayments",
  recurrentMonthlyExpenses:
    "qualificationData.financialSituation.recurrentMonthlyExpenses",
};

type SummaryInfos = Record<keyof typeof summaryInfosDataPath, number>;

export const getSummaries = (
  investorProfile: InvestorProfile,
): SummaryInfos => {
  return Object.entries(summaryInfosDataPath).reduce((acc, [key, path]) => {
    acc[key as keyof typeof summaryInfosDataPath] = getNumberValueOrZero(
      get(investorProfile, path),
    );

    return acc;
  }, {} as SummaryInfos);
};
