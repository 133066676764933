import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { mixpanelTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

export function PublicRoute() {
  const location = useLocation();

  useEffect(() => {
    const pageName = location.pathname;
    const pageUrl = `${window.location.origin}${location.pathname}${location.search}`;

    mixpanelTrackEvent("ui.page.view", {
      page_name: pageName,
      page_url: pageUrl,
    });
  }, [location.pathname, location.search]);

  return <Outlet />;
}
