import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  PageEnum,
  pathsByPagesAndSpaces,
  SpaceEnum,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import {
  useCreateWMAProfileMutation,
  useMarkIntroAsDone,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

export function WmaActions() {
  const navigate = useNavigate();
  const {
    mutateAsync: createWMAProfile,
    isPending: isCreateWMAProfileLoading,
  } = useCreateWMAProfileMutation();

  const { mutateAsync: markIntroAsDone } = useMarkIntroAsDone();
  const { userIdOrMe: userId } = useUserBusiness();

  const [urlToRedirect, setUrlToRedirect] = React.useState<string | null>(null);
  useEffect(() => {
    if (urlToRedirect) {
      navigate(urlToRedirect, { replace: true });
    }
  }, [navigate, urlToRedirect]);

  const handleConfirm = () => {
    markIntroAsDone({ userId }).finally(() => {
      createWMAProfile({
        data: {
          id: userId,
          status: "enabled",
        },
      })
        .then((wmaProfileResponse) => {
          const organizationId = wmaProfileResponse.data?.organization?.id;
          const urlToRedirect = pathsByPagesAndSpaces[
            PageEnum.ORGANIZATION_KYC_FUNNEL
          ][SpaceEnum.WMA].replace(":organizationId", organizationId || "");
          setUrlToRedirect(urlToRedirect);
        })
        .catch(() => {
          setUrlToRedirect("/dispatch");
        });
    });
  };

  return (
    <div>
      <Button
        className="w-fit px-12"
        variant="primary"
        iconRight="ArrowRight"
        onClick={handleConfirm}
        disabled={isCreateWMAProfileLoading}
        label="Je demande la création de mon compte CGP"
      />
      <Button
        className="w-fit px-12 sm:hidden"
        variant="ghost"
        disabled={isCreateWMAProfileLoading}
        label="Quitter"
      />
    </div>
  );
}
