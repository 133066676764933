import {
  INVESTMENTS,
  PAYMENT_MANAGEMEN_SYSTEM,
} from "@tudigo-monorepo/core-tudigo-utils";
import {
  mixpanelTrackEvent,
  UI_INVESTMENT_CONFIRMATION_PAGE_VIEW,
  useTrack,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useInvestmentFunnelPageContext } from "../../../pages/investment-funnel-page";
import { FinalStepPaymentByCardSuccessfull } from "./parts/card/final-step-payment-by-card-successful";
import { FinalStepPeaPaymentProjectClosedWaitingPayment } from "./parts/pea/final-step-pea-payment-project-closed-waiting-payment";
import { FinalStepPeaPaymentWaitingProjectClosure } from "./parts/pea/final-step-pea-payment-waiting-project-closure";
import { ThankYouLayout } from "./parts/shared/thank-you-layout";
import { FinalStepWaitingWirePaymentContent } from "./parts/wire/final-step-waiting-wire-payment-content";
import { FinalStepWireReceivedContent } from "./parts/wire/final-step-wire-received-content";

export function FinalStepContent() {
  const { ongoingInvestment, project, dispatchToLastStepAvailable } =
    useInvestmentFunnelPageContext();

  useTrack(() => {
    mixpanelTrackEvent(UI_INVESTMENT_CONFIRMATION_PAGE_VIEW, {
      investmentStatus: ongoingInvestment?.globalStatus,
    });

    return true;
  }, []);

  if (!ongoingInvestment || !ongoingInvestment.paymentMethodType) {
    dispatchToLastStepAvailable();

    return;
  }

  if (
    ongoingInvestment.paymentMethodType ===
    PAYMENT_MANAGEMEN_SYSTEM.PAYMENT_METHOD_TYPE.WIRE
  ) {
    if (
      ongoingInvestment.globalStatus === INVESTMENTS.GLOBAL_STATUS.VALIDATED
    ) {
      return (
        <ThankYouLayout>
          <FinalStepWireReceivedContent />
        </ThankYouLayout>
      );
    }

    return <FinalStepWaitingWirePaymentContent />;
  }

  if (
    ongoingInvestment.paymentMethodType ===
    PAYMENT_MANAGEMEN_SYSTEM.PAYMENT_METHOD_TYPE.CARD
  ) {
    return (
      <ThankYouLayout>
        <FinalStepPaymentByCardSuccessfull />
      </ThankYouLayout>
    );
  }

  if (
    ongoingInvestment.paymentMethodType ===
      PAYMENT_MANAGEMEN_SYSTEM.PAYMENT_METHOD_TYPE.PEA ||
    ongoingInvestment.paymentMethodType ===
      PAYMENT_MANAGEMEN_SYSTEM.PAYMENT_METHOD_TYPE.PEA_PME
  ) {
    if (
      ongoingInvestment.globalStatus === INVESTMENTS.GLOBAL_STATUS.VALIDATED
    ) {
      return (
        <ThankYouLayout>
          <FinalStepWireReceivedContent />
        </ThankYouLayout>
      );
    }

    if (project.commitmentLetterSigningEnabled !== true) {
      return <FinalStepPeaPaymentWaitingProjectClosure />;
    } else {
      return <FinalStepPeaPaymentProjectClosedWaitingPayment />;
    }
  }

  return null;
}
