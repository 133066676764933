import {
  isBondsProject,
  isSharesProject,
  TaxExemptionMechanicsEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { useProjectContext } from "../../project-page/hooks/use-project-context";
import { BondsProjectModalities } from "../../project-page/tabs/tab-3-modalities/modalities-summary-card/bonds-project-modalities";
import { CommonProjectModalities } from "../../project-page/tabs/tab-3-modalities/modalities-summary-card/common-project-modalities";
import { SharesProjectModalities } from "../../project-page/tabs/tab-3-modalities/modalities-summary-card/shares-project-modalities";
import { ProjectInfoCardItem } from "./project-info-card-item";

export function ProjectModalitiesCard() {
  const project = useProjectContext();

  const { t } = useTranslation();

  if (!project) {
    return null;
  }

  const getPaymentMethods = () => {
    const { taxExemptionMechanics } = project;
    let methods = "Carte bancaire, virement";

    if (taxExemptionMechanics?.includes(TaxExemptionMechanicsEnum.PEA)) {
      methods += ", virement PEA";
    }
    if (taxExemptionMechanics?.includes(TaxExemptionMechanicsEnum.PEA_PME)) {
      methods += ", virement PEA-PME";
    }

    return methods;
  };

  return (
    <article className="border-light-1 rounded-md border p-6">
      <h4 className="font-montserrat text-primary mb-4 font-bold">
        Modalités d’investissement
      </h4>

      <ul className="flex flex-col gap-y-3">
        <ProjectInfoCardItem
          label="Instrument financier"
          value={t(project.mechanic)}
        />

        {isSharesProject(project) && (
          <SharesProjectModalities project={project} />
        )}

        {isBondsProject(project) && (
          <BondsProjectModalities project={project} />
        )}

        <CommonProjectModalities project={project} />

        <ProjectInfoCardItem
          label="Moyen de paiement :"
          value={getPaymentMethods()}
        />
      </ul>
    </article>
  );
}
