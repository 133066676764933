import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { ThinLayout, Title } from "@tudigo-monorepo/web-tudigo-components";

import { ProjectLimitedAccess } from "../../components/project-limited-access";
import { QuestionsAnswers } from "./questions-answers";

export function ProjectQa() {
  const { isQualifiedOrWma } = useUserBusiness();

  const { t } = useTranslation();

  if (!isQualifiedOrWma) {
    return (
      <ThinLayout>
        <Title className="border-b" level="h2">
          {t("projects.web_app.questions_answers.title.label")}
        </Title>
        <ProjectLimitedAccess />
      </ThinLayout>
    );
  }

  return <QuestionsAnswers />;
}
