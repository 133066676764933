import { useCallback } from "react";
import {
  useInstantSearch,
  useRefinementList,
  type RefinementListProps,
} from "react-instantsearch";

import { capitalizeFirstChar } from "@tudigo-monorepo/core-tudigo-utils";
import { Button, Checkbox } from "@tudigo-monorepo/web-tudigo-components";
import { mixpanelTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

interface CustomRefinementListProps extends RefinementListProps {
  showMore?: boolean;
  transformItem?: (item: { label: string } & any) => { label: string } & any;
}

export const CustomRefinementList = (props: CustomRefinementListProps) => {
  const { showMore, transformItem, ...rest } = props;

  const { items, refine, canToggleShowMore, isShowingMore, toggleShowMore } =
    useRefinementList({
      ...rest,
    });

  const { results } = useInstantSearch();

  const getLabel = (item: any) => {
    if (transformItem) {
      item = transformItem(item)!;

      return item.label;
    }

    return capitalizeFirstChar(item.label);
  };

  const handleCheckedChange = useCallback(
    (item: any, checked: boolean) => {
      refine(item.value);

      if (checked) {
        const startedProjectsCount = results?.hits?.filter(
          (project) => project.status === "started",
        ).length;

        mixpanelTrackEvent("ui.projects_listing_page.filtered", {
          filter_category: props.attribute,
          filter_option_selected: item.label,
          project_number_of_results: item.count,
          project_number_of_active_ones: startedProjectsCount,
        });
      }
    },
    [refine, results, props.attribute],
  );

  return (
    <>
      <ul className="flex flex-col gap-y-3">
        {items.map((item) => (
          <li key={item.value} className="flex items-center">
            <Checkbox
              id={item.label}
              className="mr-3"
              checked={item.isRefined}
              onCheckedChange={(checked) =>
                handleCheckedChange(item, !!checked)
              }
            />
            <label
              htmlFor={item.label}
              className="flex w-full items-center justify-between font-sans"
            >
              <p className="text-dark-1 cursor-pointer text-[13px]">
                {getLabel(item)}
              </p>
              <p className="bg-light-3 cursor-pointer rounded-[30px] px-2 text-[10px]">
                {item.count}
              </p>
            </label>
          </li>
        ))}
      </ul>

      {showMore && canToggleShowMore && (
        <Button
          className="m-auto min-w-44"
          label={isShowingMore ? "Afficher moins" : "Afficher plus"}
          variant="tertiary"
          iconRight={isShowingMore ? "ChevronUp" : "ChevronDown"}
          onClick={toggleShowMore}
        />
      )}
    </>
  );
};
