import { useState } from "react";

import { Organization, Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { Modal } from "@tudigo-monorepo/web-tudigo-components";

import { UserOrganizationAutocomplete } from "../../../shared/components/user-organizations-autocomplete";
import { useMyInvestmentPageContext } from "../hooks/use-my-investment-page-context";

export interface GenerateInvestmentAccountCertificateModalProps {
  open: boolean;
  isLoading: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (selectedInvestorId: string) => void;
}

export function GenerateInvestmentAccountCertificateModal(
  props: GenerateInvestmentAccountCertificateModalProps,
) {
  const { user } = useUserBusiness();
  const { investments } = useMyInvestmentPageContext();
  const { t } = useTranslation();

  const project: Project | undefined = investments[0].project ?? undefined;

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);

  const handleSubmit = () => {
    if (selectedOrganization) {
      props.onSubmit(selectedOrganization.id);
    }
  };

  if (!props.open) {
    return null;
  }

  return (
    <Modal
      title={t(
        "my-investments.my_investment_page.documents.generate_investment_account_certificate.label",
      )}
      className="min-h-[605px] w-[calc(100%-32px)] sm:max-w-[654px]"
      confirmLabel="Générer"
      isOpen={props.open}
      setIsOpen={() => props.setOpen(false)}
      confirmAction={handleSubmit}
      confirmButtonProps={{
        isLoading: props.isLoading,
        disabled: !selectedOrganization,
        variant: "primary",
      }}
    >
      <div className="flex w-full max-w-[484px] flex-col gap-y-4 py-4 md:px-8">
        <p className="font-montserrat text-primary text-xs">
          {t(
            "my-investments.my_investment_page.documents.generate_investment_account_certificate.modal.desc",
            {
              accountType: t(
                `my-investments.my_investment_page.documents.generate_investment_account_certificate.modal.desc.account_type.${project.mechanic}`,
              ),
            },
          )}
        </p>
        <UserOrganizationAutocomplete
          userId={user?.id ?? "me"}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          required
        />
      </div>
    </Modal>
  );
}
