import { BlockCarousel } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "../hooks/use-project-context";

export function BlockProjectCarousel() {
  const project = useProjectContext();

  if (!project) return null;

  const medias = project?.content?.medias;

  if (!medias) return null;

  return <BlockCarousel medias={medias} />;
}
