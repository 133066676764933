import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { SubscriptionPeriod } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../..";
import { getQueryListKey } from "../../query-key-factory";

type CircleSubscriptionPeriodssQuery = {
  fields?: string;
  limit?: number;
  offset?: number;
  sort_direction?: "ASC" | "DESC";
};

const defaultFields =
  "id,subscription,customer,price,current_period_start_date,current_period_end_date,cancellation_date,club";

export const getCircleSubscriptionPeriods = async ({
  circleId,
  query,
}: {
  circleId: string;
  query: CircleSubscriptionPeriodssQuery;
}) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getCircleSubscriptionPeriods(
    circleId,
    query,
  );

  return response.data;
};

export const useGetCircleSubscriptionPeriods = (
  params: CollectionRequestParams<
    { circleId: string },
    CircleSubscriptionPeriodssQuery
  >,
  options?: Partial<
    UseQueryOptions<TudigoResponse<SubscriptionPeriod[]>, TudigoError>
  >,
) => {
  const { pagination, fields = defaultFields, filters, pathParams } = params;

  return useQuery({
    queryKey: getQueryListKey({
      key: `${QUERY_KEYS.CIRCLE_SUBSCRIPTION_PERIODS}-${pathParams?.circleId}`,
      filters,
    }),
    queryFn: () =>
      getCircleSubscriptionPeriods({
        circleId: pathParams?.circleId ?? "",
        query: {
          ...filters,
          fields,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    ...options,
  });
};
