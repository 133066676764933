import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { UserInvestmentFeedFilters } from "@tudigo-monorepo/web-tudigo-api-client";
import { TabButtons } from "@tudigo-monorepo/web-tudigo-components";

import { GenerateInvestmentAccountCertificateButton } from "../components/generate-investment-account-certificate-button";
import { InvestmentFeedList } from "../components/investment-feed-list";
import { useMyInvestmentPageContext } from "../hooks/use-my-investment-page-context";

export function InvestmentDocumentsContent() {
  const { project, userId, investments } = useMyInvestmentPageContext();

  const { filter: pathFilter } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<
    "all-documents-only" | "investment_documents" | "news"
  >("all-documents-only");
  const [requestFilter, setRequestFilter] =
    useState<UserInvestmentFeedFilters>(undefined);
  const [activeTabsButton, setActiveTabsButton] = useState<number>(0);

  useEffect(() => {
    if (pathFilter === "personal") {
      setFilter("investment_documents");
      setRequestFilter(undefined);
      setActiveTabsButton(1);
    } else if (pathFilter === "global") {
      setFilter("news");
      setRequestFilter({ type: "document" });
      setActiveTabsButton(2);
    } else {
      setFilter("all-documents-only");
      setRequestFilter(undefined);
      setActiveTabsButton(0);
    }
  }, [pathFilter]);

  return (
    <div className="m-6">
      <div className="tab-filter flex flex-row items-center justify-between  py-6">
        <h3 className="text-primary mb-6 font-sans text-lg  font-bold leading-6">
          {t("my-investments.my_investment_page.documents.all_documents.title")}
        </h3>

        <div className="flex items-center gap-3">
          {investments.length > 0 && (
            <GenerateInvestmentAccountCertificateButton />
          )}
          <TabButtons
            className="w-[258px]"
            activeButtonIndex={activeTabsButton}
            buttons={[
              {
                text: t(
                  "my-investments.my_investment_page.documents.all_documents.tab-buttons.all",
                ),
                callback: () => {
                  navigate(
                    `/my-account/my-investments/projects/${project.id}/documents/all`,
                  );
                },
              },
              {
                text: t(
                  "my-investments.my_investment_page.documents.all_documents.tab-buttons.personal",
                ),
                callback: () => {
                  navigate(
                    `/my-account/my-investments/projects/${project.id}/documents/personal`,
                  );
                },
              },
              {
                text: t(
                  "my-investments.my_investment_page.documents.all_documents.tab-buttons.global",
                ),
                callback: () => {
                  navigate(
                    `/my-account/my-investments/projects/${project.id}/documents/global`,
                  );
                },
              },
            ]}
          />
        </div>
      </div>

      <InvestmentFeedList
        project={project}
        userId={userId}
        filter={filter}
        requestFilters={requestFilter}
      />
    </div>
  );
}
