import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { WMACustomer } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../../index";
import { getQueryListKey } from "../../query-key-factory";

type GetWmaCustomersByWmaUserIdParams = CollectionRequestParams<
  {
    wmaUserId: string;
    query?: {
      [key: string]: unknown;
    };
  },
  WmaCustomersFilters
>;

export type WmaCustomersFilters = {
  status?: WMACustomer["status"];
};

export const getWmaCustomersByWmaUserId = async (
  params: GetWmaCustomersByWmaUserIdParams,
) => {
  const apiClient = getApiClient();
  const { pagination, fields, filters } = params;

  const response =
    await apiClient.apiV1.wmaCustomers.getWMACustomersByWmaUserId(
      params.pathParams?.wmaUserId ?? "",
      {
        ...filters,
        fields: fields,
        offset: pagination?.offset,
        limit: pagination?.limit,
      },
    );

  return response.data;
};

export const useGetWmaCustomersByWmaUserId = (
  params: GetWmaCustomersByWmaUserIdParams,
  options?: UseQueryOptions<TudigoResponse<WMACustomer[]>, TudigoError>,
) =>
  useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.WMA_CUSTOMERS,
      fields: params?.fields,
      filters: params?.filters,
    }),
    queryFn: () => getWmaCustomersByWmaUserId(params),
    ...options,
  });
