import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { ChipCountProps } from "@tudigo-monorepo/web-tudigo-components";

import { ConnectedNavigation } from "./use-connected-navigation";

export const useWmaInvitationBadgeProps = (): ChipCountProps | undefined => {
  const { user } = useUserBusiness();

  return user?.pendingWmaInvitations?.length
    ? {
        variant: "secondary",
        label: user?.pendingWmaInvitations?.length,
        size: "S",
      }
    : undefined;
};

export const useInvestorNavigation = (...args: any[]): ConnectedNavigation => {
  const wmaInvitationChipsCount = useWmaInvitationBadgeProps();

  return {
    type: UserRole.INVESTOR,
    items: [
      { type: "section", label: "Gerer" },
      {
        type: "link",
        label: "Tableau de bord",
        path: "/my-account/dashboard",
        icon: "Dashboard",
      },
      {
        type: "link",
        label: "Investissements",
        path: "/my-account/my-investments/list/all",
        icon: "Invest",
      },
      {
        type: "link",
        label: "Intérêts",
        path: "/my-account/my-repayments",
        icon: "Money",
      },
      {
        type: "link",
        label: "Clubs",
        path: "/my-account/clubs",
        icon: "Group",
      },
      {
        type: "link",
        label: "Mon CGP",
        path: "/my-account/wma",
        icon: "UserChat",
        chipCount: wmaInvitationChipsCount,
      },
      { type: "section", label: "Investir" },
      {
        type: "link",
        label: "Opportunités",
        path: "/",
        icon: "List",
        end: true,
      },
      { type: "section", label: "Support" },
      {
        type: "link",
        label: "FAQ",
        path: "https://aide.tudigo.co/fr",
        icon: "Faq",
        target: "_blank",
      },
    ],
  };
};
