import { useMemo } from "react";

import {
  isFinishedProject,
  isSharesProject,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { useMyInvestmentPageContext } from "./use-my-investment-page-context";

export const useCheckCertificateGenerationAvailability = () => {
  const requirements = useCheckCertificateGenerationRequirements();
  const canGenerate = requirements.map(({ ok }) => ok).every(Boolean);
  const reasons = canGenerate
    ? null
    : requirements.filter(({ ok }) => !ok).map(({ error }) => error);

  return { canGenerate, reasons };
};

function useCheckCertificateGenerationRequirements() {
  const { investments, project } = useMyInvestmentPageContext();
  const { t } = useTranslation();
  const investment = investments[0] ?? undefined;

  const requirements = useMemo(
    () => [
      {
        ok: !!investment,
        error: t(
          "my-investments.my_investment_page.documents.generate_investment_account_certificate.tooltip.no_investment",
        ),
      },
      {
        ok: investment.globalStatus === "validated",
        error: t(
          "my-investments.my_investment_page.documents.generate_investment_account_certificate.tooltip.invalid_investment_status",
        ),
      },
      {
        ok: !!project,
        error: t(
          "my-investments.my_investment_page.documents.generate_investment_account_certificate.tooltip.no_project",
        ),
      },
      {
        ok: isFinishedProject(project),
        error: t(
          "my-investments.my_investment_page.documents.generate_investment_account_certificate.tooltip.invalid_project_status",
        ),
      },
      {
        ok: !!project?.holdingFundsTransferDate,
        error: t(
          "my-investments.my_investment_page.documents.generate_investment_account_certificate.tooltip.missing_holding_funds_transfer_date",
        ),
      },
      {
        ok: isSharesProject(project)
          ? !!project?.sharesMechanicSettings?.capitalIncreaseDate
          : !!project?.bondsMechanicSettings?.bondLoanDate,
        error: isSharesProject(project)
          ? t(
              "my-investments.my_investment_page.documents.generate_investment_account_certificate.tooltip.missing_capital_increase_date",
            )
          : t(
              "my-investments.my_investment_page.documents.generate_investment_account_certificate.tooltip.missing_bond_loan_date",
            ),
      },
    ],
    [investment, project, t],
  );

  return requirements;
}
