import { Consent } from "../contracts";
import { Document } from "../documents";
import { EquitySavingPlan } from "../equity-saving-plan/models";
import { News } from "../news";
import { Organization } from "../organizations";
import { Project, ProjectDocument } from "../projects";
import { SignatureRequest } from "../signatures";
import { User } from "../users";
import { WMAInvestment } from "../wma/models";

export enum PaymentMethodType {
  WIRE = "wire",
  CARD = "card",
  PEA = "pea",
  PEA_PME = "pea_pme",
  CHECK = "check",
  MANUAL = "manual",
  NONE = "none",
}

export enum CancellationDetailType {
  DUNNING = "dunning",
  INTERNAL = "internal",
  USER_REQUEST = "user_request",
}

export enum CancellationDetailReasonType {
  PAYMENT_METHOD_UPDATE = "payment_method_update",
  AMOUNT_UPDATE = "amount_update",
  PROJECT_UPDATE = "project_update",
  ACCOUNT_UPDATE = "account_update",
  TEST_TEAM_TUDIGO = "test_team_tudigo",
  TEST_INVESTOR = "test_investor",
  PROJECT_FUNDRAISING_FAILURE = "project_fundraising_failure",
  DUPLICATE = "duplicate",
  LEMONWAY = "lemonway",
  CONFLICT_OF_INTEREST = "conflict_of_interest",
  NO_PAYMENT_METHOD = "no_payment_method",
  UNREACHABLE = "unreachable",
  CHANGE_OF_MIND = "change_of_mind",
  LACK_OF_FUNDS = "lack_of_funds",
  OUT_OF_TIME = "out_of_time",
  PEA_PROCESS = "pea_process",
  CEILING_REACHED = "ceiling_reached",
  DOES_NOT_WANT_TO_JOIN_CLUB = "does_not_want_to_join_club",
  PEA_MINIMUM = "pea_minimum",
  COLLECT_PRE_FINISHED = "collect_pre_finished",
}

type PaymentIntent = {
  id: string;
  token: string;
  paymentUrl: string;
  status: string;
  amount: number;
};

export type InvestmentGlobalStatus =
  | "validated"
  | "cancelled"
  | "transfer_funds_failed"
  | "waiting_for_payment"
  | "waiting_for_signature";

export type InvestmentPaymentStatus =
  | "intent"
  | "amount_received"
  | "payed"
  | "cancelled"
  | "failed"
  | "repayment";

export type InvestmentSubscriptionFormStatus =
  | "pending"
  | "signed"
  | "cancelled";

export interface Investment {
  id: string;
  project: Project;
  investorOrganization: Organization;
  globalStatus: InvestmentGlobalStatus;
  paymentStatus: InvestmentPaymentStatus;
  subscriptionFormStatus: InvestmentSubscriptionFormStatus;
  user?: User;
  currency: string;
  paymentMethodType: `${PaymentMethodType}` | null;
  paymentDate: string | null;
  totalAmountInCents: number;
  unitPriceInCents: number;
  quantity: number;
  subscriptionFormDocument: InvestmentDocument | null;
  signedSubscriptionFormDocument?: InvestmentDocument;
  commitmentLetterDocument: InvestmentDocument | null;
  signedCommitmentLetterDocument?: InvestmentDocument;
  archiveEspDocument?: InvestmentDocument;
  projectAgreementAndSecurityConsent: Consent | null;
  createdAt: string;
  updatedAt: string;
  wmaInvestment: Omit<WMAInvestment, "investment"> | null;
  latestPaymentIntent: PaymentIntent | null;
  equitySavingPlan?: EquitySavingPlan;
  cancelledAt?: string;
  isCancellable?: boolean;
}

export interface InvestmentFeed {
  id: string;
  refType: "news" | "investment_document" | "project_document";
  investmentDocument: InvestmentDocument | null;
  projectDocument: ProjectDocument | null;
  news: News | null;
  creationDate: string;
}

export interface InvestmentDocument {
  id: string;
  investment: Investment;
  type: "subscription_form" | "subscription_form_signed" | "title_deed";
  document: Document | null;
  signatureRequest: SignatureRequest | null;
  createdAt: string;
}

export interface InvestmentStatusChange {
  id: string;
  paymentStatus: Investment["paymentStatus"];
  subscriptionFormStatus: Investment["subscriptionFormStatus"];
  investment: Investment;
  author: User | null;
  comment?: string;
  createdAt: string;
  updatedAt: string;
}

export type CancellationDetail = {
  type: `${CancellationDetailType}`;
  reason: string;
  comment?: string;
};

export type CancelInvestmentRequest = {
  investment: {
    id: Investment["id"];
  };
  cancellationDetail: CancellationDetail;
};

export type BSRegenerationRequest = {
  comment?: string;
};

export type RetryInvestmentTransferRequest = {
  investment: {
    id: Investment["id"];
  };
};

export type ChangeInvestmentStatusRequest = {
  paymentStatus: Investment["paymentStatus"];
  comment?: string;
};
