export interface OpenBlobFileOptions {
  type?: string;
  target?: "_blank" | "_self";
}

export const openBlobFile = (blob: Blob, opts?: OpenBlobFileOptions) => {
  const blobFile = new Blob([blob], { type: opts?.type });

  const url = window.URL.createObjectURL(blobFile);

  window.open(url, opts?.target);
};
