import type { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { INVESTMENTS } from "@tudigo-monorepo/core-tudigo-utils";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import {
  checkCanFinalizeInvestment,
  getInvestmentPaymentMethodTypeTranslateKey,
} from "../utils/investment-utils";

export const useInvestmentMetricsCardFooterProps = (investment: Investment) => {
  const { isAdmin } = useUserBusiness();
  const { t } = useTranslation();

  if (investment.globalStatus === INVESTMENTS.GLOBAL_STATUS.VALIDATED) {
    return {
      text: `${t(getInvestmentPaymentMethodTypeTranslateKey(investment))} ${t(
        "my-investments.my_investment_page.metrics.investment_card.payment_method_information.paid_on",
      )} ${
        investment.paymentDate
          ? new Date(investment.paymentDate).toLocaleDateString()
          : "Non renseigné"
      }`,
    };
  }

  if (
    [
      INVESTMENTS.GLOBAL_STATUS.WAITING_FOR_SIGNATURE,
      INVESTMENTS.GLOBAL_STATUS.WAITING_FOR_PAYMENT,
    ].includes(investment.globalStatus)
  ) {
    return {
      text: `${t(
        "investments.investment_metric_card.footer.text.payment_method.label",
      )} ${t(getInvestmentPaymentMethodTypeTranslateKey(investment))}`,
      cta:
        !isAdmin && checkCanFinalizeInvestment(investment)
          ? {
              text: t(
                "investments.investment_metric_card.footer.cta_text.finalize",
              ),
            }
          : undefined,
    };
  }
};
