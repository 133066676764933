import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";
import {
  mixpanelTrackEvent,
  useTrack,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useProjectContext } from "../../hooks/use-project-context";
import { SimulatorWidget } from "../../tabs/tab-2-simulator/sidebar/simulator-widget";

export function SidebarProjectSimulator() {
  const project = useProjectContext();
  useTrack(() => {
    if (!project) return false;

    mixpanelTrackEvent("ui.project_simulation_view", {
      project_id: project?.id,
      project_simulation_currency: project?.currency,
      project_simulation_source: "Sidebar",
    });

    return true;
  }, [project]);

  return (
    <>
      <header className="mb-4 flex gap-3">
        <Icon
          name="Invest"
          primaryColor={themeColors.success}
          bgColor={themeColors["success-light"]}
        />
        <h2 className="text-primary font-brand">
          Simulez votre plus-value potentielle
        </h2>
      </header>
      {project && <SimulatorWidget />}
    </>
  );
}
