import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Comment } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../..";
import { getQueryListKey } from "../../query-key-factory";

type CommentsQuery = {
  fields?: string;
  limit?: number;
  offset?: number;
  sort_direction?: "ASC" | "DESC";
};

export const getCommentsByProjectId = async (
  projectId: string,
  query: CommentsQuery,
) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.comments.getCommentsByProjectId(
    projectId,
    query,
  );

  return response.data;
};

export const useGetCommentsByProjectIdQuery = (
  params: CollectionRequestParams<{ projectId: string }, CommentsQuery>,
  options?: UseQueryOptions<TudigoResponse<Comment[]>, TudigoError>,
) => {
  const { pagination, fields, filters, pathParams } = params;

  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.COMMENTS,
      filters,
    }),
    queryFn: () =>
      getCommentsByProjectId(pathParams?.projectId ?? "", {
        ...filters,
        fields: fields,
        offset: pagination?.offset,
        limit: pagination?.limit,
      }),
    ...options,
  });
};
