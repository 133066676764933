import { toast } from "react-toastify";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useCreateProjectHolderProfile,
  useMarkIntroAsDone,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { Alert, Button } from "@tudigo-monorepo/web-tudigo-components";

import { useQualificationIntro } from "../../hooks/useQualificationIntro";

type ProjectHolderIntroActionsProps = {
  onCreateProfile?: () => void;
};

export const ProjectHolderIntroActions = (
  props: ProjectHolderIntroActionsProps,
) => {
  const { onCreateProfile } = props;
  const { userIdOrMe } = useUserBusiness();

  const { setShowExitDialog } = useQualificationIntro();

  const handleError = () => {
    toast((content) => (
      <Alert
        close={content.closeToast}
        icon="Error"
        variant="error"
        title="Erreur lors de la création du compte"
        description="Le compte Porteur de projet n'a pas pu être créé"
      />
    ));
  };

  const { mutate: markIntroDone, isPending: isMarkIntroDoneLoading } =
    useMarkIntroAsDone({
      onSuccess: onCreateProfile,
      onError: handleError,
    });

  const {
    mutate: createProjectHolderProfile,
    isPending: isCreateProjectHolderProfileLoading,
  } = useCreateProjectHolderProfile({
    onSuccess: () => markIntroDone({ userId: userIdOrMe }),
    onError: handleError,
  });

  const handleCreateProjectHolderProfile = () => {
    createProjectHolderProfile({
      data: {
        id: userIdOrMe,
        status: "enabled",
      },
    });
  };

  const isLoading =
    isCreateProjectHolderProfileLoading || isMarkIntroDoneLoading;

  return (
    <>
      <Button
        onClick={handleCreateProjectHolderProfile}
        className="w-fit px-12"
        variant="primary"
        iconRight="ArrowRight"
        label="Je demande la création de mon compte Porteur de projet"
        disabled={isLoading}
        isLoading={isLoading}
      />
      <Button
        onClick={() => setShowExitDialog(true)}
        className="w-fit px-12 sm:hidden"
        variant="ghost"
        label="Quitter"
        disabled={isLoading}
      />
    </>
  );
};
