import { useLocation } from "react-router-dom";

import type { Todo } from "@tudigo-monorepo/core-tudigo-api-models";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { OrganizationKycListElement } from "@tudigo-monorepo/web-feature-organizations";

import { UserPayInvestmentListElement } from "./list-elements/user-pay-investment-list-element";
import { UserQualificationListElement } from "./list-elements/user-qualification-list-element";
import { UserSignInvestmentListElement } from "./list-elements/user-sign-investment-list-element";
import { UserValidateWmaInvitationListElement } from "./list-elements/user-validate-wma-invitation-list-element";

const UserTodo = ({ todo }: { todo: Todo }) => {
  const { currentPagePaths } = useSpaceBusiness();
  const location = useLocation();

  switch (todo.type) {
    case "investment_to_sign":
      return (
        <UserSignInvestmentListElement investment={todo.subjectInvestment} />
      );

    case "investment_to_pay":
      return (
        <UserPayInvestmentListElement investment={todo.subjectInvestment} />
      );

    case "user_qualification":
      return <UserQualificationListElement />;

    case "organization_kyc": {
      if (!todo.subjectOrganization) {
        return null;
      }

      return (
        <OrganizationKycListElement
          organization={todo.subjectOrganization}
          onClick={() => {
            localStorage.setItem("target-url", location.pathname);
          }}
          navigateTo={currentPagePaths[
            PageEnum.ORGANIZATION_KYC_FUNNEL
          ].replace(":organizationId", todo.subjectOrganization.id)}
        />
      );
    }

    case "pending_wma_invitation":
      return <UserValidateWmaInvitationListElement todo={todo} />;

    default:
      return null;
  }
};

export { UserTodo };
