import { ReactNode } from "react";

import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@tudigo-monorepo/web-tudigo-components";

import { useQualificationIntro } from "../hooks/useQualificationIntro";

export function QualificationContent({ children }: { children: ReactNode }) {
  return (
    <div className="grid grid-cols-4 overflow-y-auto px-4 pb-[160px] pt-4 sm:grid-cols-12 sm:px-0 sm:pb-[97px] sm:pt-8">
      <div className="col-span-4 flex flex-col gap-8 sm:col-span-6 sm:col-start-4">
        {children}
      </div>
    </div>
  );
}

export function QualificationFooter({ children }: { children: ReactNode }) {
  return (
    <div className="border-light-1 fixed bottom-0 left-0 z-20 flex w-full flex-col items-center justify-center gap-4 border-t bg-white px-6 py-5 sm:flex-row sm:py-8">
      {children}
    </div>
  );
}

export function QualificationRoleSwitcher() {
  const { selectedProfile, setSelectedProfile } = useQualificationIntro();

  return (
    <ToggleGroup
      type="single"
      variant="outline"
      value={selectedProfile}
      onValueChange={(value) => {
        if (!value) return;
        setSelectedProfile(value as UserRole);
      }}
    >
      <ToggleGroupItem value={UserRole.INVESTOR} aria-label="Toggle bold">
        Investisseur
      </ToggleGroupItem>
      <ToggleGroupItem value={UserRole.WMA} aria-label="Toggle italic">
        CGP
      </ToggleGroupItem>
      <ToggleGroupItem
        value={UserRole.PROJECT_HOLDER}
        aria-label="Toggle underline"
      >
        Porteur de projet
      </ToggleGroupItem>
    </ToggleGroup>
  );
}
