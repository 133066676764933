import React from "react";
import { useNavigate } from "react-router-dom";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useMarkIntroAsDone } from "@tudigo-monorepo/web-tudigo-api-client";
import { Modal } from "@tudigo-monorepo/web-tudigo-components";

import { useQualificationIntro } from "../hooks/useQualificationIntro";

export const QualificationIntroExitConfirmationModal = () => {
  const { showExitDialog, setShowExitDialog } = useQualificationIntro();

  const navigate = useNavigate();
  const { userIdOrMe: userId } = useUserBusiness();

  const { mutate: markIntroDone } = useMarkIntroAsDone({
    onSuccess: async () => {
      navigate("/dispatch", { replace: true });
    },
  });

  const handleExitQualification = () => markIntroDone({ userId });

  return (
    <Modal
      title="Confirmation"
      content={
        <div>
          <h6 className="font-montserrat text-lg font-bold leading-6">
            Dernière ligne droite !
          </h6>
          <div className="font-montserrat text-[12px] font-normal leading-4">
            Vous pourrez compléter votre profil plus tard depuis votre espace
            “mon compte” mais vous devez impérativement valider votre profil
            investisseur pour :
            <ul className="mt-3 list-inside list-disc">
              <li>
                accès aux informations complètes concernant les opportunités
                d’investissement
              </li>
              <li>investir sur un projet</li>
            </ul>
          </div>
        </div>
      }
      negativeLabel="Je souhaite quitter tout de même"
      negativeAction={handleExitQualification}
      negativeButtonProps={{
        variant: "ghost",
      }}
      confirmAction={() => {
        setShowExitDialog(false);
      }}
      confirmLabel="Annuler et continuer"
      isOpen={showExitDialog}
      setIsOpen={setShowExitDialog}
      hideCrossButton
    />
  );
};
