import { useState } from "react";
import { EllipsisVertical, Pen, Trash } from "lucide-react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ButtonRebrand,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@tudigo-monorepo/web-tudigo-components";

import { UpdateProjectNewsModal } from "../../../pages/edit-project-page/tabs/tab-7-news/update-project-news-modal";
import { useDeleteProjectNews } from "../../hooks/use-delete-project-news";

export function ProjectNewsItemMenu() {
  const handleDeleteProjectNews = useDeleteProjectNews();
  const { t } = useTranslation();

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  return (
    <>
      <UpdateProjectNewsModal
        isDialogOpen={showUpdateModal}
        setEditDialogOpen={setShowUpdateModal}
      />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <ButtonRebrand variant="ghost">
            <EllipsisVertical className="h-5 w-5" />
          </ButtonRebrand>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="left">
          <DropdownMenuItem onClick={() => setShowUpdateModal(true)}>
            <Pen className="h-5 w-5" />
            {t("users.back_office.user_list.table.row.actions.update")}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDeleteProjectNews}>
            <Trash className="h-5 w-5" />
            {t("users.back_office.user_list.table.row.actions.delete")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
