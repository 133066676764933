import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetUserTodosQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { ListElementSkeleton } from "@tudigo-monorepo/web-tudigo-components";

import { UserTodo } from "../../components/user-todo";

const todoFields =
  "id,type,status,subject_user,subject_club_member.fields(id,club.fields(id)),doer_club_member,subject_wma_customer,subject_organization.fields(id,logo,name,persons,type,internal_kyc_status),subject_investment,created_at";

export function UserTodoList() {
  const { t } = useTranslation();
  const { user } = useUserBusiness();
  const userId = user?.id ?? "me";

  const { data: todos, isLoading } = useGetUserTodosQuery({
    pathParams: { userId },
    filters: { status: "todo" },
    pagination: { limit: 1000 },
    fields: todoFields,
  });

  if (!isLoading && (!todos || todos?.data?.length === 0)) {
    return null;
  }

  return (
    <section className="m-6 pt-6">
      <h3 className="text-primary mb-6 font-sans text-lg font-bold">
        {t("dashboard.user_todo_list.title")}
      </h3>

      {isLoading && (
        <ul className="flex flex-col gap-y-6">
          {new Array(3).fill(null).map((_, index) => (
            <ListElementSkeleton key={index} />
          ))}
        </ul>
      )}

      {!isLoading && (
        <ul className="flex flex-col gap-y-6">
          {todos?.data?.map((todo) => <UserTodo key={todo.id} todo={todo} />)}
        </ul>
      )}
    </section>
  );
}
