import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import { OrganizationBankAccount } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ORGANIZATIONS, PMS_FILES } from "@tudigo-monorepo/core-tudigo-utils";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  FormContextProvider,
  getSmartFormComponents,
} from "@tudigo-monorepo/web-feature-form";
import { useCreateOrganizationBankAccountMutation } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  PageHead,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";
import {
  mixpanelTrackEvent,
  useTrack,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useKYCContext } from "../kyc-page";
import { checkIfBankAccountRequirementsAreMet } from "../utils/kyc-documents-utils";

export function KycBankAccountInformation() {
  const { t, tNode } = useTranslation();
  const { organization, organizationQueryKey } = useKYCContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { currentPagePaths } = useSpaceBusiness();
  const kycPagePath = currentPagePaths[
    PageEnum.ORGANIZATION_KYC_FUNNEL
  ].replace(":organizationId", organization.id);

  const { SmartPdfField, SmartTextField } =
    getSmartFormComponents<OrganizationBankAccount>();
  const [updateError, setUpdateError] = useState<TudigoError | null>(null);

  const [
    stepBankAccountInformationFormData,
    setStepBankAccountInformationFormData,
  ] = useState<OrganizationBankAccount>(
    organization.organizationBankAccounts[0] ?? {
      bankAccountNumber: "",
      holderName: "",
      document: {
        base64: null,
        originalFilename: null,
        mimeTypeExtension: null,
      },
    },
  );

  useTrack(() => {
    mixpanelTrackEvent("ui.kyc_step_bank_account.started", {
      kyc_step_name: "Bank account",
    });

    return true;
  }, []);

  const {
    mutateAsync: createBankAccount,
    isPending: createBankAccountIsPending,
  } = useCreateOrganizationBankAccountMutation({
    onSuccess: async () => {
      mixpanelTrackEvent("ui.kyc_step_bank_account.completed", {
        kyc_step_name: "Bank account",
      });
      await queryClient.invalidateQueries({
        queryKey: organizationQueryKey,
      });
      toast((content) => {
        return (
          <Alert
            icon="Checked"
            variant="success"
            size="S"
            close={content.closeToast}
            title={t(
              "kyc_funnel.step_bank_account_information.alert_on_success.title",
            )}
          />
        );
      });
      navigate(`${kycPagePath}/documents`, {
        replace: true,
      });
    },
    onError: (err) => {
      setUpdateError(err);
    },
  });

  const handleSubmit = () => {
    if (
      stepBankAccountInformationFormData ===
      organization.organizationBankAccounts[0]
    ) {
      navigate(`${kycPagePath}/documents`, {
        replace: true,
      });

      return;
    }

    const formatedRequestCreateBankAccount = {
      organization: {
        id: organization.id,
      },
      bankAccountNumber: stepBankAccountInformationFormData.bankAccountNumber,
      holderName: stepBankAccountInformationFormData.holderName,
      document: {
        base64: stepBankAccountInformationFormData.document.base64,
        originalFilename:
          stepBankAccountInformationFormData.document.originalFilename,
        mimeTypeExtension:
          stepBankAccountInformationFormData.document.mimeTypeExtension,
      },
    };

    createBankAccount(formatedRequestCreateBankAccount);
  };

  const bankAccountFileIsRejected =
    stepBankAccountInformationFormData.pmsFile?.status ===
    PMS_FILES.STATUS.REJECTED;

  const bankAccountFileIsWaitingApproval =
    stepBankAccountInformationFormData.pmsFile?.status ===
    PMS_FILES.STATUS.WAITING_APPROVAL;

  return (
    <div>
      <PageHead
        chipsTop={[
          {
            label:
              organization.type === ORGANIZATIONS.TYPE.COMPANY
                ? t(
                    "kyc_funnel.step_bank_account_information.page.chip.label.company",
                  )
                : t(
                    "kyc_funnel.step_bank_account_information.page.chip.label.individual",
                  ),
          },
          {
            label: organization.name,
            type: "info",
          },
        ]}
        title={t("kyc_funnel.step_bank_account_information.page.title")}
        withEndLine
      />
      <div className="m-6 flex h-full flex-col justify-between gap-6">
        <Alert
          variant="info"
          icon="Info"
          size="F"
          description={tNode(
            "kyc_funnel.step_bank_account_information.alert.content",
          )}
        />
        <div>
          <Title level="h3" h3Brand="brand-2">
            {t("kyc_funnel.step_bank_account_information.section.title")}
          </Title>
          <div className="my-4 rounded-lg border px-10 py-8">
            <FormContextProvider
              error={updateError}
              formData={stepBankAccountInformationFormData}
              setFormData={setStepBankAccountInformationFormData}
            >
              <div className="flex flex-col gap-4">
                <SmartTextField
                  name="bankAccountNumber"
                  label={t(
                    "kyc_funnel.step_bank_account_information.form.label.iban",
                  )}
                  required
                  placeholder={t(
                    "kyc_funnel.step_bank_account_information.form.placeholder.iban",
                  )}
                />
                <SmartTextField
                  name="holderName"
                  label={t(
                    "kyc_funnel.step_bank_account_information.form.label.holder_name",
                  )}
                  required
                  placeholder={t(
                    "kyc_funnel.step_bank_account_information.form.placeholder.holder_name",
                  )}
                />
                <SmartPdfField
                  name="document"
                  label={t(
                    "kyc_funnel.step_bank_account_information.form.label.document",
                  )}
                  required
                  disabled={bankAccountFileIsWaitingApproval}
                  errors={
                    bankAccountFileIsRejected &&
                    stepBankAccountInformationFormData.document.base64 ===
                      undefined
                      ? [t("kyc_funnel.document_status.rejected")]
                      : undefined
                  }
                />
              </div>
            </FormContextProvider>
          </div>
          <Typography variant="body1">
            {t(
              "kyc_funnel.step_bank_account_information.section.legal_message",
            )}
          </Typography>
        </div>
      </div>
      <div className="border-light-1 fixed bottom-0 left-0 z-20 flex h-[80px] w-full items-center justify-center gap-x-4 border-t bg-white px-6 sm:h-[104px]">
        <Button
          isLoading={createBankAccountIsPending}
          disabled={
            !checkIfBankAccountRequirementsAreMet(
              stepBankAccountInformationFormData,
            )
          }
          className="w-fit px-12"
          variant="primary"
          iconRight="ArrowRight"
          label={t("kyc_funnel.form.button.next.label")}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
