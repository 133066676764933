import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  CollectionRequestParams,
  getApiClient,
  getQueryListKey,
  InvestmentsFilters,
} from "../../../";

type GetUserInvestmentsParams = CollectionRequestParams<
  { userId: string },
  InvestmentsFilters
>;
export const getUserInvestments = async (params: GetUserInvestmentsParams) => {
  const apiClient = getApiClient();
  const { pagination, fields, filters } = params;

  const response = await apiClient.apiV1.investments.getUserInvestments(
    params.pathParams?.userId ?? "",
    {
      ...filters,
      fields: fields,
      offset: pagination?.offset,
      limit: pagination?.limit,
    },
  );

  return response.data;
};

export const useGetUserInvestments = (
  params: GetUserInvestmentsParams,
  options?: UseQueryOptions<TudigoResponse<Investment[]>, TudigoError>,
) => {
  const userInvestmentsQueryKey = getQueryListKey({
    key: QUERY_KEYS.INVESTMENTS,
    fields: params?.fields,
    filters: { userId: params.pathParams?.userId, ...params?.filters },
  });

  return useQuery({
    queryKey: userInvestmentsQueryKey,
    queryFn: () => getUserInvestments(params),
    ...options,
  });
};
