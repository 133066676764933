import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetClubMember } from "@tudigo-monorepo/web-tudigo-api-client";
import { Breadcrumbs, Chip } from "@tudigo-monorepo/web-tudigo-components";

import { ClubMemberTodoList } from "./components/club-member-todo-list";
import { MyMembershipFollowingContent } from "./components/my-membership-following-content";
import { MyMembershipPremiumContent } from "./components/my-membership-premium-content";

export const InvestorMyMembershipPage = () => {
  const navigate = useNavigate();
  const clubId = useParams().circleId as string;
  const memberId = useParams().membershipId as string;
  const { t } = useTranslation();
  const clubMemberFields =
    "id,club.fields(id),todos,user.fields(investor_profile,first_name,last_name,created_at),statistics,investments,status,subscription_periods";
  const { data: member } = useGetClubMember({
    clubId,
    clubMemberId: memberId,
    query: {
      fields: clubMemberFields,
    },
  });

  const clubMember = member?.data;

  useEffect(() => {
    if (clubMember && clubMember.status === "unfollowed") {
      navigate(`/my-account/circles/`);
    }
  }, [clubMember]);

  if (!clubMember) {
    return <div>Club member not found</div>;
  }

  const getPremiumStatus = () => {
    if (clubMember.status === "following") {
      return;
    }

    const chipType = {
      draft: "warning",
      active: "success",
      deleted: "error",
    } as const;

    const chipLabel: Record<keyof typeof chipType, string> = {
      draft: t("space_investor.my_circles.list.columns.premium_status.draft"),
      active: t("space_investor.my_circles.list.columns.premium_status.active"),
      deleted: t(
        "space_investor.my_circles.list.columns.premium_status.deleted",
      ),
    };

    const status = clubMember.status as keyof typeof chipType;

    return (
      <Chip
        type={chipType[status]}
        iconLeft="DotChip"
        label={chipLabel[status]}
      />
    );
  };

  return (
    <>
      <div className="border-light-1 mx-6 flex flex-col gap-6 border-b py-4">
        <Breadcrumbs
          levels={[
            {
              url: `/my-account/circles/`,
              iconName: "Group",
            },
          ]}
        />
        <div className="flex gap-2">
          <Chip
            label={t(`club_member.status.${clubMember.status}`)}
            type="secondary"
          />
          {getPremiumStatus()}
        </div>
        <h1 className="text-primary font-obviously text-[27px] font-[570]">
          {t("space_investor.my_circles.my_membership_page.title")}
        </h1>
      </div>
      <ClubMemberTodoList />
      <div>
        {(clubMember.status === "following" && (
          <MyMembershipFollowingContent clubMember={clubMember} />
        )) || <MyMembershipPremiumContent clubMember={clubMember} />}
      </div>
    </>
  );
};
