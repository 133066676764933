import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  BondsMechanicSettings,
  getProjectMechanicSettings,
  Organization,
  PaymentMethodType,
  Project,
  SharesMechanicSettings,
  WMACustomer,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useGetProjectsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  IconName,
  PageHead,
  Tabs,
} from "@tudigo-monorepo/web-tudigo-components";

import { useCreateWMACustomerInvestmentMutation } from "../../../../../tudigo-api-client/src/lib/mutations/wma-investments/create-wma-customer-investment";
import {
  CreateWmaInvestmentFormData,
  WmaCreateInvestmentForm,
} from "./components/wma-create-investment-form";

export function CreateWmaInvestmentPage() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const urlParamsProjectId = searchParams.get("project-id");

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    urlParamsProjectId || null,
  );
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedProjectMechanicSettings, setSelectedProjectMechanicSettings] =
    useState<BondsMechanicSettings | SharesMechanicSettings | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<WMACustomer | null>(
    null,
  );
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);
  const [peaAccountSelected, setPeaAccountSelected] = useState<boolean>(false);
  const [peaPmeAccountSelected, setPeaPmeAccountSelected] =
    useState<boolean>(false);

  const { data: projectsResponse } = useGetProjectsQuery({
    filters: { status: ["started", "prefinished", "scheduled"] },
    pagination: { limit: 1000 },
  });

  const projects = useMemo(() => {
    return projectsResponse?.data ?? [];
  }, [projectsResponse]);

  const [investmentFormData, setInvestmentFormData] =
    useState<CreateWmaInvestmentFormData>({
      projectId: null,
      userId: null,
      wmaCustomerId: null,
      organizationId: null,
      paymentMethod: PaymentMethodType.WIRE,
      totalAmountInCents: 0,
    });

  const handleAutocompleteChange = (
    fieldName: string,
    value: string | null | undefined,
  ) => {
    setInvestmentFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleProjectSelect = (projectId: string) => {
    setSelectedProjectId(projectId);
    handleAutocompleteChange("projectId", projectId);
  };

  const handleCustomerSelect = (customer: WMACustomer) => {
    setSelectedCustomer(customer);
    setSelectedOrganization(null);
    setPeaPmeAccountSelected(false);
    setPeaAccountSelected(false);
    handleAutocompleteChange("userId", customer.customerUser?.id as string);
    handleAutocompleteChange("wmaCustomerId", customer.id as string);
    handleAutocompleteChange("organizationId", null);
    handleAutocompleteChange("paymentMethod", PaymentMethodType.WIRE);
    handleAutocompleteChange("equitySavingPlan", undefined);
  };

  useEffect(() => {
    const selectedProject = projects.find(
      (project) => project.id === selectedProjectId,
    );
    const mechanicSettings = selectedProject
      ? getProjectMechanicSettings(selectedProject)
      : null;
    setSelectedProjectMechanicSettings(mechanicSettings);
    if (selectedProject) {
      setSelectedProject(selectedProject);
    }
  }, [selectedProjectId, projects]);

  const checkIfFormIsValid = () => {
    return (
      investmentFormData.projectId !== null &&
      investmentFormData.userId !== null &&
      !!selectedCustomer?.customerUser?.investorProfile?.confirmed &&
      investmentFormData.organizationId !== null &&
      investmentFormData.totalAmountInCents !== 0 &&
      !isNaN(investmentFormData.totalAmountInCents) &&
      investmentFormData.totalAmountInCents %
        (selectedProjectMechanicSettings!.unitPriceInCents ?? 1) ===
        0 &&
      investmentFormData.totalAmountInCents >=
        selectedProjectMechanicSettings!.minimumTicketAmountInCents
    );
  };

  const { mutateAsync: createWmaInvestment, isPending: isCreateWmaInvestment } =
    useCreateWMACustomerInvestmentMutation({
      onSuccess: () => {
        toast((content) => (
          <Alert
            icon="Upload"
            close={content.closeToast}
            variant="success"
            size="S"
            title="Succès"
            description="L'investissement a bien été créé."
          />
        ));
        navigate(`list/all`);
      },
      onError: () => {
        toast((content) => (
          <Alert
            icon="Error"
            close={content.closeToast}
            variant="error"
            size="S"
            title="Erreur"
            description="Une erreur est survenue lors de la création de l'investissement."
          />
        ));
        navigate(`list/all`);
      },
    });

  const handleSubmit = () => {
    if (!checkIfFormIsValid()) {
      return;
    }
    createWmaInvestment({
      wmaCustomer: {
        id: investmentFormData.wmaCustomerId as string,
      },
      investment: {
        project: {
          id: investmentFormData.projectId as string,
        },
        user: {
          id: investmentFormData.userId as string,
        },
        investorOrganization: {
          id: investmentFormData.organizationId as string,
        },
        paymentMethodType: investmentFormData.paymentMethod,
        totalAmountInCents: investmentFormData.totalAmountInCents,
        autoConsentProjectAgreementsAndSecurity: true,
        equitySavingPlan: investmentFormData?.equitySavingPlan,
      },
    });
  };

  return (
    <div>
      <PageHead
        title="Création d'une souscription"
        withEndLine
        levels={[
          {
            iconName: "Invest" as IconName,
            callback: () => {
              navigate("list/all");
            },
          },
        ]}
      />
      <Tabs
        tabs={[
          {
            name: "Souscription",
          },
        ]}
        className="px-6"
      />
      <div className="m-6 flex flex-col gap-6">
        <WmaCreateInvestmentForm
          projects={projects}
          selectedProjectId={selectedProjectId}
          handleProjectSelect={handleProjectSelect}
          selectedCustomer={selectedCustomer}
          handleCustomerSelect={handleCustomerSelect}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          handleAutocompleteChange={handleAutocompleteChange}
          selectedProject={selectedProject}
          selectedProjectMechanicSettings={selectedProjectMechanicSettings}
          investmentFormData={investmentFormData}
          setInvestmentFormData={setInvestmentFormData}
          peaAccountSelected={peaAccountSelected}
          setPeaAccountSelected={setPeaAccountSelected}
          peaPmeAccountSelected={peaPmeAccountSelected}
          setPeaPmeAccountSelected={setPeaPmeAccountSelected}
          handleSubmit={handleSubmit}
          isCreateWmaInvestment={isCreateWmaInvestment}
          checkIfFormIsValid={checkIfFormIsValid}
        />
      </div>
    </div>
  );
}
