import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";
import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";
import { useImpersonateSearchParams } from "@tudigo-monorepo/core-tudigo-utils";
import {
  PageEnum,
  pathsByPagesAndSpaces,
  SpaceEnum,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import useAuthStore, {
  useSetImpersonateId,
} from "@tudigo-monorepo/web-tudigo-auth-store";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

export const DispatchPage = () => {
  const { user: connectedUser } = useUserBusiness();
  const accessToken = useAuthStore((state) => state.accessToken);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setImpersonateId = useSetImpersonateId();
  const { impersonateId } = useImpersonateSearchParams();

  const authClient = getAuthClient();
  const authUrl = authClient.authorizeUrl();

  const finalPath = searchParams.get("final_path");
  const hasDoneIntro = connectedUser?.state?.hasDoneIntro;
  const targetUrl = localStorage.getItem("target-url") ?? "";
  const shouldSkipIntro = targetUrl.includes("skip_intro");

  useEffect(() => {
    // user not loaded
    if (!connectedUser && !accessToken) {
      return navigate("/login");
    }

    if (!connectedUser && accessToken) {
      return;
    }

    if (finalPath && !connectedUser) {
      localStorage.setItem("target-url", finalPath);

      return window.location.replace(authUrl);
    }

    if (!hasDoneIntro && !shouldSkipIntro) {
      return navigate("/qualification/intro");
    }

    if (finalPath && connectedUser) {
      return navigate(finalPath);
    }

    if (!connectedUser && targetUrl) {
      localStorage.removeItem("target-url");

      return navigate(targetUrl);
    }

    if (targetUrl) {
      localStorage.removeItem("target-url");

      return navigate(targetUrl);
    }

    if (connectedUser && accessToken && impersonateId) {
      setImpersonateId(impersonateId);
    }

    if (impersonateId) {
      return navigate(
        pathsByPagesAndSpaces[PageEnum.DASHBOARD][SpaceEnum.INVESTOR],
      );
    }

    if (connectedUser?.appRoles?.includes(UserRole.WMA)) {
      return navigate(pathsByPagesAndSpaces[PageEnum.HOME][SpaceEnum.WMA]);
    }
    if (connectedUser?.appRoles?.includes(UserRole.PROJECT_HOLDER)) {
      return navigate(
        pathsByPagesAndSpaces[PageEnum.HOME][SpaceEnum.PROJECT_HOLDER],
      );
    }
    if (connectedUser?.appRoles?.includes(UserRole.CIRCLE_LEADER)) {
      return navigate(
        pathsByPagesAndSpaces[PageEnum.HOME][SpaceEnum.CIRCLE_LEADER],
      );
    }

    return navigate(pathsByPagesAndSpaces[PageEnum.HOME][SpaceEnum.INVESTOR]);
  }, [
    hasDoneIntro,
    targetUrl,
    connectedUser,
    finalPath,
    impersonateId,
    authUrl,
    accessToken,
    shouldSkipIntro,
    navigate,
    setImpersonateId,
  ]);

  return (
    <div className="h-screen">
      <Icon className="m-auto h-full" name="Loading" />
    </div>
  );
};
