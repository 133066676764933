import { useEffect, useState } from "react";
import { CommandLoading } from "cmdk";

import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  useGetCircleById,
  useGetCircles,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  Icon,
  InputGroup,
  InputGroupProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@tudigo-monorepo/web-tudigo-components";

type BlockSearchCircleProps = {
  currentItem?: ClubV2;
  currentItemId?: string;
  handleSelectItem: (item?: ClubV2) => void;
  filters?: any;
} & InputGroupProps;

export const CircleSelector = (props: BlockSearchCircleProps) => {
  const {
    labelPosition = "inside",
    disabled = false,
    required = false,
    errors,
    groupRef,
    currentItemId,
    currentItem,
    placeholder,
    handleSelectItem,
    filters,
  } = props;

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCircle, setSelectedCircle] = useState<ClubV2 | undefined>(
    currentItem,
  );

  const { data: fetchedCircle } = useGetCircleById({
    clubId: currentItemId ?? "",
    fields: "id,content.fields(name)",
  });

  useEffect(() => {
    if (currentItem || fetchedCircle) {
      setSelectedCircle(currentItem ?? fetchedCircle);
    }
  }, [fetchedCircle, currentItem]);

  const { data, isLoading } = useGetCircles({
    filters: {
      ...filters,
      term: search,
    },
    fields: "id,content.fields(name)",
    pathParams: undefined,
  });

  const handleValueChange = (currentValue: string) => {
    setSearch(currentValue);
  };

  const handleOrgChange = (item: ClubV2) => {
    setSelectedCircle(item);
    handleSelectItem(item);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="flex min-h-[38px] min-w-[250px] flex-row items-stretch outline-0">
        <InputGroup
          groupRef={groupRef}
          className="w-full"
          disabled={disabled}
          label="Choisir un cercle"
          required={required}
          errors={errors}
          hasValue={!!selectedCircle}
          labelPosition={labelPosition}
          canBeReset={!!selectedCircle}
          reset={() => {
            setSelectedCircle(undefined);
            handleSelectItem(undefined);
          }}
          iconRight={
            <Icon
              name="ChevronDown"
              size="S"
              primaryColor={
                open ? themeColors["accent"] : themeColors["dark-2"]
              }
              className={cn("rotate-0 transition-all", {
                "rotate-180": open,
              })}
            />
          }
          renderInput={() => (
            <p className="font-montserrat w-full text-start text-sm font-normal leading-5">
              {selectedCircle?.content?.name ?? placeholder}
            </p>
          )}
        />
      </PopoverTrigger>
      <PopoverContent className="w-[250px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            value={search}
            onValueChange={handleValueChange}
            placeholder="Rechercher par nom"
          />

          {isLoading && (
            <CommandList>
              <CommandLoading className="flex justify-center p-4">
                Chargement...
              </CommandLoading>
            </CommandList>
          )}

          {!isLoading && (
            <CommandList>
              <CommandEmpty>Aucun cercle trouvé</CommandEmpty>
              {data?.clubs.map((item) => (
                <CommandItem
                  key={item.id}
                  value={item.id}
                  onSelect={() => handleOrgChange(item)}
                >
                  {item.content?.name}
                </CommandItem>
              ))}
            </CommandList>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
};
