import { useState } from "react";
import { Plus } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetProjectNewsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  buttonCommonStyle,
  buttonStyles,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { ProjectNews } from "../../../../shared-external/components/project-news/project-news";
import { ProjectNewsProvider } from "../../../../shared-external/state/project-news.context";
import { useEditProjectPageContext } from "../../hooks/use-edit-project-page-context";
import { CreateProjectNewsModal } from "./create-project-news-modal";

export const projectNewsRequestFields =
  "id," +
  "type," +
  "title," +
  "description," +
  "content," +
  "locale," +
  "ref_type," +
  "ref_id," +
  "document.fields(id,signed_url,original_filename,mime_type_extension,created_at)," +
  "club.fields(id,name)," +
  "project.fields(id,name,content)," +
  "video.fields(url,title)," +
  "created_at," +
  "updated_at";

export function EditProjectNewsContent() {
  const { projectFormData } = useEditProjectPageContext();
  const { t } = useTranslation();
  const [isDialogOpen, setEditDialogOpen] = useState(false);

  return (
    <div className="m-6">
      <header className="mb-4 flex justify-between">
        <Title h3Brand="brand-2" level="h3" className="text-primary">
          {t("back_office.edit_project.project_news.list.title")}
        </Title>

        {/* <ButtonRebrand size="sm" onClick={() => setEditDialogOpen(true)}>
          <Plus className="mr-2 h-5 w-5" />
          {t("back_office.edit_project.project_news.add_news.button.label")}
        </ButtonRebrand> */}

        <button
          onClick={() => setEditDialogOpen(true)}
          className={cn(
            "ml-auto flex items-center gap-x-2 px-4",
            buttonCommonStyle,
            buttonStyles.tertiary,
          )}
        >
          {t("back_office.edit_project.project_news.add_news.button.label")}
          <Plus className="mr-2 h-5 w-5" />
        </button>

        <CreateProjectNewsModal
          isDialogOpen={isDialogOpen}
          setEditDialogOpen={setEditDialogOpen}
        />
      </header>

      <TudigoCollection
        useLocationAsState
        useQueryFn={useGetProjectNewsQuery}
        pathParams={{
          projectId: projectFormData.id,
        }}
        fields={projectNewsRequestFields}
        columns={[]}
        pagination={{
          pageIndex: 0,
          pageSize: 5,
        }}
        renderEmpty={() => <p>{t("collection.no_result")}</p>}
        render={(newsList) => {
          return (
            <div className="my-4 flex flex-col">
              {newsList.getRowModel().rows?.map(({ original: news }) => (
                <div className="mb-6 flex-row" key={news.id}>
                  <ProjectNewsProvider news={news} editMode>
                    <ProjectNews />
                  </ProjectNewsProvider>
                </div>
              ))}
            </div>
          );
        }}
      />
    </div>
  );
}
