import { UserRole } from "@tudigo-monorepo/core-tudigo-api-models";

import { QualificationExitButtons } from "./components/qualification-exit-buttons";
import { QualificationIntroExitConfirmationModal } from "./components/qualification-intro-exit-confirmation-modal";
import { QualificationRoleSwitcher } from "./components/qualification-intro-view";
import { useQualificationIntro } from "./hooks/useQualificationIntro";
import { QualificationInvestor } from "./pages/investor/qualification-investor";
import { QualificationProjectHolder } from "./pages/project-holder/qualification-project-holder";
import { QualificationWma } from "./pages/wma/qualification-wma";

export function QualificationIntroPage() {
  const { selectedProfile } = useQualificationIntro();

  return (
    <div className="relative min-h-screen">
      <div className="relative flex flex-col items-center justify-center gap-y-3 px-4 py-8 sm:flex-row sm:px-6">
        <QualificationRoleSwitcher />
        <QualificationExitButtons />
      </div>

      <hr />

      {selectedProfile === UserRole.INVESTOR && <QualificationInvestor />}

      {selectedProfile === UserRole.PROJECT_HOLDER && (
        <QualificationProjectHolder />
      )}

      {selectedProfile === UserRole.WMA && <QualificationWma />}

      <QualificationIntroExitConfirmationModal />
    </div>
  );
}
