import { ReactNode } from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { Typography } from "../typography";

type InputHelperProps = {
  children?: string | ReactNode;
  className?: string;
  variant?: "Default" | "Invalid" | "Valid";
};

const variantColors = {
  Default: themeColors["dark-2"],
  Invalid: themeColors["error"],
  Valid: themeColors["success"],
};

export function InputHelper({
  children,
  className,
  variant = "Default",
}: InputHelperProps) {
  return (
    <div className={cn("flex items-center gap-x-2", className)}>
      <Icon name="Error" primaryColor={variantColors[variant]} />
      <Typography variant="body3-regular" className="text-dark-3">
        {children}
      </Typography>
    </div>
  );
}
