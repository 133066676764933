import type { ReactNode } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  FileUploadConnected,
  InputConnected,
  InputHelper,
  InputTextareaConnected,
  MBToBytes,
  pdfMimesTypes,
  RadioGroupConnected,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  defaultProjectNewsFormValues,
  formSchema,
  type ProjectNewsFormValues,
} from "./form-schema";

type ProjectNewsFormProps = {
  onSubmit: (values: ProjectNewsFormValues) => void;
  defaultValues?: ProjectNewsFormValues;
  children?: ReactNode;
};

const InformationField = () => {
  const { t } = useTranslation();

  return (
    <InputTextareaConnected
      name="description"
      label={t(
        "back_office.edit_project.project_news.add_news.form.description.label",
      )}
      placeholder="Lorem ipsum"
      required
    />
  );
};

const DocumentField = () => {
  const { t } = useTranslation();

  return (
    <FileUploadConnected
      name="document"
      label={t(
        "back_office.edit_project.project_news.add_news.form.document.label",
      )}
      options={{
        accept: pdfMimesTypes,
        maxSize: MBToBytes(10),
        multiple: false,
      }}
      required
    />
  );
};

const VideoFields = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col gap-y-1">
        <InputConnected
          name="video.title"
          label={t(
            "back_office.edit_project.project_news.add_news.form.video_title.label",
          )}
          placeholder="Lorem ipsum"
          required
        />
        <InputHelper>
          {t(
            "back_office.edit_project.project_news.add_news.form.video_title_helper.label",
          )}
        </InputHelper>
      </div>

      <InputConnected
        name="video.url"
        label={t(
          "back_office.edit_project.project_news.add_news.form.video_url.label",
        )}
        placeholder="Lorem ipsum"
        required
      />
    </>
  );
};

export function ProjectNewsForm({
  onSubmit,
  defaultValues,
  children,
}: ProjectNewsFormProps) {
  const { t } = useTranslation();

  const form = useForm<ProjectNewsFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues || defaultProjectNewsFormValues,
  });
  const newsType = form.watch("type");
  console.log("form fields state", form.watch());

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-6"
      >
        <InputConnected
          name="title"
          label={t(
            "back_office.edit_project.project_news.add_news.form.title.label",
          )}
          placeholder="Lorem ipsum"
          required
        />

        <RadioGroupConnected
          name="type"
          className="flex items-center gap-x-4"
          options={[
            { label: "Texte", value: "information" },
            { label: "Document", value: "document" },
            { label: "Vidéo", value: "video" },
          ]}
          disableValueFn={(value) => !!defaultValues && value !== newsType}
          onValueChange={() => form.clearErrors()}
        />

        {newsType === "information" && <InformationField />}
        {newsType === "document" && <DocumentField />}
        {newsType === "video" && <VideoFields />}

        {children}
      </form>
    </FormProvider>
  );
}
