import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  NumberSearchCriteria,
  QUERY_KEYS,
} from "@tudigo-monorepo/core-tudigo-utils";

import {
  CollectionRequestParams,
  getApiClient,
  getQueryListKey,
} from "../../../";

export const enum InvestmentStatus {
  ENGAGED = "engaged",
  VALIDATED = "validated",
  WAITING_FOR_SIGNATURE = "waiting_for_signature",
  WAITING_FOR_PAYMENT = "waiting_for_payment",
  CANCELLED = "cancelled",
  TRANSFER_FAILED = "transfer_failed",
  INTENDED = "intended",
  PENDING = "pending",
}

export type InvestmentsFilters = {
  projectId?: string;
  userId?: string;
  createdAt?: NumberSearchCriteria;
  globalStatus?: InvestmentStatus | InvestmentStatus[] | string | string[];
  paymentMethodType?: string;
};

type GetInvestmentsParams = CollectionRequestParams<
  undefined,
  InvestmentsFilters
>;
export const getInvestments = async (params: GetInvestmentsParams) => {
  const apiClient = getApiClient();
  const { pagination, fields, filters } = params;
  const response = await apiClient.apiV1.investments.getInvestments({
    ...filters,
    fields: fields,
    offset: pagination?.offset,
    limit: pagination?.limit,
  });

  return response.data;
};

export const useGetInvestments = (
  params: GetInvestmentsParams,
  options?: UseQueryOptions<TudigoResponse<Investment[]>, TudigoError>,
) =>
  useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.INVESTMENTS,
      fields: params?.fields,
      filters: params?.filters,
    }),
    queryFn: () => getInvestments(params),
    ...options,
  });
