import { ChangeEvent, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { useInstantSearch, useSearchBox } from "react-instantsearch";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon, useToggler } from "@tudigo-monorepo/web-tudigo-components";
import { mixpanelTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

export const CustomSearchBox = () => {
  const { query, refine, clear } = useSearchBox();
  const { results } = useInstantSearch();
  const [focused, toggle] = useToggler();
  const searching = query.length > 0;

  const debouncedTrackSearch = useCallback(
    debounce((eventData) => {
      mixpanelTrackEvent("ui.projects_listing_page.searched", eventData);
    }, 500),
    [],
  );

  useEffect(() => {
    if (searching && results) {
      const totalProjectsCount = results.hits.length || 0;

      const startedProjectsCount =
        results.hits.filter((project) => project.status === "started").length ||
        0;

      const mixpanelEventData = {
        "Search Term": query,
        "Project Number of Results": totalProjectsCount,
        "Project Number of Active Ones": startedProjectsCount,
      };

      debouncedTrackSearch(mixpanelEventData);
    }
  }, [query, results, searching, debouncedTrackSearch]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.currentTarget.value;
    refine(query);
  };

  return (
    <div className="w-full">
      <div
        className={cn(
          "h-[38px] w-full sm:w-[288px]",
          "flex items-center",
          "px-4",
          "text-[13px]",
          "text-dark-2 bg-white",
          "border-dark-4 focus:border-accent-medium rounded-lg border",
          {
            "border-accent-medium": focused,
          },
        )}
      >
        <input
          type="text"
          value={query}
          onChange={handleChange}
          onFocus={toggle}
          onBlur={toggle}
          placeholder="Rechercher"
          className="search-cancel:appearance-none text-dark-2 font-montserrat w-full bg-transparent text-[13px] outline-none"
        />
        {searching && (
          <button onClick={clear}>
            <Icon name="Cross" />{" "}
          </button>
        )}
        {!searching && <Icon name="Search" />}
      </div>
    </div>
  );
};
