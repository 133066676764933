import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  RouterTabItem,
  RouterTabs,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@tudigo-monorepo/web-tudigo-components";

import { useProjectPageRoutes } from "../hooks/use-project-page-routes";

function DesktopNavigation() {
  const projectRoutes = useProjectPageRoutes();

  return (
    <RouterTabs>
      {projectRoutes.map((route) => (
        <RouterTabItem key={route.label} to={route.path}>
          {route.label}
        </RouterTabItem>
      ))}
    </RouterTabs>
  );
}

function MobileNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const projectRoutes = useProjectPageRoutes();

  const handleSelectChange = (value: string) => {
    const navigationPath = projectRoutes.find((route) => route.key === value)
      ?.path;
    if (navigationPath) {
      navigate(navigationPath);
    }
  };

  const currentRoute = useMemo(() => {
    if (location.pathname === "") {
      return projectRoutes[0];
    }

    return projectRoutes.find((route) =>
      location.pathname.includes(route.path),
    );
  }, [location.pathname, projectRoutes]);

  return (
    <div className="-ml-4 w-screen">
      <Select onValueChange={handleSelectChange}>
        <SelectTrigger className="rounded-none border-0 border-b-[4px] border-black">
          <SelectValue placeholder={currentRoute?.label} />
        </SelectTrigger>
        <SelectContent
          className="bg-white"
          style={{ width: "calc(100% - 1.5rem)" }}
        >
          <SelectGroup>
            {projectRoutes.map((route) => (
              <SelectItem key={route.key} value={route.key}>
                {route.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

export function ProjectPageNavigation() {
  return (
    <>
      <div className="hidden md:block">
        <DesktopNavigation />
      </div>
      <div className="relative mb-2 block md:hidden">
        <MobileNavigation />
      </div>
    </>
  );
}
